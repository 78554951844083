import { Component, ElementRef, ViewChild } from '@angular/core';
import { homeText, message, monthNames, searchFields } from '../../app/app.constant';

import { AdsService } from '../../services/ads-service/ads-service';
import { BookingService } from '../../services/booking-service/booking-service';
import { CancelService } from '../../services/cancel-service/cancel-service';
import { InfoService } from '../../services/info-service/info-service';
import { MatDialog } from '@angular/material';
import { MessageComponent } from '../message/message';
import { NewsService } from '../../services/news-service/new-service';
import { OrderComponent } from '../order/order';
import { PageService } from '../../services/page-service/page-service';
import { Router } from '@angular/router';
import { SearchComponent } from '../search/search-booking';
import { faClock, faHandPointer, faMapMarker, faCalendarAlt, faUsers, faShip, faHiking, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})

export class HomeComponent {
  @ViewChild('departureInput', { static: true }) departureInput: ElementRef;
  @ViewChild('returnInput', { static: true }) returnInput: ElementRef;
  menues = [];
  fullBookedDateDeparture = [];
  fullBookedDateReturn = [];
  myFilterReturn: any;
  myFilterDeparture: any;
  developer = true;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faShip = faShip;
  faHiking = faHiking;
  faUsers = faUsers;
  faClock = faClock;
  faHandPointer = faHandPointer;
  faMapMarker = faMapMarker;
  faCalendarAlt = faCalendarAlt;
  localhost = window.location.hostname;
  fullBookedDate = []
  activeMenu = [];
  trips = [];
  tripsAccountType = [];
  amountTrips: number;
  froms = [];
  guests = [];
  amountGuest: number;
  backgroundSrc = [];
  backgroundSrcMedium = [];
  delay: number;
  price = [];
  tourInfo = [];
  latestInfo = [];
  infoes = [];
  pagesText = [];
  pageBackground: string;
  homeText = {
    returnTripText: '',
    juniorSeniorText: '',
    orderHere: '',
    adults: '',
    childrenText: '',
    from: '',
    readmore: '',
    headline: [],
    contact: homeText.contact
  };

  tripDropdown: boolean;
  locationDropdown: boolean;
  dateDropdown: boolean;
  timeDropdown: boolean;
  guestsDropdown: boolean;

  departure: any;
  departureTime = [];
  returnTime = [];
  startAt = new Date();
  returnMin = new Date();
  min = new Date();
  max = new Date();
  myFilter: any;
  selectedDepartureTime = { departure: '', return: '' };
  departureLocation: string;
  disabled = true;
  rotateTrip: number;
  myTimer: any;
  count = 0;
  language = sessionStorage.getItem('language');
  messages = [];
  showMessage = [];
  cancel: boolean;
  showMessageInfo: string;
  infoInfoCtrl = -1;
  messageInfoCtrl = -1;
  countRotate = -3;
  topInfo: boolean;
  loopLength: number;
  reset: false;
  dialogRef: any;
  adults = 0;
  juniorSenior = 0;
  children = 0;
  returnTrip = true;
  depSelected: number;
  retSelected: number;
  departureDate: any;
  returnDate: any;
  searchFields = [{
    hiking: false,
    lan: [],
    tour: '',
    departure: '',
    return: '',
    date: [],
    adults: 0,
    juniorSenior: 0,
    children: 0,
    returnTrip: this.returnTrip,
    seats: '',
    seasonStart: new Date(),
    seasonEnd: new Date(),
    extraTrip: false
  }];
  disableFrom: boolean;
  leftMenu: boolean;
  width: any;
  activeSearch: number;
  dialogWidth: string;
  dialogHeight: string;
  cancelInfo: any;
  cancelDates = [];
  numberRotate: number;
  adFirstImage = '';
  adSecondImage = '';
  fadeAd = false;
  tourNumber: string;
  mobile = '';
  countryInfo = sessionStorage.getItem('countryInfo');
  linkInfoEN = [];
  linkInfoFO = [];
  pageRun = true;
  tours = [];
  accountType = { hiking: false, trip: false };
  adImg1: string;
  adImgEn1: string;
  adImg2: string;
  adImgEn2: string;
  adImg3: string;
  adImgEn3: string;
  images = [
    { path: 'assets/img/3.png' }
  ]
  cellWidth = 0;
  cellHeight = 0;
  arrows = true;
  constructor(
    private router: Router,
    public bookingService: BookingService,
    public adsService: AdsService,
    public cancelService: CancelService,
    public pageService: PageService,
    public newsService: NewsService,
    public infoService: InfoService,
    public dialog: MatDialog) {
    this.cellWidth = window.innerWidth;
    if (window.innerWidth < 500) {
      this.arrows = false;
      this.cellHeight = window.innerWidth / 1.25;
    } else if (window.innerWidth > 500 && window.innerWidth < 1024) {
      this.cellHeight = window.innerWidth / 1.6;
    } else {
      this.cellHeight = window.innerWidth / 2.15;
    }
    this.adsService.getAdInfo().then((info) => {
      const objInfo = info;

      this.linkInfoEN = JSON.parse(objInfo[0].linkEN);
      this.linkInfoFO = JSON.parse(objInfo[0].linkFO);
      this.tourNumber = this.language === 'FO' || this.language === null ? this.linkInfoFO[JSON.parse(randomAd[0]) - 1] :
        this.linkInfoEN[JSON.parse(randomAd[0]) - 1];
    }).catch(() => {

    });
    if (window.innerWidth < 1024) {
      this.mobile = '_mobile';
    }
    const randomAd = ['1', '2', '3'];
    randomAd.sort(() => {
      return .5 - Math.random();
    });
    const random = JSON.stringify(Math.random()).slice(2);

    this.adImg1 = 'https://mykines.fo/assets/ads/' + randomAd[0] + this.mobile + '.png';
    this.adImgEn1 = 'https://mykines.fo/assets/ads/en_' + randomAd[0] + this.mobile + '.png';
    this.adImg2 = 'https://mykines.fo/assets/ads/' + randomAd[1] + this.mobile + '.png';
    this.adImgEn2 = 'https://mykines.fo/assets/ads/en_' + randomAd[1] + this.mobile + '.png';
    this.adImg3 = 'https://mykines.fo/assets/ads/' + randomAd[2] + this.mobile + '.png';
    this.adImgEn3 = 'https://mykines.fo/assets/ads/en_' + randomAd[2] + this.mobile + '.png';
    this.adSecondImage = this.language === 'FO' || this.language === null ?
      this.adImg1 :
      this.adImgEn1;
    this.ads();
    setInterval(() => {
      if (this.fadeAd) {
        this.fadeAd = false;
      } else {
        this.fadeAd = true;
      }

      this.width = window.innerWidth;
      if (this.numberRotate === 3 || this.numberRotate === undefined) {
        this.numberRotate = 1;
        if (this.adFirstImage === '') {
          this.adFirstImage = this.language === 'FO' || this.language === null ?
            this.adImg1 : this.adImgEn1;
          setTimeout(() => {
            this.adSecondImage = '';
          }, 1000);
        } else {
          this.adSecondImage = this.language === 'FO' || this.language === null ?
            this.adImg1 : this.adImgEn1;
          setTimeout(() => {
            this.adFirstImage = '';
          }, 1000);
        }

        this.tourNumber = this.language === 'FO' || this.language === null ? this.linkInfoFO[JSON.parse(randomAd[0]) - 1] :
          this.linkInfoEN[JSON.parse(randomAd[0]) - 1];
      } else if (this.numberRotate === 1) {
        this.numberRotate = 2;
        if (this.adFirstImage === '') {
          this.adFirstImage = this.language === 'FO' || this.language === null ?
            this.adImg2 : this.adImgEn2;
          setTimeout(() => {
            this.adSecondImage = '';
          }, 1000);
        } else {
          this.adSecondImage = this.language === 'FO' || this.language === null ?
            this.adImg2 : this.adImgEn2;
          setTimeout(() => {
            this.adFirstImage = '';
          }, 1000);
        }
        this.tourNumber = this.language === 'FO' || this.language === null ? this.linkInfoFO[JSON.parse(randomAd[1]) - 1] :
          this.linkInfoEN[JSON.parse(randomAd[1]) - 1];
      } else if (this.numberRotate === 2) {
        this.numberRotate = 3;
        if (this.adFirstImage === '') {
          this.adFirstImage = this.language === 'FO' || this.language === null ?
            this.adImg3 : this.adImgEn3;
          setTimeout(() => {
            this.adSecondImage = '';
          }, 1000);
        } else {
          this.adSecondImage = this.language === 'FO' || this.language === null ?
            this.adImg3 : this.adImgEn3;
          setTimeout(() => {
            this.adFirstImage = '';
          }, 1000);
        }
        this.tourNumber = this.language === 'FO' || this.language === null ? this.linkInfoFO[JSON.parse(randomAd[2]) - 1] :
          this.linkInfoEN[JSON.parse(randomAd[2]) - 1];
      }
      setTimeout(() => {
        this.pageRun = true;
      }, 4000);
    }, 4000);
    if (window.innerWidth > 1024) {
      this.dialogWidth = '50vw';
      this.dialogHeight = '';
    } else {
      this.dialogWidth = '100vw';
      this.dialogHeight = '100vh';
    }
    for (let i = 1; i < 50; i++) {
      this.guests.push(i);
    }
    this.pageService.getPages().then((pageInfo) => {

      if (pageInfo) {
        const pageInfoObj = pageInfo;
        for (let i = 0; i < pageInfoObj.length; i++) {
          this.pagesText.push(pageInfoObj[i]);

          if (this.language === 'FO' || !this.language) {
            if (i === 0) {
              this.menues.push('Heim');
            }
            this.menues.push(pageInfoObj[i].menuFo);
          } else {
            if (i === 0) {
              this.menues.push('Home');
            }
            this.menues.push(pageInfoObj[i].menuEn);
          }
          this.activeMenu = this.menues[0];

          // const parser = new DOMParser();
        }
      } else {
        if (this.language === 'FO' || !this.language) {
          this.menues.push('Heim');
        } else {
          this.menues.push('Home');
        }
        this.activeMenu = this.menues[0];

        // const parser = new DOMParser();
      }

    }).catch(() => {

    });

    this.infoService.getInfo().then((infoInfo) => {

      const infoInfoObj = infoInfo;
      infoInfoObj.forEach((info) => {
        if (this.language === 'FO' || !this.language) {
          this.infoes.push({ headline: info.headlineFo, text: info.commentFo });
        } else {
          this.infoes.push({ headline: info.headlineEn, text: info.commentEn });
        }
      });
    }).catch(() => {

    });

    this.bookingService.getTours().then((info) => {

      if (this.language === 'FO' || !this.language) {
        this.searchFields[0].lan = searchFields.fo;
        this.homeText.from = homeText.from.fo;
        this.homeText.adults = homeText.adults.fo;
        this.homeText.orderHere = homeText.orderHere.fo;
        this.homeText.readmore = homeText.readmore.fo;
        this.homeText.headline = homeText.headline.fo;
        this.homeText.juniorSeniorText = homeText.juniorSeniorText.fo;
        this.homeText.childrenText = homeText.childrenText.fo;
        this.homeText.returnTripText = homeText.returnTripText.fo;
      } else {
        this.searchFields[0].lan = searchFields.en;
        this.homeText.from = homeText.from.en;
        this.homeText.adults = homeText.adults.en;
        this.homeText.orderHere = homeText.orderHere.en;
        this.homeText.readmore = homeText.readmore.en;
        this.homeText.headline = homeText.headline.en;
        this.homeText.juniorSeniorText = homeText.juniorSeniorText.en;
        this.homeText.childrenText = homeText.childrenText.en;
        this.homeText.returnTripText = homeText.returnTripText.en;
      }
      const trips = JSON.parse(info[0].tours);
      console.log(trips);
      trips.sort(this.compareSeasonDate);
      this.tours = trips;
      var lengthOfValidTrips = 0;
      for (let i = 0; i < trips.length; i++) {

        const dateCurrent = new Date();
        const UTCDate = dateCurrent.getUTCFullYear() + '-' +
          ((dateCurrent.getUTCMonth() + 1) < 10 ? '0' : '') +
          (dateCurrent.getUTCMonth() + 1) + '-' +
          (dateCurrent.getUTCDate() < 10 ? '0' : '') +
          dateCurrent.getUTCDate();

        if (trips[i].seasonEnd >= UTCDate) {
          lengthOfValidTrips = lengthOfValidTrips + 1;
          this.price.push(trips[i].priceAdult);
          if (this.language === 'FO' || !this.language) {
            this.trips.push(trips[i].tour);

            this.tourInfo.push(trips[i].fragreiding);
          } else {
            this.tourInfo.push(trips[i].fragreidingEN);
            this.trips.push(trips[i].touren);
          }
          this.tripsAccountType.push(trips[i].accountType);
          this.width = window.innerWidth;
          if (i > 2) {
            const arr = ['0', '1', '2', '3'];
            const imgNumber = this.shuffle(arr)[0];
            if (this.width < 500) {
              console.log('test')
              this.backgroundSrc.push('assets/img/' + imgNumber + '_mobile.png');
              this.backgroundSrcMedium.push('assets/img/' + imgNumber + '_mobile.png');
            } else if (this.width > 500 && this.width < 1024) {
              this.backgroundSrc.push('assets/img/' + imgNumber + '_tablet.png');
              this.backgroundSrcMedium.push('assets/img/' + imgNumber + '_tablet.png');
            } else {
              this.backgroundSrcMedium.push('assets/img/' + imgNumber + '_medium.png');
              this.backgroundSrc.push('assets/img/' + imgNumber + '.png');
            }

          } else {
            if (this.width < 500) {
              this.backgroundSrc.push('assets/img/' + i + '_mobile.png');
              this.backgroundSrcMedium.push('assets/img/' + i + '_mobile.png');
            } else if (this.width > 500 && this.width < 1024) {
              this.backgroundSrc.push('assets/img/' + i + '_tablet.png');
              this.backgroundSrcMedium.push('assets/img/' + i + '_tablet.png');
            } else {
              this.backgroundSrcMedium.push('assets/img/' + i + '_medium.png');
              this.backgroundSrc.push('assets/img/' + i + '.png');
            }
          }
        }
      }

      if (lengthOfValidTrips === 0) {
        if (this.language === 'FO' || !this.language) {
          this.trips.push("Season Closed");
          this.tourInfo.push(trips[0].fragreiding);
        } else {
          this.tourInfo.push(trips[0].fragreidingEN);
          this.trips.push("Season Closed");
        }
        if (this.width < 500) {
          this.backgroundSrc.push('assets/img/' + 0 + '_mobile.png');
        } else if (this.width > 500 && this.width < 1024) {
          this.backgroundSrc.push('assets/img/' + 0 + '_tablet.png');
        } else {
          this.backgroundSrcMedium.push('assets/img/' + 0 + '_medium.png');
          this.backgroundSrc.push('assets/img/' + 0 + '.png');
        }
      }

      this.newsService.getNews().then((newsInfo) => {
        this.cancelService.getCancelTours().then((cancelInfo) => {
          this.cancelInfo = cancelInfo;
          if (cancelInfo.length === 0 && newsInfo.length === 0) {
            for (let i = 0; i < 3; i++) {
              if (this.language === 'FO' || !this.language) {
                this.showMessage.push(message.normalMessage.fo);
              } else {
                this.showMessage.push(message.normalMessage.en);
              }
            }
          } else {
            let newsInfoMessage = [];
            if (!newsInfo) {
              newsInfoMessage = cancelInfo;
            }
            if (!cancelInfo) {
              newsInfoMessage = newsInfo.concat();
            }
            if (cancelInfo && newsInfo) {
              newsInfoMessage = newsInfo.concat(cancelInfo);
            }

            newsInfoMessage.sort(this.compareRegDate);

            let messageStart = new Date();
            // let messageEnd = new Date();
            newsInfoMessage.forEach((infoMessage) => {
              const regDate = new Date(infoMessage.reg_date.substring(0, 10));
              if (infoMessage.tour) {
                const reason = infoMessage.reason;
                let tour = trips.map((e) => e.id).indexOf(infoMessage.tour);
                if (tour === -1) {
                  tour = 0;
                }
                const time = infoMessage.time;
                const date = new Date();
                const UTCDate = date.getUTCFullYear() + '-' +
                  ((date.getUTCMonth() + 1) < 10 ? '0' : '') +
                  (date.getUTCMonth() + 1) + '-' +
                  (date.getUTCDate() < 10 ? '0' : '') +
                  date.getUTCDate();

                messageStart = new Date(infoMessage.fromDate);
                const messageStartString = messageStart.getUTCFullYear() + '-' +
                  ((messageStart.getUTCMonth() + 1) < 10 ? '0' : '') +
                  (messageStart.getUTCMonth() + 1) + '-' +
                  (messageStart.getUTCDate() < 10 ? '0' : '') +
                  messageStart.getUTCDate();

                // messageEnd = new Date(infoMessage['toDate']);
                const messageEndString = messageStart.getUTCFullYear() + '-' +
                  ((messageStart.getUTCMonth() + 1) < 10 ? '0' : '') +
                  (messageStart.getUTCMonth() + 1) + '-' +
                  (messageStart.getUTCDate() < 10 ? '0' : '') +
                  messageStart.getUTCDate();

                let textDate: any;

                if (messageEndString === messageStartString) {
                  textDate = this.convertDate(infoMessage.fromDate);
                } else {
                  textDate = this.convertDate(infoMessage.fromDate) + ' - ' + this.convertDate(infoMessage.toDate);
                }
                if (UTCDate <= messageEndString) {
                  if (time === 'Allir túrar') {
                    if (this.language === 'FO' || !this.language) {
                      console.log(message.cancelMessage[reason].foFleiri)
                      this.latestInfo.push({
                        id: infoMessage.id,
                        date: regDate.getUTCDate(),
                        month: monthNames.fo[regDate.getUTCMonth()],
                        headline: trips[tour].tour,
                        text: 'Allir túrar til ' + trips[tour].tour + ' tann ' + textDate + ' ' + message.cancelMessage[reason].foFleiri
                      });
                    } else {
                      this.latestInfo.push({
                        id: infoMessage.id,
                        date: regDate.getUTCDate(),
                        month: monthNames.en[regDate.getUTCMonth()],
                        headline: trips[tour].touren,
                        text: 'All trips on ' + textDate + ' to ' + trips[tour].tour + ' are ' + message.cancelMessage[reason].enFleiri
                      });
                    }
                  } else {
                    if (this.language === 'FO' || !this.language) {
                      this.latestInfo.push({
                        id: infoMessage.id,
                        date: regDate.getUTCDate(),
                        month: monthNames.fo[regDate.getUTCMonth()],
                        headline: trips[tour].tour,
                        text: 'Túrurin kl.' +
                          time + ' til ' +
                          trips[tour].tour + ' tann ' +
                          textDate + ' ' +
                          message.cancelMessage[reason].fo
                      });
                    } else {
                      this.latestInfo.push({
                        id: infoMessage.id,
                        date: regDate.getUTCDate(),
                        month: monthNames.en[regDate.getUTCMonth()],
                        headline: trips[tour].touren,
                        text: 'The trip on ' +
                          textDate + ' at ' +
                          time + ' to ' +
                          trips[tour].tour + ' ' +
                          message.cancelMessage[reason].en
                      });
                    }
                  }
                }
                if (UTCDate >= messageStartString && UTCDate <= messageEndString) {
                  if (time === 'Allir túrar') {
                    if (this.language === 'FO' || !this.language) {
                      this.messages.push({
                        id: infoMessage.id,
                        date: regDate.getUTCDate(),
                        month: monthNames.en[regDate.getUTCMonth()],
                        headline: trips[tour].tour,
                        text: 'Allir túrar til ' + trips[tour].tour + ' tann ' + textDate + ' ' + message.cancelMessage[reason].foFleiri
                      });
                    } else {
                      this.messages.push({
                        id: infoMessage.id,
                        date: regDate.getDate(),
                        month: monthNames.en[regDate.getMonth()],
                        headline: trips[tour].touren,
                        text: 'All trips on ' + textDate + ' to ' + trips[tour].touren + ' are ' + message.cancelMessage[reason].enFleiri
                      });
                    }
                  } else {
                    if (this.calcTime('+1') < time) {
                      if (this.language === 'FO' || !this.language) {
                        this.messages.push({
                          id: infoMessage.id,
                          date: regDate.getUTCDate(),
                          month: monthNames.en[regDate.getUTCMonth()],
                          headline: trips[tour].tour,
                          text: 'Túrurin kl.' + time + ' tann ' +
                            textDate + ' til ' + trips[tour].tour + ' ' + message.cancelMessage[reason].fo
                        });
                      } else {
                        this.messages.push({
                          id: infoMessage.id,
                          date: regDate.getUTCDate(),
                          month: monthNames.en[regDate.getUTCMonth()],
                          headline: trips[tour].touren,
                          text: 'The trip at ' + time + ' on ' +
                            textDate + ' to ' + trips[tour].touren + ' is ' + message.cancelMessage[reason].en
                        });
                      }
                    }
                  }
                }
              } else {
                if (this.language === 'FO' || !this.language) {
                  this.latestInfo.push({
                    id: infoMessage.id,
                    date: regDate.getUTCDate(),
                    month: monthNames.fo[regDate.getUTCMonth()],
                    headline: infoMessage.headlineFo,
                    text: infoMessage.commentFo
                  });
                  // this.messages.push(infoMessage.headlineFo + ' - ' + infoMessage.commentFo);
                } else {
                  this.latestInfo.push({
                    id: infoMessage.id,
                    date: regDate.getUTCDate(),
                    month: monthNames.en[regDate.getUTCMonth()],
                    headline: infoMessage.headlineEn,
                    text: infoMessage.commentEn
                  });
                  // this.messages.push(infoMessage.headlineEn + ' - ' + infoMessage.commentEn);
                }
              }
              this.showMessage = [];
              if (this.messages.length % 3 === 0) {
                this.loopLength = this.messages.length;
              } else if ((this.messages.length + 1) % 3 === 0) {
                this.loopLength = this.messages.length + 1;
              } else {
                this.loopLength = this.messages.length + 2;
              }
            });
            this.rotateMessage(this.loopLength);

            if (this.loopLength > 3) {
              setInterval(() => {
                this.showMessage = [];
                this.rotateMessage(this.loopLength);
              }, this.loopLength * 1500);
            }
          }
          if (this.messages.length > 0) {
            this.dialogRef = this.dialog.open(MessageComponent, {
              width: this.dialogWidth,
              data: this.messages
            });
          }
        }).catch(() => {

        });
      }).catch(() => { });
      this.bullets(this.count, true);
    }).catch(() => {

    });

  }

  soldOutCalender(direction) {
    this.fullBookedDateDeparture = [];
    const primaryTour = this.tours[this.tours.findIndex(x => x.id === '1577719642953')];
    const getTour = this.tours[this.tours.findIndex(x => x.id === '1577720079820')];
    const extraTour = { tour: getTour.tour, touren: getTour.touren }
    if (extraTour.tour === this.searchFields[0].tour || extraTour.touren === this.searchFields[0].tour) {
      var seat = JSON.parse(primaryTour.seat);

      var departureLocation = this.searchFields[0].departure;
      var returnLocation = this.searchFields[0].return;
      var allDepartureDate = [];
      var allReturnDate = [];
      this.bookingService.getBooking({ future: 'true', notUserData: 'true' }).then((bookedDate) => {

        bookedDate.forEach((value) => {

          if (direction === 'departure') {
            if (primaryTour.tour === value.tour &&
              departureLocation === value.departureLocation && value.payment !== 'Endurgoldið') {

              if (new Date(value.departureDate) > new Date()) {
                const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
                const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
                if (index === -1) {

                  allDepartureDate.push({ departureDate: value.departureDate, countPeople });
                } else {

                  allDepartureDate[index].countPeople += countPeople;
                }

              }
            }
            if (primaryTour.tour === value.tour &&
              departureLocation === value.returnLocation && value.payment !== 'Endurgoldið') {

              if (new Date(value.departureDate) > new Date()) {
                const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))

                const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
                if (index === -1) {
                  allDepartureDate.push({ departureDate: value.returnDate, countPeople });
                } else {

                  allDepartureDate[index].countPeople += countPeople;
                }
              }
            }

          } else {
            if (primaryTour.tour === value.tour && returnLocation === value.returnLocation && value.payment !== 'Endurgoldið') {


              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
              if (index === -1) {
                allReturnDate.push({ returnDate: value.returnDate, countPeople });
              } else {
                allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
              }
            }
            if (primaryTour.tour === value.tour && returnLocation === value.departureLocation && value.payment !== 'Endurgoldið') {

              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
              if (index === -1) {
                allReturnDate.push({ returnDate: value.departureDate, countPeople });
              } else {
                allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
              }
            }
          }
        })

      }).then(() => {

        if (direction === 'departure') {
          console.log(this.departureLocation);
          allDepartureDate.forEach((x) => {
            if (x.countPeople >= (seat - 5) && seat !== 0) {
              const stringCount = new Date(x.departureDate);
              stringCount.setHours(0, 0, 0, 0);
              this.fullBookedDateDeparture.push(stringCount.toString())
            }
          });
        } else {
          if (this.returnTrip) {
            const stringCount = new Date(this.departureDate);
            stringCount.setHours(0, 0, 0, 0);
            this.fullBookedDateReturn.push(stringCount.toString())
          } else {
            allReturnDate.forEach((x) => {
              if (x.countPeople >= (seat - 5) && seat !== 0) {
                const stringCount = new Date(x.returnDate);
                stringCount.setHours(0, 0, 0, 0);
                this.fullBookedDateReturn.push(stringCount.toString())
              }
            });
          }


        }

        if (direction === 'departure') {
          this.myFilterDeparture = (d: Date): boolean => {
            d.setHours(0, 0, 0, 0);

            if (this.fullBookedDateDeparture.includes(d.toString())) {

              return true;
            } else {
              return false;
            }

          }
        } else {
          this.myFilterReturn = (d: Date): boolean => {
            d.setHours(0, 0, 0, 0);
            if (this.fullBookedDateReturn.includes(d.toString())) {
              return true;
            } else {
              return false;
            }

          }
        }

      })
    } else {
      this.myFilterReturn = undefined;
      this.myFilterDeparture = undefined;
    }

  }


  openCalender(value) {
    value === 'departure' ? document.getElementById("departure-input").click() : document.getElementById("return-input").click();
  }

  clickAds(adNumber) {
    if (adNumber.length > 2) {
      window.open(adNumber, '_blank');
    }

    this.adsService.click({ adNumber, countryInfo: this.countryInfo }).then((value) => {
      console.log(value);
    }).catch((err) => {
      console.log(err);
    });
  }

  calcTime(offset) {
    // create Date object for current location
    const d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    const nd = new Date(utc + (3600000 * offset));

    const time = (nd.getHours() < 10 ? '0' : '') +
      nd.getHours() + ':' +
      (nd.getMinutes() < 10 ? '0' : '') +
      nd.getMinutes();
    // return time as a string
    return time;
  }

  ads(event?) {

    if (event) {
      if (event.target.innerWidth < 1024) {
        this.mobile = '_mobile';
        if (!this.adImg1.includes('_mobile')) {
          this.adImg1 = this.adImg1.slice(0, -4) + '_mobile.png';
          this.adImgEn1 = this.adImgEn1.slice(0, -4) + '_mobile.png';
          this.adImg2 = this.adImg2.slice(0, -4) + '_mobile.png';
          this.adImgEn2 = this.adImgEn2.slice(0, -4) + '_mobile.png';
          this.adImg3 = this.adImg3.slice(0, -4) + '_mobile.png';
          this.adImgEn3 = this.adImgEn3.slice(0, -4) + '_mobile.png';
        }
      } else {
        this.mobile = '';
        if (this.adImg1.includes('_mobile')) {
          this.adImg1 = this.adImg1.slice(0, -11) + '.png';
          this.adImgEn1 = this.adImgEn1.slice(0, -11) + '.png';
          this.adImg2 = this.adImg2.slice(0, -11) + '.png';
          this.adImgEn2 = this.adImgEn2.slice(0, -11) + '.png';
          this.adImg3 = this.adImg3.slice(0, -11) + '.png';
          this.adImgEn3 = this.adImgEn3.slice(0, -11) + '.png';
        }
      }

    } else {
      if (window.innerWidth < 1024) {
        this.mobile = '_mobile';
        if (!this.adImg1.includes('_mobile')) {
          this.adImg1 = this.adImg1.slice(0, -4) + '_mobile.png';
          this.adImgEn1 = this.adImgEn1.slice(0, -4) + '_mobile.png';
          this.adImg2 = this.adImg2.slice(0, -4) + '_mobile.png';
          this.adImgEn2 = this.adImgEn2.slice(0, -4) + '_mobile.png';
          this.adImg3 = this.adImg3.slice(0, -4) + '_mobile.png';
          this.adImgEn3 = this.adImgEn3.slice(0, -4) + '_mobile.png';
        }
      } else {
        this.mobile = '';
        if (this.adImg1.includes('_mobile')) {
          this.adImg1 = this.adImg1.slice(0, -11) + '.png';
          this.adImgEn1 = this.adImgEn1.slice(0, -11) + '.png';
          this.adImg2 = this.adImg2.slice(0, -11) + '.png';
          this.adImgEn2 = this.adImgEn2.slice(0, -11) + '.png';
          this.adImg3 = this.adImg3.slice(0, -11) + '.png';
          this.adImgEn3 = this.adImgEn3.slice(0, -11) + '.png';
        }
      }
    }
  }

  onResize(event?) {
    this.cellWidth = event.target.innerWidth;
    this.width = event.target.innerWidth;
    this.arrows = true;
    this.ads(event);
    if (this.width < 500) {
      this.arrows = false;
      this.cellHeight = window.innerWidth / 1.25;
    } else if (this.width > 500 && this.width < 1024) {
      this.cellHeight = window.innerWidth / 1.6;
    } else {
      this.cellHeight = window.innerWidth / 2.15;
    }
  }

  burgerMenu() {
    this.leftMenu = this.leftMenu ? false : true;
  }

  addGuests(count, type) {
    if (type === 0) {
      this.adults = this.adults + count;
    }
    if (type === 1) {
      this.juniorSenior = this.juniorSenior + count;
    }
    if (type === 2) {
      this.children = this.children + count;
    }
  }

  searchBooking(): void {
    this.mask();
    let warning: string;

    if (this.adults !== 0 || this.juniorSenior !== 0) {
    } else {
      if (this.language === 'FO' || !this.language) {
        warning = 'Vinarliga vel gestir. \n\nGev gætur!\n Børn undir 6 ár kunna ikki ferðast einsamøll';
      } else {
        warning = 'Please select guests. OBS! Children below 6 year old can not travel alone';
      }
    }

    if (this.departureLocation === undefined) {
      if (this.language === 'FO' || !this.language) {
        warning = 'Vinarliga vel fráferð';
      } else {
        warning = 'Please select a departure';
      }
    }

    if (this.returnTrip) {
      if ((this.departureDate === undefined || this.departureDate === '') && this.returnDate) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
      if ((this.returnDate === undefined || this.returnDate === '') && this.departureDate) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel heimferð dato';
        } else {
          warning = 'Please select return date';
        }
      }
      if ((this.returnDate === undefined || this.returnDate === '')
        && (this.departureDate === undefined || this.departureDate === '')) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
    } else {
      if (this.departureDate === undefined || this.departureDate === '') {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
    }

    if (warning) {
      alert(warning);
    } else {
      // const searchFields = [{
      //   lan: [],
      //   tour: 'Mykines / Sørvágur',
      //   departure: 'Sørvágur',
      //   return: 'Mykines',
      //   date: ['2018-05-16', '2018-05-17'],
      //   adults: 1,
      //   juniorSenior: 1,
      //   children: 1,
      //   returnTrip: this.returnTrip,
      // }];
      const getTour = this.tours[this.tours.findIndex(x => x.id === '1577720079820')];
      const extraTour = { tour: getTour.tour, touren: getTour.touren }
      if (extraTour.tour === this.searchFields[0].tour || extraTour.touren === this.searchFields[0].tour) {
        this.searchFields[0].extraTrip = true;
      } else {
        this.searchFields[0].extraTrip = false;
      }
      this.searchFields[0].adults = this.adults;
      this.searchFields[0].juniorSenior = this.juniorSenior;
      this.searchFields[0].children = this.children;
      this.searchFields[0].returnTrip = this.returnTrip;

      this.dialogRef = this.dialog.open(SearchComponent, {
        width: this.dialogWidth,
        height: this.dialogHeight,
        data: this.searchFields,
      });
    }

  }

  pad(s) { return (s < 10) ? '0' + s : s; }
  convertDate(inputFormat) {
    const d = new Date(inputFormat);
    return [this.pad(d.getUTCDate()), this.pad(d.getUTCMonth() + 1), d.getFullYear()].join('-');
  }

  compareRegDate(a, b) {
    if (a.reg_date > b.reg_date) {
      return 1;
    }
    if (a.reg_date < b.reg_date) {
      return -1;
    }
    return 0;
  }

  compareSeasonDate(a, b) {
    if (a.seasonStart > b.seasonStart) {
      return 1;
    }
    if (a.seasonStart < b.seasonStart) {
      return -1;
    }
    return 0;
  }

  compareNewsDate(a, b) {
    if (a.fromDate > b.fromDate) {
      return 1;
    }
    if (a.fromDate < b.fromDate) {
      return -1;
    }
    return 0;
  }

  rotateMessage(loopLength) {
    this.showMessage = [];
    for (let i = 0; i < loopLength; i += 3) {
      ((index) => {
        setTimeout(() => {
          this.showMessage = [];
          for (let t = 0; t < 3; t++) {
            if (this.messages[i + t]) {
              if (t === 0 && loopLength > this.countRotate) {
                this.countRotate = this.countRotate + 3;
                if (loopLength <= this.countRotate) {
                  this.countRotate = 0;
                }
              }
              this.showMessage.push(this.messages[i + t]);
            }
          }
        }, i * 1500);
      })(i);
    }
  }

  setLanguage(lan) {
    if (navigator.userAgent.match(/iPad/i) != null) {
      alert('ipad');
    }
    sessionStorage.setItem('language', lan);
    window.location.reload();
  }

  rotate(index) {
    if (this.count === index) {
      this.count = 0;
    }
    this.rotateTrip = this.count;
    setTimeout(() => {
      this.delay = this.count;
    }, 2000);
  }

  shuffle(a) {
    let j: number;
    let x: number;
    let i: number;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  bullets(i, first?) {
    if (i === this.count && !first) {

    } else {
      this.count = i;
      this.rotateTrip = this.count;
      this.delay = this.count;
      const index = this.trips.length;
      window.clearInterval(this.myTimer);

      this.myTimer = window.setInterval(() => { this.count++; this.rotate(index); }, 10000);
    }
  }

  mask() {
    if (
      this.tripDropdown ||
      this.locationDropdown ||
      this.dateDropdown ||
      this.timeDropdown ||
      this.guestsDropdown
    ) {
      this.activeSearch = -1;
      this.tripDropdown = false;
      this.locationDropdown = false;
      this.dateDropdown = false;
      this.timeDropdown = false;
      this.guestsDropdown = false;
    }

  }

  selectedMenu(menu) {
    this.activeMenu = menu;
    this.leftMenu = false;
    // if (menu === this.menues[0]) {
    //   this.bullets(this.count, true);
    // }
  }

  goToAdmin() {
    this.router.navigate(['admin']);
  }

  selectTour(value, trip?) {
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.activeSearch = trip;
      this.disabled = true;
      this.tripDropdown = true;
      this.departureDate = undefined;
      this.returnDate = undefined;
    } else {
      this.searchFields[0].hiking = this.tripsAccountType[value].hiking;
      this.accountType.hiking = this.tripsAccountType[value].hiking;
      this.accountType.trip = this.tripsAccountType[value].trip;

      this.activeSearch = -1;
      this.selectFrom(-1, 1);
      this.amountTrips = value;
      this.disabled = false;
      this.tripDropdown = false;
      this.searchFields[0].tour = trip;
      this.bookingService.getTours().then((info) => {
        const trips = [];
        const data = JSON.parse(info[0].tours);
        data.sort(this.compareSeasonDate);
        const today = new Date();
        const UTCtoday = today.getUTCFullYear() + '-' +
          ((today.getUTCMonth() + 1) < 10 ? '0' : '') +
          (today.getUTCMonth() + 1) + '-' +
          (today.getUTCDate() < 10 ? '0' : '') +
          today.getUTCDate();
        data.forEach((dataInfo) => {
          const seasonEnd = dataInfo.seasonEnd;
          if (seasonEnd >= UTCtoday) {
            trips.push(dataInfo);
          }
        });
        data.forEach((value) => {
          if (trip === value.tour || trip === value.touren) {
            if (this.searchFields[0].hiking === false) {
              this.searchFields[0].seats = value.seat;
            } else {
              this.searchFields[0].seats = '1';
            }
          }
        })

        for (let i = 0; i < Object.keys(trips[value].returntime).length; i++) {
          let returntime = trips[value].returntime[i];
          returntime = new Date(returntime);

          this.returnTime.push(
            (returntime.getUTCHours() < 10 ? '0' : '') +
            returntime.getUTCHours() + ':' +
            (returntime.getUTCMinutes() < 10 ? '0' : '') +
            returntime.getUTCMinutes());
        }
        for (let i = 0; i < Object.keys(trips[value].departuretime).length; i++) {
          let departuretime = trips[value].departuretime[i];
          departuretime = new Date(departuretime);

          this.departureTime.push(
            (departuretime.getUTCHours() < 10 ? '0' : '') +
            departuretime.getUTCHours() + ':' +
            (departuretime.getUTCMinutes() < 10 ? '0' : '') +
            departuretime.getUTCMinutes());
        }
        this.disableFrom = true;
        this.froms = [];
        this.froms.push(trips[value].departure);

        if (trips[value].return) {
          this.froms.push(trips[value].return);
        }
        if (this.froms.length < 2) {
          this.selectFrom(0, trips[value].departure);
          this.returnTrip = false;
          this.searchFields[0].returnTrip = false;
        } else {
          this.searchFields[0].returnTrip = true;
          this.returnTrip = true;
        }
        if (this.accountType.hiking) {
          this.returnTrip = false;
        }
        let startAt = new Date(trips[value].seasonStart);
        startAt = new Date(Date.UTC(startAt.getUTCFullYear(), startAt.getUTCMonth(), startAt.getUTCDate()));

        if (startAt < today) {
          this.startAt = today;
        } else {
          this.startAt = startAt;
        }
        const min = new Date(trips[value].seasonStart);
        this.searchFields[0].seasonStart = min;
        this.min = new Date(min.getUTCFullYear() + '/' + (min.getUTCMonth() + 1) + '/' + min.getUTCDate());

        if (today >= this.min) {
          this.min = today;
          this.min.setHours(0, 0, 0, 0);
        }
        const max = new Date(trips[value].seasonEnd);
        this.searchFields[0].seasonEnd = max;

        this.max = new Date(max.getUTCFullYear() + '/' + (max.getUTCMonth() + 1) + '/' + max.getUTCDate());
        this.cancelInfo.forEach((cancelInfo) => {
          const fromDate = new Date(cancelInfo.fromDate);
          fromDate.setHours(0, 0, 0, 0);
          const toDate = new Date(cancelInfo.toDate);
          toDate.setHours(0, 0, 0, 0);

          if (fromDate >= this.min && toDate <= this.max) {
            this.cancelDates.push({ from: fromDate, to: toDate, id: cancelInfo.tour });
          }
        });

        let man = 1;
        let tys = 2;
        let mik = 3;
        let hos = 4;
        let fri = 5;
        let ley = 6;
        let sun = 0;
        for (let dag = 0; dag < 7; dag++) {

          if (trips[value].chooseDay[dag] === true || this.tripsAccountType[value].hiking) {

            if (dag === 0) {
              man = -1;
            }
            if (dag === 1) {
              tys = -1;
            }
            if (dag === 2) {
              mik = -1;
            }
            if (dag === 3) {
              hos = -1;
            }
            if (dag === 4) {
              fri = -1;
            }
            if (dag === 5) {
              ley = -1;
            }
            if (dag === 6) {
              sun = -1;
            }
          }

          this.myFilter = (d: Date): boolean => {

            if (JSON.parse(this.searchFields[0].seats) === 0 && !this.developer) {
              return false;
            } else {
              d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
              const day = d.getUTCDay();
              d.setHours(0, 0, 0, 0);
             
                if(trip === 'Eykatúrur til Mykines'){
                  man = -1;
                  tys = -1;
                  mik = -1;
                  hos = -1;
                  fri = -1;
                  ley = -1;
                  sun = -1;
                }
                
              this.cancelDates.forEach((cancelDate) => {
                if (d >= cancelDate.from && d <= cancelDate.from && (trips[value].id === cancelDate.id)) {
                  if (day === 1) {
                    man = day;
                  }
                  if (day === 2) {
                    tys = day;
                  }
                  if (day === 3) {
                    mik = day;
                  }
                  if (day === 4) {
                    hos = day;
                  }
                  if (day === 5) {
                    fri = day;
                  }
                  if (day === 6) {
                    ley = day;
                  }
                  if (day === 0) {
                    sun = day;
                  }
                }
              });
              return day !== sun && day !== man && day !== tys && day !== mik && day !== hos && day !== fri && day !== ley;
            };

          }

        }
      }).catch(() => {

      });
    }
  }

  selectDeparture(event, sel?) {
    if (event <= -1) {
      this.mask();
      this.dateDropdown = true;
      this.activeSearch = sel;
    } else {
      if (this.returnTrip) {
        this.returnDate = undefined;
        if (sel === 'departure') {
          this.departureDate = event;
          this.returnMin = event;
          this.disableFrom = false;
        } else {
          this.returnDate = event;
          this.activeSearch = sel;
          this.selectGuests(-1, 3);
        }
        this.searchFields[0].date = [this.departureDate, this.returnDate];
      } else {
        this.activeSearch = sel;
        this.selectGuests(-1, 3);
        this.searchFields[0].date = [event];

      }
    }
  }

  // selectDepartureTime(value, sel, time?) {
  //   if (JSON.parse(value) <= -1) {
  //     this.timeDropdown = true;
  //   } else {
  //     if (sel === 'dep' || sel === 'depOnly') {
  //       this.depSelected = value;
  //       this.searchFields[0].time['departure'] = time;
  //     }
  //     if (sel === 'ret') {
  //       this.retSelected = value;
  //       this.searchFields[0].time['return'] = time;
  //     }
  //   }
  // }

  // closeTime() {
  //   this.timeDropdown = false;
  // }

  selectGuests(value, guest?) {
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.guestsDropdown = true;
      this.activeSearch = guest;
    } else {
      this.guestsDropdown = false;
      this.activeSearch = -1;
      this.searchFields[0].adults = this.adults;
      this.searchFields[0].juniorSenior = this.juniorSenior;
      this.searchFields[0].children = this.children;
      this.amountGuest = (this.adults + this.juniorSenior + this.children);
    }
  }

  selectFrom(value, from?) {
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.locationDropdown = true;
      this.searchFields[0].departure = '';
      this.activeSearch = from;
    } else {
      this.activeSearch = from;
      this.selectDeparture(-1, 2);
      this.locationDropdown = false;
      if (value === 0) {
        this.searchFields[0].return = this.froms[value + 1];
      } else {
        this.searchFields[0].return = this.froms[value - 1];
      }
      this.searchFields[0].departure = this.froms[value];

      this.departureLocation = from;
    }
  }

  messageInfo(i, value?) {
    let data: string;
    if (value === 'info') {
      data = this.infoes[i];
    } else if (value === 'message') {
      data = this.showMessage[i];
    } else {
      data = this.latestInfo[i];
    }

    this.dialogRef = this.dialog.open(MessageComponent, {
      width: this.dialogWidth,
      data: [data]
    });

    // if (info === true) {
    //   this.topInfo = true;
    //   const count = [0 + this.countRotate, 1 + this.countRotate, 2 + this.countRotate];
    //   i = count[i];
    //   this.messageInfoCtrl = i;
    // } else if (info === 'info') {
    //   this.infoInfoCtrl = i;
    // } else {
    //   this.messageInfoCtrl = i;
    //   this.topInfo = false;
    // }

  }

  order(tour) {
    this.dialogRef = this.dialog.open(OrderComponent, {
      data: [this.tourInfo[tour], this.trips[tour], tour]
    });
  }
  searchTrip() {
    this.mask();
    const tour = this.amountTrips;
    const d = new Date(this.departure);
    const departureDate = this.departure ? d.getFullYear() +
      '-' +
      (d.getUTCMonth() + 1 < 10 ? '0' : '') +
      (d.getUTCMonth() + 1) +
      '-' +
      (d.getUTCDate() < 10 ? '0' : '') +
      d.getUTCDate() : 'n/a';
    const departureTime = this.selectedDepartureTime;
    const departureLocation = this.departureLocation;

    alert(tour + '-' + departureLocation + '-' + departureDate + '-' + departureTime + '-' + this.amountGuest);

    // this.bookingService.registerBooking({
    //   tour: tour,
    //   name: name,
    //   phone: phone,
    //   email: email,
    //   comments: comments,
    //   departureDate: departureDate,
    //   departureTime: departureTime,
    //   departureLocation: departureLocation,
    //   returnDate: returnDate,
    //   returnTime: returnTime,
    //   returnLocation: returnLocation,
    //   adults: adults,
    //   juniorSenior: juniorSenior,
    //   children: children
    // }).then((data) => {
    //   if (data === '1') {
    //     alert('Bóking er goymd');
    //   } else {
    //     alert(data);
    //   }
    // });
  }
}
