import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CancelService {

  constructor(public http: HttpClient) {

  }

  updateRefundEmail(refundEmailInfo) {
    const link = 'https://mykines.vita.fo/php/updateRefundEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, refundEmailInfo)
        .subscribe(data => {
          // tslint:disable-next-line:no-string-literal
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  cancelTours(canceledInfo) {
    const link = 'https://mykines.vita.fo/php/cancelTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, canceledInfo)
        .subscribe(data => {
          // tslint:disable-next-line:no-string-literal
          resolve(data);
        }, error => {
          reject(error);
          console.log(error);
          console.log('Oooops!');
        });
    });
  }

  getCancelTours() {
    const link = 'https://mykines.vita.fo/php/getCancelTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          // tslint:disable-next-line:no-string-literal
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  deleteCancel(id) {
    const link = 'https://mykines.vita.fo/php/deleteCancel.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          // tslint:disable-next-line:no-string-literal
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
