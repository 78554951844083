<div >
    <div style="
    background-color: red;
    color: white;
    font-size: 1.3vw;
    line-height: 2.5vw;
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    text-align: center
    "
    (click)="onNoClick()">x</div>

</div>

<div [innerHTML]="data.text"></div>
