import { ConditionComponent, SearchComponent } from '../pages/search/search-booking';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from '../pages/admin/admin';
import { HomeComponent } from '../pages/home/home';
import { LoginModalComponent } from '../pages/login/login';
import { MessageComponent } from '../pages/message/message';
import { NgModule } from '@angular/core';
import { OrderComponent } from '../pages/order/order';

const routes: Routes = [ { path: '',
redirectTo: '/admin',
pathMatch: 'full'
},
{path: 'home', component: HomeComponent},
{path: 'admin', component: AdminComponent},
{path: 'search', component: SearchComponent},
{path: 'message', component: MessageComponent},
{path: 'condition', component: ConditionComponent},
{path: 'order', component: OrderComponent},
{path: 'login', component: LoginModalComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
