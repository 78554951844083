import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HikingService {

  constructor(public http: HttpClient) {

  }

  updatePaymentStatus(info) {
    console.log(info);
    const link = 'https://mykines.vita.fo/php/updatePaymentStatusHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {


          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!', error);
        });
    });
  }

  deleteHiking(id) {
    const link = 'https://mykines.vita.fo/php/deleteHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  registerHiking(hikingInfo) {
    const link = 'https://mykines.vita.fo/php/hiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, hikingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  searchHiking(year?) {
    const link = 'https://mykines.vita.fo/php/searchHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, year)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateHiking(hikingInfo) {
    const link = 'https://mykines.vita.fo/php/updateHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, hikingInfo, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateAvoidPayment(hikingInfo) {
    const link = 'https://mykines.vita.fo/php/updateAvoidPaymentHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, hikingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getHiking(hikingInfo) {
    const link = 'https://mykines.vita.fo/php/getHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link, hikingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getTours() {
    const link = 'https://mykines.vita.fo/php/getTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {

          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateTours(toursInfo) {
    const link = 'https://mykines.vita.fo/php/updateTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, toursInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
