import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Mykine.fo';
  browserVersion: string;
  browserType: string;
  publicIP: string;
  res: object;

  constructor(private router: Router, public http: HttpClient) {
  }

  ngOnInit() {
    // this.http.get('https://api.ipdata.co?api-key=7754d2a218cf8cd689195251c5188b2df6f05474d762fbbae9ed0acd')
    //   .subscribe((data) => {
    //     // this.publicIP = JSON.parse(data).ip; // Data from REST
    //     this.saveIP();
    //   });
  }
  saveIP() {
    // this.http.get(`https://api.ipdata.co/${this.publicIP}?api-key=7754d2a218cf8cd689195251c5188b2df6f05474d762fbbae9ed0acd`)
    //   .subscribe((data) => {
    //     this.res = JSON.parse(data);
    //     sessionStorage.setItem('countryInfo', this.res['country_code'] + ',' + this.res['country_name'] + ',' + this.res['city']);
    //   });
  }
}
