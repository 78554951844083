import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PageService {

  constructor(public http: HttpClient) {

  }

  updatePages(info) {
    const link = 'https://mykines.vita.fo/php/addPage.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getPages() {
    const link = 'https://mykines.vita.fo/php/getPages.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {

          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  deletePage(id) {
    const link = 'https://mykines.vita.fo/php/deletePage.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
