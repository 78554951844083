import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BookingService {

  constructor(public http: HttpClient) {

  }

  updatePdf(info) {
    const link = 'https://mykines.vita.fo/php/updatePdf.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  updatePaymentStatus(info) {
    const link = 'https://mykines.vita.fo/php/updatePaymentStatus.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  updateMultiplePaymentStatus(info) {
    const link = 'https://mykines.vita.fo/php/updateMultiplePaymentStatus.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  deleteBooking(id) {
    const link = 'https://mykines.vita.fo/php/deleteBooking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  registerBooking(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/booking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  registerHiking(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/hiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  searchBooking(year?) {
    const link = 'https://mykines.vita.fo/php/searchBooking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, year)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  updateBooking(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/updateBooking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  updateAvoidPayment(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/updateAvoidPayment.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  getBooking(bookingInfo?) {
    const link = 'https://mykines.vita.fo/php/getBooking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  getStats(bookingInfo?) {
    const link = 'https://mykines.vita.fo/php/getStats.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  
  getTours() {
    const link = 'https://mykines.vita.fo/php/getTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          console.error(error)
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  deleteRoute(id) {
    const link = 'https://mykines.vita.fo/php/deleteRoute.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {

          resolve(data);
        }, error => {
          console.error(error)
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  deleteTour(id) {
    const link = 'https://mykines.vita.fo/php/deleteTour.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {

          resolve(data);
        }, error => {
          console.error(error)
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  updateTours(toursInfo) {
    const link = 'https://mykines.vita.fo/php/updateTours.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, toursInfo)
        .subscribe(data => {
          console.error(data)
          resolve(data);
        }, error => {
          console.error(error)
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  updateRoute(route) {
    const link = 'https://mykines.vita.fo/php/updateRoute.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, route)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
  getRoutes() {
    const link = 'https://mykines.vita.fo/php/getRoute.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          console.error(error)
          reject(error);
          console.error('Oooops!');
        });
    });
  }

  updateRoutes(toursInfo) {
    const link = 'https://mykines.vita.fo/php/updateRoutes.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, toursInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.error('Oooops!');
        });
    });
  }
}
