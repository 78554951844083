import { ConditionComponent, SearchComponent } from '../pages/search/search-booking';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule
} from '@angular/material';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdminComponent } from '../pages/admin/admin';
import { AdsService } from '../services/ads-service/ads-service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BookingService } from '../services/booking-service/booking-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from 'ngx-ckeditor';
import { CancelService } from '../services/cancel-service/cancel-service';
import { EmailService } from '../services/email-service/email-service';
import { FormsModule } from '@angular/forms';
import { HikingService } from 'src/services/hiking-service/hiking-service';
import { HomeComponent } from '../pages/home/home';
import { HttpClientModule } from '@angular/common/http';
import { InfoService } from '../services/info-service/info-service';
import { LoginModalComponent } from '../pages/login/login';
import { MessageComponent } from '../pages/message/message';
import { NewsService } from '../services/news-service/new-service';
import { NgModule } from '@angular/core';
import { OrderComponent } from '../pages/order/order';
import { PageService } from '../services/page-service/page-service';
import { PaymentService } from '../services/payment-service/payment-service';
import { SafeHtmlPipe } from '../controller/safe-html';
import { UploadImageService } from '../services/uploadImage-service/uploadImage.service';
import { UserService } from '../services/user-service/user-service';

const routes: Routes = [{
  path: '',
  redirectTo: '/home',
  pathMatch: 'full'
}];

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    HomeComponent,
    SafeHtmlPipe,
    SearchComponent,
    ConditionComponent,
    OrderComponent,
    LoginModalComponent,
    MessageComponent
  ],
  imports: [
    IvyCarouselModule,
    FontAwesomeModule,
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  providers: [BookingService,
    HikingService,
    PageService,
    CancelService,
    NewsService,
    InfoService,
    EmailService,
    PaymentService,
    AdsService,
    UploadImageService,
    UserService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'da' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
