<div *ngIf="login" class="left-menu">
  <div class="profil-content">
    <div class="user">
      <div class="img">
        <img src="assets/img/logo.png" />
      </div>
      <div class="first-name">{{name}}</div>
      <div class="email">{{email}}</div>
    </div>
  </div>

  <div *ngFor="let menu of menus;let i = index" class="menu-content">
    <div
      [ngClass]="{'menu-active': activeMenu === menu.name}"
      tappable
      (click)="openMenu(menu.name)"
      class="menu"
    >
      <div class="icon">
        <fa-icon [icon]="menu.icon"></fa-icon>
      </div>
      <div class="text">{{menu.name}}</div>
    </div>
  </div>
  <div tappable (click)="signOut()" class="menu">
    <div class="icon">
      <fa-icon [icon]="faSignOutAlt"></fa-icon>
    </div>
    <div class="text">{{signout}}</div>
  </div>
</div>
<div class="second-content">
  <div class="ferdafolk-tol" *ngIf="activeMenu === 'Samla ferðafólka tøl'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div class="choose-tour">
        <select [(ngModel)]="tolTour" (ngModelChange)="getBookingTol()">
          <option
            *ngFor="let tour of tours; let i = index"
            [selected]="tolTour == tour.tour"
          >
            {{tour.tour}}
          </option>
        </select>
      </div>
      <div class="choose-radio">
        <input [(ngModel)]="tolRadio" type="radio" name="tol" value="ar" />
        Ár<br />
        <select
          [disabled]="tolRadio != 'ar'"
          [(ngModel)]="choosenYear"
          (ngModelChange)="getBookingTol()"
        >
          <option *ngFor="let a of ar; let i = index">{{a}}</option>
        </select>
      </div>
      <div class="choose-radio">
        Mánaðar<br />
        <select
          [disabled]="tolRadio != 'ar'"
          [(ngModel)]="choosenMonth"
          (ngModelChange)="getBookingTol()"
        >
          <option *ngFor="let manad of manadar; let i = index">
            {{manad.name}}
          </option>
        </select>
      </div>
      <div class="choose-radio">
        <input [(ngModel)]="tolRadio" type="radio" name="tol" value="dagar" />
        Dagar<br />

        <input
          class="dateInput"
          readonly
          [(ngModel)]="choosenDay"
          (ngModelChange)="getBookingTol()"
          [disabled]="tolRadio != 'dagar'"
          [owlDateTimeTrigger]="dt2"
          [owlDateTime]="dt2"
          type="button"
        />

        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
      </div>
    </div>
    <div class="list">
      <table>
        <tbody>
          <tr>
            <th>
              {{hiking? 'Ferðafólk við gongugjaldi' : '*Ferðfólk við retur
              atgongumerki'}}
            </th>
            <th>
              {{hiking? 'Samla søla í kr' : '*Ferðfólk við einvegis
              atgongumerki'}}
            </th>
            <th *ngIf="!hiking">**Samla søla í kr</th>
          </tr>
          <tr>
            <td
              *ngFor="let tal of hiking ? hikingPeople : ferdafolk; let i = index"
            >
              {{tal}}
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        *Ferðafólk sum hava fingið endurgolidið verða ikki tald við í
        ferðafólkatalinum. Tey í hava fingið faktura verða tald við
      </p>
      <p>
        **Ferðafólk sum hava fingið endurgolidið ella faktura verða ikki tald
        við í samla søluni.
      </p>
    </div>
  </div>
  <div *ngIf="activeMenu === 'Lýsingar'">
    <div class="list headline">{{activeMenu}}</div>
    <div tappable class="add-route-btn" (click)="addAd()">+</div>
    <div *ngFor="let ad of ads; let i = index">
      <div class="ad">
        <div class="link">
          <label for="link">Link</label>
          <input [(ngModel)]="ad.link" />
        </div>
        <div class="text">
          <div class="fo">
            <div>
              <label for="headlineFo">Headline Fo</label>
              <input [(ngModel)]="ad.headlineFo" />
            </div>
            <div>
              <label for="textFo">Text Fo</label>
              <textarea [(ngModel)]="ad.textFo"></textarea>
            </div>
          </div>
          <div class="en">
            <div>
              <label for="headlineEn">Headline En</label>
              <input [(ngModel)]="ad.headlineEn" />
            </div>
            <div>
              <label for="textEn">Text Fo</label>
              <textarea [(ngModel)]="ad.textEn"></textarea>
            </div>
          </div>
        </div>
        <div class="image">
          <div>
            <label for="image url">Image File Url</label>
            <input [(ngModel)]="ad.imageUrl" />
          </div>
          <div>
            <label for="image url">Image Logo</label>
            <input [(ngModel)]="ad.imageLogo" />
          </div>
        </div>
        <div class="cta">
          <div tappable (click)="deleteAd(i)" class="remove-tour-btn">
            Strika túr
          </div>
          <div tappable class="save-btn" (click)="saveAds(i)">Goym</div>
        </div>
      </div>
    </div>
  </div>

  <div class="cancel" *ngIf="activeMenu === 'Skráset brúkara'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div>
        Navn
        <input class="input" [(ngModel)]="userProfile.name" />
      </div>
      <div>
        Teldupost
        <input class="input" [(ngModel)]="userProfile.email" />
      </div>
      <div>
        Loyniorð
        <input type="password" class="input" [(ngModel)]="userProfile.psw" />
      </div>
      <div>
        Vátta loyniorð
        <input
          type="password"
          class="input"
          [(ngModel)]="userProfile.pswrepeat"
        />
      </div>
      <div tappable class="send-email-btn" (click)="register()">
        Skráset brúkara
      </div>
    </div>
    <div class="list" *ngFor="let user of users">
      <div class="user">{{user.name}}</div>
      <div tappable (click)="deleteUser(user['id'])" class="remove-booking-btn">
        <img src="assets/icons/delete.png" />
      </div>
    </div>
  </div>
  <div class="cancel" *ngIf="activeMenu === 'Treytir'">
    <div class="list headline">
      {{activeMenu}}
      <div class="selectLanguage">
        <span
          tappable
          (click)="selectConditionLan('FO')"
          [ngClass]="{'selected': conditionLan === 'FO' }"
          >Føroyskt</span
        >
        <span
          tappable
          (click)="selectConditionLan('EN')"
          [ngClass]="{'selected': conditionLan === 'EN' }"
          >English</span
        >
      </div>
    </div>
    <div class="list">
      <div *ngIf="conditionLan === 'FO'">
        <div>Skriva innihald á FO</div>
        <ck-editor
          name="editor1"
          [(ngModel)]="condition.conditionFo"
          skin="moono-lisa"
          language="en"
          [fullPage]="true"
        >
        </ck-editor>
        <div tappable class="save-booking" (click)="saveCondition()">
          <img src="assets/icons/save.png" />
        </div>
      </div>
      <div *ngIf="conditionLan === 'EN'">
        <div>Skriva innihald á EN</div>
        <ck-editor
          name="editor1"
          [(ngModel)]="condition.conditionEn"
          skin="moono-lisa"
          language="en"
          [fullPage]="true"
        >
        </ck-editor>
        <div tappable class="save-booking" (click)="saveCondition()">
          <img src="assets/icons/save.png" />
        </div>
      </div>
    </div>
  </div>
  <div class="cancel" *ngIf="activeMenu === 'Roknings móttakarar'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div tappable class="add-tour-btn" (click)="addEmail()">+</div>
      <div>Email</div>
      <div *ngFor="let email of emails">
        <div class="tourHeadlines">
          <input [(ngModel)]="email.email" />
        </div>
        <div
          tappable
          (click)="deleteEmail(email.email, email['id'])"
          class="remove-booking-btn"
        >
          <img src="assets/icons/delete.png" />
        </div>
        <div
          tappable
          class="save-booking"
          (click)="updateEmail(email.email, email['id'])"
        >
          <img src="assets/icons/save.png" />
        </div>
      </div>
    </div>
  </div>
	
	<!-- User discounts -->
	<div class="cancel" *ngIf="activeMenu === 'Avsláttur'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div
        tappable
        class="save-discounts-btn"
        (click)="updateDiscounts()"
				*ngIf="discountsChanged"
      >
          <img src="assets/icons/save.png" />
      </div>
			<div tappable class="add-tour-btn" (click)="addDiscountField()">+</div>
      <div>Teldupostur</div>
      <div *ngFor="let field of discountFields; let idx = index">
        <div class="tourHeadlines">
          <input [(ngModel)]="field.email" (change)="checkDiscountsChanged()" />
        </div>
				<div class="tourHeadlines">
          <input 
						type="number" 
						min="0" 
						max="100" 
						size="5" 
						[(ngModel)]="field.discountAmount" 
						(change)="checkDiscountsChanged()" />
						%
        </div>
        <div
          tappable
          (click)="deleteDiscountField(idx)"
          class="remove-booking-btn"
        >
          <img src="assets/icons/delete.png" />
        </div>
        
      </div>
    </div>
  </div>
	<!-- END user discounts -->

  <div class="cancel" *ngIf="activeMenu === 'Upplýsingar'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div>
        <div class="tourHeadlines">
          <div>Vel yvirskrift FØ</div>
          <input class="input" [(ngModel)]="infoHeadlineFo" />
        </div>
        <div class="tourHeadlines">
          <div>Vel yvirskrift EN</div>
          <input class="input" [(ngModel)]="infoHeadlineEn" />
        </div>
        <br />
        <div class="tourHeadlines">
          <div>Frágreiðing FØ:</div>
          <textarea class="input" [(ngModel)]="infoTekstFo"></textarea>
        </div>

        <div class="tourHeadlines">
          <div>Frágreiðing EN:</div>
          <textarea class="input" [(ngModel)]="infoTekstEn"></textarea>
        </div>
        <br />
        <div tappable class="save-cancel-btn" (click)="saveInfo()">Goym</div>
        <br />
        <div class="news list">
          <div>Allir upplýsingar</div>
          <div class="newsHeadlines">
            <span>Yvirskrift FØ</span>
            <span>Yvirskrift EN</span>
            <span>Frágreiðing FØ</span>
            <span>Frágreiðing EN</span>
          </div>
          <div class="allNews" *ngFor="let info of allInfo">
            <span>{{info.headlineFo}}</span>
            <span>{{info.headlineEn}}</span>
            <span>{{info.commentFo}}</span>
            <span>{{info.commentEn}}</span>
            <div
              tappable
              (click)="deleteInfo(info['id'])"
              class="remove-booking-btn"
            >
              <img src="assets/icons/delete.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cancel" *ngIf="activeMenu === 'Tíðindi'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list">
      <div>
        <div class="tourHeadlines">
          <div>Vel yvirskrift FØ</div>
          <input class="input" [(ngModel)]="newsHeadlineFo" />
        </div>
        <div class="tourHeadlines">
          <div>Vel yvirskrift EN</div>
          <input class="input" [(ngModel)]="newsHeadlineEn" />
        </div>
        <br />
        <div class="tourHeadlines">
          <div>Frágreiðing FØ:</div>
          <textarea class="input" [(ngModel)]="newsTekstFo"></textarea>
        </div>

        <div class="tourHeadlines">
          <div>Frágreiðing EN:</div>
          <textarea class="input" [(ngModel)]="newsTekstEn"></textarea>
        </div>
        <br />
        <div tappable class="save-cancel-btn" (click)="saveNews()">Goym</div>
        <br />
        <div class="news list">
          <div>Øll tíðindi</div>
          <div class="newsHeadlines">
            <span>Yvirskrift FØ</span>
            <span>Yvirskrift EN</span>
            <span>Frágreiðing FØ</span>
            <span>Frágreiðing EN</span>
          </div>
          <div class="allNews" *ngFor="let news of allNews">
            <span>{{news.headlineFo}}</span>
            <span>{{news.headlineEn}}</span>
            <span>{{news.commentFo}}</span>
            <span>{{news.commentEn}}</span>
            <div
              tappable
              (click)="deleteNews(news['id'])"
              class="remove-booking-btn"
            >
              <img src="assets/icons/delete.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="cancel" *ngIf="activeMenu === 'Avlýsing'">
    <div class="list headline">{{activeMenu}}</div>
    <div class="list" *ngFor="let tour of tours; let i = index">
      <div
        [ngClass]="{'rotateShowImg': selectedPage === i+1, 'rotateHideImg': selectedPage === false }"
        (click)="selectPageMenu(i+1)"
        class="list pageMenu"
      >
        {{tour.tour}}
        <img src="assets/icons/arrowdown.png" />
      </div>
      <div
        class="defaultHideMenu"
        [ngClass]="{'hideMenu': selectedPage === i+1, 'showMenu': selectedPage === false  }"
      >
        <div class="tourHeadlines">
          <div>Vel dato</div>
          <input
            class="input"
            readonly
            (dateTimeChange)="cancelRangeDate($event, i)"
            placeholder="Dato"
            [owlDateTimeTrigger]="dt10"
            [owlDateTime]="dt10"
            [selectMode]="'range'"
          />
          <!-- [min]="min"
            [max]="max"-->
          <img class="calImg" src="assets/icons/cal.png" />
          <owl-date-time
            [pickerType]="'calendar'"
            [startAt]="startAt"
            #dt10
          ></owl-date-time>
        </div>
        <div class="tourHeadlines">
          <div>Vel tíðspunkt</div>
          <select
            class="input"
            #cancelCtrl
            (change)="cancelTrip(cancelCtrl.value)"
          >
            <option value="0" disabled></option>
            <option *ngFor="let time of tripTime" [value]="time">
              {{time}}
            </option>
          </select>
        </div>
        <div class="tourHeadlines">
          <div>Avlýst verður vegna?</div>
          <select
            [(ngModel)]="selectedReason"
            class="input"
            #reasonCtrl
            (change)="reasonCancelTrip(reasonCtrl.value)"
          >
            <option
              [value]="r"
              *ngFor="let reason of reasonCancel; let r = index"
            >
              {{reason[r]}}
            </option>
          </select>
        </div>
        <div class="tourHeadlines">
          <div>Eyka boð</div>
          <textarea [(ngModel)]="extraMessage"></textarea>
        </div>
        <div
          tappable
          class="save-cancel-btn"
          (click)="saveCanceledTrip(tour.id)"
        >
          Goym
        </div>
        <br />
        <div class="canceled">
          <div>Avlýstir túrar</div>
          <table class="cancelTripsHeadlines">
            <tr>
              <th>Frá</th>
              <th>Til</th>
              <th>Tíðspunkt</th>
              <th>Vegna</th>
              <th>Eyka boð</th>
              <th>Teldupostar</th>
              <th>Endurinda</th>
            </tr>
            <tbody *ngFor="let canceled of canceledTrip; let id = index;">
              <tr class="cancelTrips" *ngIf="canceled.tour == tour.id">
                <th>{{canceled.fromDate}}</th>
                <th>{{canceled.toDate}}</th>
                <th>{{canceled.time}}</th>
                <th>{{reasonCancel[canceled.reason][canceled.reason]}}</th>
                <th>{{canceled.extraMessage}}</th>
                <th>{{canceled.sentEmail}}</th>
                <th>{{canceled.cancelRefund}}</th>
                <th
                  tappable
                  (click)="deleteCancel(canceled['id'])"
                  class="remove-booking-btn"
                >
                  <img src="assets/icons/delete.png" />
                </th>

                <th
                  tappable
                  (click)="sendCancelEmail(canceled, canceled['id'], i, id, canceled.extraMessage)"
                  class="send-email-btn"
                >
                  <img src="assets/icons/email.png" />
                </th>
                <th
                  tappable
                  (click)="sendCancelRefund(canceled, canceled['id'], i, id)"
                  class="send-refund-btn"
                >
                  <img src="assets/icons/refund.png" />
                </th>
                <th class="loading" *ngIf="loading === id">
                  <img src="assets/gif/loading.gif" />
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="activeMenu === 'Síðir'">
    <div class="list headline">
      {{activeMenu}}
      <div class="selectLanguage">
        <span
          tappable
          (click)="selectPageLanguage('FO')"
          [ngClass]="{'selected': languageSelect === 'FO' }"
          >Føroyskt</span
        >
        <span
          tappable
          (click)="selectPageLanguage('EN')"
          [ngClass]="{'selected': languageSelect === 'EN' }"
          >English</span
        >
      </div>
    </div>
    <div tappable class="add-tour-btn" (click)="addPage()">+</div>

    <div class="list" *ngFor="let menu of pageInfo; let i = index">
      <div *ngIf="languageSelect === 'FO'">
        <div
          [ngClass]="{'rotateShowImg': selectedPage === i+1, 'rotateHideImg': selectedPage === false }"
          (click)="selectPageMenu(i+1)"
          class="list pageMenu"
        >
          {{menu.menuFo}}
          <img src="assets/icons/arrowdown.png" />
        </div>
        <div
          class="defaultHideMenu"
          [ngClass]="{'hideMenu': selectedPage === i+1, 'showMenu': selectedPage === false  }"
        >
          <div>Navn á síðu</div>
          <input [(ngModel)]="menu.menuFo" />
          <div>Skriva innihald</div>
          <ck-editor
            name="editor1"
            [(ngModel)]="menu.contentFo"
            skin="moono-lisa"
            language="en"
            [fullPage]="true"
          >
          </ck-editor>
          <div tappable class="save-booking" (click)="savepage(i, menu.id)">
            <img src="assets/icons/save.png" />
          </div>
          <div
            tappable
            (click)="deletePage(i, menu.id)"
            class="remove-booking-btn"
          >
            <img src="assets/icons/delete.png" />
          </div>
        </div>
      </div>
      <div *ngIf="languageSelect === 'EN'">
        <div
          [ngClass]="{'rotateShowImg': selectedPage === i+1, 'rotateHideImg': selectedPage === false }"
          (click)="selectPageMenu(i+1)"
          class="list pageMenu"
        >
          {{menu.menuEn}}
          <img src="assets/icons/arrowdown.png" />
        </div>
        <div
          class="defaultHideMenu"
          [ngClass]="{'hideMenu': selectedPage === i+1, 'showMenu': selectedPage === false  }"
        >
          <div>Page name</div>
          <input [(ngModel)]="menu.menuEn" />
          <div>Add content</div>
          <ck-editor
            name="editor1"
            [(ngModel)]="menu.contentEn"
            skin="moono-lisa"
            language="en"
            [fullPage]="true"
          >
          </ck-editor>
          <div tappable class="save-booking" (click)="savepage(i, menu.id)">
            <img src="assets/icons/save.png" />
          </div>
          <div
            tappable
            (click)="deletePage(i, menu.id)"
            class="remove-booking-btn"
          >
            <img src="assets/icons/delete.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeMenu === 'Bókingar - Gongugjald'">
    <div class="list headline">
      <button class="filter-btn" (click)="print()">Print</button>
      <div>
        {{activeMenu}}
        <!-- <br>
            <span class="selectedDate">{{selctedDate}}</span>

            <div *ngIf="selctedDate">
              <div *ngFor="let tour of tours; let i = index">
                <div *ngIf="tours[i].seasonEnd >= selctedDate && tours[i].seasonStart <= selctedDate">
                  <div class="leftText">{{tours[i].tour}}</div>
                  <span class="left" *ngIf="tours[i].departuretime[0]">{{tours[i].seat - guestsLeftFirstTrip[i]}}
                    eftir kl. {{tours[i].departuretime[0]}} </span>
                  <span class="left" *ngIf="tours[i].departuretime[1]">{{tours[i].seat - guestsLeftSecondTrip[i]}}
                    eftir kl. {{tours[i].departuretime[1]}} </span>
                  <span class="left" *ngIf="tours[i].departuretime[2]">{{tours[i].seat - guestsLeftThirdTrip[i]}}
                    eftir kl. {{tours[i].departuretime[2]}} </span>
                  <span class="left" *ngIf="tours[i].departuretime[3]">{{tours[i].seat - guestsLeftFourthTrip[i]}}
                    eftir kl. {{tours[i].departuretime[3]}}</span>
                  <span class="left" *ngIf="tours[i].returntime[0]">{{tours[i].seat - guestsLeftFirstReturnTrip[i]}}
                    eftir kl. {{tours[i].returntime[0]}}</span>
                  <span class="left" *ngIf="tours[i].returntime[1]">{{tours[i].seat - guestsLeftSecondReturnTrip[i]}}
                    eftir kl. {{tours[i].returntime[1]}} </span>
                  <span class="left" *ngIf="tours[i].returntime[2]">{{tours[i].seat - guestsLeftThirdReturnTrip[i]}}
                    eftir kl. {{tours[i].returntime[2]}} </span>
                  <span class="left" *ngIf="tours[i].returntime[3]">{{tours[i].seat - guestsLeftFourthReturnTrip[i]}}
                    eftir kl. {{tours[i].returntime[3]}}</span>
                </div>
              </div>
            </div> -->
        <div>
          <input
            readonly
            [(ngModel)]="selectedTravelDate"
            (dateTimeChange)="selectedDate('hiking')"
            placeholder="Dato"
            [owlDateTimeTrigger]="dt123"
            [owlDateTime]="dt123"
          />
          <owl-date-time [pickerType]="'calendar'" #dt123></owl-date-time>
        </div>
      </div>
      <input
        class="leita"
        type="text"
        placeholder="Leita"
        (keyup)="search('hiking')"
        [(ngModel)]="leita"
      />
      <div class="list">
        <button class="filter-btn" (click)="clearFilter('hiking')">
          Heinta bókingar av nýggjum
        </button>
      </div>
      <div class="selectedDate">
        {{selectedFromDate}}
        <span *ngIf="selectedToDate !== selectedFromDate ">
          til {{selectedToDate}}</span
        >
      </div>
    </div>
    <div>
      <table>
        <!-- <span *ngFor="let time of (selctedDate?tripTime:['']); let t = index; "> -->
        <tr>
          <th class="regdate">
            <div>Dagført</div>
          </th>
          <th class="QuickPayID">
            <div>QuickPay ID</div>
          </th>
          <th class="turar">
            <div>Gongugjald</div>
          </th>
          <th>
            <div>
              <select
                #nameCtrl
                (change)="filterAscDesc(nameCtrl.value, 'name')"
              >
                <option value="1" [selected]="selected === true">Navn</option>
                <option value="asc">asc.</option>
                <option value="desc">desc.</option>
              </select>
            </div>
          </th>
          <th>
            <div>Email</div>
          </th>
          <th>
            <div>Boð</div>
          </th>
          <th class="bookingDateHeadline disableFromPrint">
            <div>Dato</div>
          </th>
          <th>
            <div>Vaksin</div>
          </th>
          <th>
            <div>Børn</div>
          </th>
          <th>
            <div>Smá børn</div>
          </th>
          <th>
            <div>Gjalding</div>
          </th>
          <th class="avoidPayment">
            <div>Umrinda aftur rindingar</div>
          </th>
        </tr>
        <tr
          class="list booking"
          *ngFor="let hiking of filterHikings | slice:0:sliceEnd; let i = index"
        >
          <th class="QuickPayID">
            <span
              [textContent]="hiking['reg_date']"
              (input)="hiking['reg_date']=$event.target.textContent"
            ></span>
          </th>
          <th class="QuickPayID">
            <span
              [textContent]="hiking['paymentid']"
              (input)="hiking['paymentid']=$event.target.textContent"
            ></span>
          </th>
          <th class="turar">
            <select class="selectTour" [(ngModel)]="hiking['tour']">
              <option [value]="">{{hiking['tour']}}</option>
              <option
                *ngFor="let tour of currentRegisterTours"
                [value]="tour.tourfo"
              >
                {{tour.tourfo}}
              </option>
            </select>
            <!-- <span contenteditable="true" [textContent]="booking['tour']" (input)="booking['tour']=$event.target.textContent"></span> -->
          </th>
          <th>
            <span
              contenteditable="true"
              [textContent]="hiking['name']"
              (input)="hiking['name']=$event.target.textContent"
            ></span>
          </th>
          <th>
            <span
              contenteditable="true"
              [textContent]="hiking['email']"
              (input)="hiking['email']=$event.target.textContent"
            ></span>
          </th>
          <th>
            <span
              [textContent]="hiking['comments']"
              (input)="hiking['comments']=$event.target.textContent"
            ></span>
          </th>
          <th class="disableFromPrint">
            <input
              readonly
              class="changeseasonStart"
              [(ngModel)]="hiking['date']"
              [owlDateTimeTrigger]="dt99"
              [owlDateTime]="dt99"
            />
            <owl-date-time [pickerType]="'calendar'" #dt99></owl-date-time>
            <!-- <span contenteditable="true" [textContent]="booking['seasonStart']" (input)="booking['seasonStart']=$event.target.textContent"></span> -->
          </th>

          <th>
            <select [(ngModel)]="hiking['adult']">
              <option [value]="0">0</option>
              <option
                *ngFor="let seat of seats"
                [selected]="hiking['adult'] == seat"
                [value]="seat"
              >
                {{seat}}
              </option>
            </select>
          </th>
          <th>
            <select [(ngModel)]="hiking['juniorSenior']">
              <option [value]="0">0</option>
              <option
                *ngFor="let seat of seats"
                [selected]="hiking['juniorSenior'] == seat"
                [value]="seat"
              >
                {{seat}}
              </option>
            </select>
            <!-- <span contenteditable="true" [textContent]="booking['juniorSenior']" (input)="booking['juniorSenior']=$event.target.textContent"></span> -->
          </th>
          <th>
            <select [(ngModel)]="hiking['children']">
              <option [value]="0">0</option>
              <option
                *ngFor="let seat of seats"
                [selected]="hiking['children'] == seat"
                [value]="seat"
              >
                {{seat}}
              </option>
            </select>
            <!-- <span contenteditable="true" [textContent]="booking['children']" (input)="booking['children']=$event.target.textContent"></span> -->
          </th>
          <th>
            <span
              [textContent]="hiking['payment']"
              (input)="hiking['payment']=$event.target.textContent"
            ></span>
          </th>
          <th class="avoidPayment">
            <input
              type="checkbox"
              [(ngModel)]="hiking['avoidPayment']"
              (click)="avoidPayment(hiking['id'], hiking['avoidPayment'], 'hiking')"
            />
          </th>
          <th
            tappable
            (click)="deleteHiking(hiking['id'], i)"
            class="remove-booking-btn"
          >
            <img src="assets/icons/delete.png" />
          </th>
          <th
            tappable
            class="save-booking"
            (click)="saveHiking(hiking['id'], i)"
          >
            <img src="assets/icons/save.png" />
          </th>
          <th
            appable
            (click)="cancelRefundSingle(hiking['paymentid'], i, 'hiking')"
            class="send-refund-btn"
          >
            <img src="assets/icons/refund.png" />
          </th>
        </tr>
        <!-- </span> -->
      </table>
    </div>
  </div>
  <div
    class="loadingBooking"
    align="center"
    *ngIf="(activeMenu === 'Bókingar - Túrar') && (!loadBooking)"
  >
    <h3>{{bookingValue}}</h3>
    <h2>Heintar dagførdar bókingar...</h2>
    <img src="assets/gif/loading.gif" />
  </div>
  <div *ngIf="(activeMenu === 'Bókingar - Túrar') && (loadBooking)">
    <div class="list headline">
      <button class="filter-btn" (click)="print()">Print</button>
      <div>
        {{activeMenu}}
        <br />
        <span class="selectedDate">{{selctedDate}}</span>
        <div *ngIf="selctedDate">
					<div *ngFor="let route of routes; let i = index">
            <div *ngIf=" route.tour > 0">
              <div
                *ngIf="getTour(route.tour)?.accountType === 'trip'"
                class="leftText"
              >
                {{getTour(route.tour)?.tour + ': ' + route.departureTime +' - '+
                route.returnTime}}
              </div>
              <span *ngFor="let guests of guestsLeftFirstTrip">
                <span class="left" *ngIf="guests.id === route.id"
                >{{getTour(route.tour).seat - guests.left}} eftir kl.
                {{guests.time}}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <input
            readonly
            [(ngModel)]="selectedTravelDate"
            (dateTimeChange)="selectedDate()"
            placeholder="Dato"
            [owlDateTimeTrigger]="dt101"
            [owlDateTime]="dt101"
          />
          <owl-date-time [pickerType]="'calendar'" #dt101></owl-date-time>
        </div>
      </div>
      <input
        class="leita"
        type="text"
        placeholder="Leita"
        (keyup)="search()"
        [(ngModel)]="leita"
      />
      <div class="list">
        <button class="filter-btn" (click)="clearFilter()">
          Heinta bókingar av nýggjum
        </button>
      </div>
      <div class="selectedDate">
        {{selectedFromDate}}
        <span *ngIf="selectedToDate !== selectedFromDate ">
          til {{selectedToDate}}</span
        >
      </div>
    </div>
    <div>
      <table>
        <span *ngFor="let time of (selctedDate?tripTime:['']); let t = index; ">
					<span class="printHeadline" *ngIf="selctedDate">
            <span *ngFor="let value of routes; let i = index">
              <span *ngIf="getFilterBookings(filterBookings, time, value.tour)">
                <h1 *ngIf="value.departureTime == time">
                  Frá {{value.departureCity}} {{'til ' + value.returnCity}}
                  kl.{{time}}
                </h1>
                <h1 *ngIf="value.returnTime == time">
                  Frá {{value.returnCity}} {{'til ' + value.departureCity}}
                  kl.{{time}}
                </h1>
              </span>
            </span>
          </span>
          <tbody >
            <tr>
              <th class="regdate">
                <div>Dagført</div>
              </th>
              <th class="QuickPayID">
                <div>QuickPay ID</div>
              </th>
              <th class="goldid">
                <div>Goldið í kr.</div>
              </th>
              <th class="turar">
                <div>Túrar</div>
              </th>
              <th>
                <div>
                  <select
                    #nameCtrl
                    (change)="filterAscDesc(nameCtrl.value, 'name')"
                  >
                    <option value="1" [selected]="selected === true">
                      Navn
                    </option>
                    <option value="asc">asc.</option>
                    <option value="desc">desc.</option>
                  </select>
                </div>
              </th>
              <th class="tlfList">
                <div>Tlf</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Boð</div>
              </th>
              <th class="disableFromPrint">
                <div class="fraferdtext" align="center">Fráferð</div>
                <div>Stað</div>
              </th>
              <th class="bookingDateHeadline disableFromPrint">
                <div class="fraferdtext" align="center">Fráferð</div>
                <div>dato</div>
              </th>
              <th class="disableFromPrint">
                <div class="fraferdtext" align="center">Fráferð</div>
                <div>kl.</div>
              </th>
              <th class="disableFromPrint">
                <div class="fraferdtext" align="center">Heimferð</div>
                <div>Stað</div>
              </th>
              <th class="disableFromPrint">
                <div class="fraferdtext" align="center">Heimferð</div>
                <div>Dato</div>
              </th>
              <th class="disableFromPrint">
                <div class="fraferdtext" align="center">Heimferð</div>
                <div>Kl.</div>
              </th>
              <th>
                <div>Vaksin</div>
              </th>
              <th>
                <div>Børn</div>
              </th>
              <th>
                <div>Smá børn</div>
              </th>
              <th>
                <div>Gjalding</div>
              </th>
              <th class="avoidPayment">
                <div>Umrinda aftur rindingar</div>
              </th>
            </tr>
            <tr
              class="list booking"
              *ngFor="let booking of filterBookings | slice:0:sliceEnd; let i = index"
            >
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  [textContent]="booking['reg_date']"
                  (input)="booking['reg_date']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="QuickPayID"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  [textContent]="booking['paymentid']"
                  (input)="booking['paymentid']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="goldid"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  [textContent]="booking['goldid']"
                  (input)="booking['goldid']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="turar"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select class="selectTour" [(ngModel)]="booking['tour']">
                  <option [value]="">{{booking['tour']}}</option>
                  <option
                    *ngFor="let tour of currentRegisterTours"
                    [value]="tour.tourfo"
                  >
                    {{tour.tourfo}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['tour']" (input)="booking['tour']=$event.target.textContent"></span> -->
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  contenteditable="true"
                  [textContent]="booking['name']"
                  (input)="booking['name']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="tlfList"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  contenteditable="true"
                  [textContent]="booking['phone']"
                  (input)="booking['phone']=$event.target.textContent"
                ></span>
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  contenteditable="true"
                  [textContent]="booking['email']"
                  (input)="booking['email']=$event.target.textContent"
                ></span>
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  contenteditable="true"
                  [textContent]="booking['comments']"
                  (input)="booking['comments']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['departureLocation']">
                  <option [value]=""></option>
                  <option
                    *ngFor="let loca of location"
                    [selected]="booking['departureLocation'] == loca"
                    [value]="loca"
                  >
                    {{loca}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['departureLocation']" (input)="booking['departureLocation']=$event.target.textContent"></span> -->
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <input
                  readonly
                  class="changeseasonStart"
                  [(ngModel)]="booking['departureDate']"
                  (dateTimeChange)="changeseasonStart(i)"
                  [owlDateTimeTrigger]="dt99"
                  [owlDateTime]="dt99"
                />
                <owl-date-time [pickerType]="'calendar'" #dt99></owl-date-time>
                <!-- <span contenteditable="true" [textContent]="booking['seasonStart']" (input)="booking['seasonStart']=$event.target.textContent"></span> -->
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['departureTime']">
                  <option [value]=""></option>
                  <option
                    *ngFor="let time of tripTime"
                    [selected]="booking['departureTime'] == time"
                    [value]="time"
                  >
                    {{time}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['departureTime']" (input)="booking['departureTime']=$event.target.textContent"></span> -->
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['returnLocation']">
                  <option [value]=""></option>
                  <option
                    *ngFor="let loca of location"
                    [selected]="booking['returnLocation'] == loca"
                    [value]="loca"
                  >
                    {{loca}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['returnLocation']" (input)="booking['returnLocation']=$event.target.textContent"></span> -->
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <input
                  readonly
                  class="changeseasonStart"
                  [(ngModel)]="booking['returnDate']"
                  (dateTimeChange)="changeseasonStart(i)"
                  [owlDateTimeTrigger]="dt99"
                  [owlDateTime]="dt99"
                />
                <owl-date-time [pickerType]="'calendar'" #dt99></owl-date-time>
                <!-- <span contenteditable="true" [textContent]="booking['seasonEnd']" (input)="booking['seasonEnd']=$event.target.textContent"></span> -->
              </th>
              <th
                class="disableFromPrint"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['returnTime']">
                  <option [value]=""></option>
                  <option
                    *ngFor="let time of tripTime"
                    [selected]="booking['returnTime'] == time"
                    [value]="time"
                  >
                    {{time}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['returnTime']" (input)="booking['returnTime']=$event.target.textContent"></span> -->
              </th>

              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['adults']">
                  <option [value]="0">0</option>
                  <option
                    *ngFor="let seat of seats"
                    [selected]="booking['adults'] == seat"
                    [value]="seat"
                  >
                    {{seat}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['adults']" (input)="booking['adults']=$event.target.textContent"></span> -->
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['juniorSenior']">
                  <option [value]="0">0</option>
                  <option
                    *ngFor="let seat of seats"
                    [selected]="booking['juniorSenior'] == seat"
                    [value]="seat"
                  >
                    {{seat}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['juniorSenior']" (input)="booking['juniorSenior']=$event.target.textContent"></span> -->
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <select [(ngModel)]="booking['children']">
                  <option [value]="0">0</option>
                  <option
                    *ngFor="let seat of seats"
                    [selected]="booking['children'] == seat"
                    [value]="seat"
                  >
                    {{seat}}
                  </option>
                </select>
                <!-- <span contenteditable="true" [textContent]="booking['children']" (input)="booking['children']=$event.target.textContent"></span> -->
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <span
                  [textContent]="booking['payment']"
                  (input)="booking['payment']=$event.target.textContent"
                ></span>
              </th>
              <th
                class="avoidPayment"
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="booking['avoidPayment']"
                  (click)="avoidPayment(booking['id'], booking['avoidPayment'])"
                />
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
                tappable
                (click)="deleteBooking(booking['id'], i)"
                class="remove-booking-btn"
              >
                <img src="assets/icons/delete.png" />
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
                tappable
                class="save-booking"
                (click)="saveBooking(booking['id'], i)"
              >
                <img src="assets/icons/save.png" />
              </th>
              <th
                *ngIf="time?(time == booking['departureTime']) || (time == booking['returnTime']):true"
                tappable
                (click)="cancelRefundSingle(booking['paymentid'], i)"
                class="send-refund-btn"
              >
                <img src="assets/icons/refund.png" />
              </th>
            </tr>
          </tbody>
        </span>
      </table>
    </div>
  </div>
  <div *ngIf="activeMenu === 'Farleið'">
    <div class="list headline">{{activeMenu}}</div>

    <div class="list tours" *ngFor="let tour of tours; let i = index">
      <div
        [ngClass]="{'rotateShowImg': selectedPage === tour.id}"
        (click)="selectPageMenu(tour.id)"
        class="list pageMenu"
      >
        {{tour.tour}}
        <img src="assets/icons/arrowdown.png" />
      </div>
      <div
        class="defaultHideMenu"
        [ngClass]="{'hideMenu': selectedPage === tour.id }"
      >
        <div tappable class="add-route-btn" (click)="addRoute(tour.id)">+</div>
        <table class="route-table">
          <tbody>
            <tr>
              <th>Tour</th>
              <th>Season Start</th>
              <th>Season End</th>
              <th *ngIf="tour.accountType === 'trip'">Departure Time</th>
              <th *ngIf="tour.accountType === 'trip'">Departure City</th>
              <th *ngIf="tour.accountType === 'hiking'">Place</th>
              <th *ngIf="tour.accountType === 'trip'">Return Time</th>
              <th *ngIf="tour.accountType === 'trip'">Return City</th>
              <th *ngIf="tour.accountType === 'trip'">Payment Method</th>
              <th *ngIf="tour.accountType === 'trip'">One Way</th>
              <th *ngIf="tour.accountType === 'trip'">Extra Trip</th>
            </tr>
            <ng-container *ngFor="let route of routes; let i = index">
              <tr *ngIf="route.tour == tour.id" class="route">
                <td>{{tour.tour}}</td>
                <td>
                  <input
                    class="dateInput"
                    [(ngModel)]="route.seasonStart"
                    (dateTimeChange)="setRoute($event, 'seasonStart', tour.id, i)"
                    [owlDateTimeTrigger]="dt3"
                    [owlDateTime]="dt3"
                    type="button"
                  />
                  <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
                </td>
                <td>
                  <input
                    class="dateInput"
                    [(ngModel)]="route.seasonEnd"
                    (dateTimeChange)="setRoute($event, 'seasonEnd', tour.id, i)"
                    [owlDateTimeTrigger]="dt5"
                    [owlDateTime]="dt5"
                    type="button"
                  />
                  <owl-date-time [pickerType]="'calendar'" #dt5></owl-date-time>
                </td>
                <td *ngIf="tour.accountType === 'trip'">
                  <input
                    class="dateInput"
                    [(ngModel)]="route.departureTimeFormat"
                    (dateTimeChange)="setRoute($event, 'departureTime', tour.id, i)"
                    [owlDateTimeTrigger]="dt4"
                    [owlDateTime]="dt4"
                    type="button"
                  />
                  <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
                </td>
                <td>
                  <input
                    [(ngModel)]="route.departureCity"
                    (change)="setRoute($event, 'departureCity', tour.id, i)"
                  />
                </td>

                <td *ngIf="tour.accountType === 'trip'">
                  <input
                    *ngIf="!route.oneway"
                    class="dateInput"
                    [(ngModel)]="route.returnTimeFormat"
                    (dateTimeChange)="setRoute($event, 'returnTime', tour.id, i)"
                    [owlDateTimeTrigger]="dt6"
                    [owlDateTime]="dt6"
                    type="button"
                  />
                  <owl-date-time [pickerType]="'timer'" #dt6></owl-date-time>
                </td>
                <td *ngIf="tour.accountType === 'trip'">
                  <input
                    *ngIf="!route.oneway"
                    [(ngModel)]="route.returnCity"
                    (change)="setRoute($event, 'returnCity', tour.id, i)"
                  />
                </td>
                <td *ngIf="tour.accountType === 'trip'">
                  <select
                    #payment
                    (change)="setRoute(payment, 'payment', tour.id, i)"
                  >
                    <option
                      *ngFor="let method of paymentMethods; let i = index"
                      [value]="method.value"
                      [selected]="route.payemntMethod == method.value"
                    >
                      {{method.name}}
                    </option>
                  </select>
                </td>
                <td *ngIf="tour.accountType === 'trip'">
                  <input
                    [checked]="route.oneway === 1 ? 1: 0"
                    [(ngModel)]="route.oneway"
                    type="checkbox"
                  />
                </td>
                <td *ngIf="tour.accountType === 'trip'">
                  <input
                    type="checkbox"
                    [checked]="route.extraTrip == 1 ? 1: 0"
                    (change)="setRoute($event, 'extraTrip', tour.id, i)"
                  />
                </td>
                <td>
                  <div tappable (click)="deleteRoute(i)" class="delete">
                    Delete
                  </div>
                  <div class="save" (click)="saveRute(i)">Save</div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="activeMenu === 'Túrar / Gongugjald'">
    <div class="list headline">{{activeMenu}}</div>
    <div tappable class="add-tour-btn" (click)="addTour()">+</div>

    <div class="list tours" *ngFor="let tour of tours; let i = index">
      <div
        [ngClass]="{'rotateShowImg': selectedPage === tour.id }"
        (click)="selectPageMenu(tour.id)"
        class="list pageMenu"
      >
        {{tour.tour}}
        <img src="assets/icons/arrowdown.png" />
      </div>

      <div
        class="defaultHideMenu"
        [ngClass]="{'hideMenu': selectedPage === tour.id }"
      >
        <div class="account-type">
          <div>
            Túrur:
            <input
              id="trip"
              [checked]="tour.accountType === 'trip' ? 1: 0"
              type="radio"
              (change)="accountTypeChange('trip', tour.id)"
            />
          </div>
          <div>
            Gongugjald:
            <input
              id="hiking"
              type="radio"
              [checked]="tour.accountType === 'hiking' ? 1: 0"
              (change)="accountTypeChange('hiking', tour.id)"
            />
          </div>
        </div>
        <div *ngIf="tour.accountType === 'trip'" class="weekdays">
          <div>
            <label for="man">Mánadag</label>
            <input
              [(ngModel)]="tour.weekdays[0]"
              name="man"
              id="man"
              type="checkbox"
            />
          </div>
          <div>
            <label for="tue">Týsdag</label>
            <input
              [(ngModel)]="tour.weekdays[1]"
              name="tue"
              id="tue"
              type="checkbox"
            />
          </div>
          <div>
            <label for="wed">Mikudag</label>
            <input
              [(ngModel)]="tour.weekdays[2]"
              name="wed"
              id="wed"
              type="checkbox"
            />
          </div>
          <div>
            <label for="thu">Hósdag</label>
            <input
              [(ngModel)]="tour.weekdays[3]"
              name="thu"
              id="thu"
              type="checkbox"
            />
          </div>
          <div>
            <label for="fri">Fríggjadag</label>
            <input
              [(ngModel)]="tour.weekdays[4]"
              name="fri"
              id="fri"
              type="checkbox"
            />
          </div>
          <div>
            <label for="sat">Leygardag</label>
            <input
              [(ngModel)]="tour.weekdays[5]"
              name="sat"
              id="sat"
              type="checkbox"
            />
          </div>
          <div>
            <label for="sun">Sunnudag</label>
            <input
              [(ngModel)]="tour.weekdays[6]"
              name="sun"
              id="sun"
              type="checkbox"
            />
          </div>
        </div>
        <div class="section">
          <div class="tourHeadlines">
            <div *ngIf="tour.accountType === 'trip'">Túrur FO:</div>
            <div *ngIf="tour.accountType === 'hiking'">Navn FO:</div>
            <input class="input" [(ngModel)]="tour.tour" />
          </div>
          <div class="tourHeadlines">
            <div *ngIf="tour.accountType === 'trip'">Túrur EN:</div>
            <div *ngIf="tour.accountType === 'hiking'">Navn EN:</div>
            <input class="input" [(ngModel)]="tour.touren" />
          </div>
          <div *ngIf="tour.accountType === 'trip'" class="tourHeadlines">
            <div>Mesta lagi ferðfólk:</div>
            <select
              class="input"
              #seatCtrl
              (change)="seat(seatCtrl.value, tour.id)"
            >
              <option [value]="tour.seat" selected>{{tour.seat}}</option>
              <option [value]="0">0</option>
              <option *ngFor="let seat of seats; let i=index" [value]="seat">
                {{seat}}
              </option>
            </select>
          </div>

          <div class="price">
            <div class="normal_price">
              <div>
                <div>Prís vaksin:</div>
                <input class="input" [(ngModel)]="tour.priceAdult" />
              </div>
              <div>
                <div>Prís pensjonistar og ung:</div>
                <input class="input" [(ngModel)]="tour.priceSeniorJunior" />
              </div>
              <div>
                <div>Prís børn:</div>
                <input class="input" [(ngModel)]="tour.priceChildren" />
              </div>
            </div>
            <div *ngIf="tour.accountType === 'trip'" class="extra_price">
              <div>
                <div>Prís vaksin (Eykatúrur):</div>
                <input class="input" [(ngModel)]="tour.priceAdultExtra" />
              </div>
              <div>
                <div>Prís pensjonistar og ung (Eykatúrur):</div>
                <input
                  class="input"
                  [(ngModel)]="tour.priceSeniorJuniorExtra"
                />
              </div>
              <div>
                <div>Prís børn (Eykatúrur):</div>
                <input class="input" [(ngModel)]="tour.priceChildrenExtra" />
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="tourHeadlines">
            <div>Stutt frágreiðing FØ max. 120:</div>
            <ck-editor
              name="editor1"
              [(ngModel)]="tour.fragreiding"
              skin="moono-lisa"
              language="en"
              [fullPage]="true"
            >
            </ck-editor>
          </div>

          <div class="tourHeadlines">
            <div>Stutt frágreiðing EN max. 120:</div>
            <ck-editor
              name="editor1"
              [(ngModel)]="tour.fragreidingEN"
              skin="moono-lisa"
              language="en"
              [fullPage]="true"
            >
            </ck-editor>
          </div>
        </div>

        <div class="cta">
          <div tappable (click)="deleteTour(tour.id)" class="remove-tour-btn">
            Strika túr
          </div>
          <div tappable class="save-btn" (click)="saveSettings()">Goym</div>
        </div>
      </div>
    </div>
  </div>
</div>
