import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UserService {

  constructor(public http: HttpClient) {

  }

  logIn(loginInfo) {
    const link = 'https://mykines.vita.fo/php/login.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, loginInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  registerUser(info) {
    const link = 'https://mykines.vita.fo/php/register.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          alert(error);
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getUsers() {
    const link = 'https://mykines.vita.fo/php/getUsers.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  deleteUser(id) {
    const link = 'https://mykines.vita.fo/php/deleteUser.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}

