import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { homeText, searchFields, searchText } from '../../app/app.constant';

import { BookingService } from '../../services/booking-service/booking-service';
import { SearchComponent } from '../search/search-booking';
import { ViewChild } from '../../../node_modules/@angular/core';
import { faClock, faHandPointer, faMapMarker, faCalendarAlt, faUsers, faShip, faHiking, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { CancelService } from 'src/services/cancel-service/cancel-service';


@Component({
  selector: 'app-order',
  templateUrl: './order.html',
  styleUrls: ['./order.scss']
})

export class OrderComponent {
  @ViewChild('departureInput', { static: true }) departureInput: ElementRef;
  @ViewChild('returnInput', { static: true }) returnInput: ElementRef;
  fullBookedDateDeparture = [];
  fullBookedDateReturn = [];
  myFilterDeparture: any;
  myFilterReturn: any;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faShip = faShip;
  cancelInfo: any;
  cancelDates = [];
  faHiking = faHiking;
  faUsers = faUsers;
  faClock = faClock;
  faHandPointer = faHandPointer;
  faMapMarker = faMapMarker;
  faCalendarAlt = faCalendarAlt;
  localhost = window.location.hostname;
  menues = [];
  activeMenu = [];
  trips = [];
  tripsAccountType = [];
  amountTrips: number;
  froms = [];
  guests = [];
  amountGuest: number;
  backgroundSrc = [];
  delay: number;
  price = [];
  tourInfo = [];
  latestInfo = [];
  infoes = [];
  pagesText = [];
  pageBackground: string;
  homeText = {
    returnTripText: '',
    juniorSeniorText: '',
    orderHere: '',
    adults: '',
    childrenText: '',
    from: '',
    readmore: '',
    headline: [],
    contact: homeText.contact
  };

  dialogWidth: string;
  dialogHeight: string;

  tripDropdown: boolean;
  locationDropdown: boolean;
  dateDropdown: boolean;
  timeDropdown: boolean;
  guestsDropdown: boolean;

  departure: any;
  departureTime = [];
  returnTime = [];
  startAt = new Date();
  returnMin = new Date();
  min = new Date();
  max = new Date();
  myFilter: any;
  selectedDepartureTime = { departure: '', return: '' };
  departureLocation: string;
  disabled = true;
  rotateTrip: number;
  myTimer: any;
  count = 0;
  language = sessionStorage.getItem('language');
  messages = [];
  showMessage = [];
  cancel: boolean;
  showMessageInfo: string;
  infoInfoCtrl = -1;
  messageInfoCtrl = -1;
  countRotate = -3;
  topInfo: boolean;
  loopLength: number;
  reset: false;
  adults = 0;
  juniorSenior = 0;
  children = 0;
  returnTrip = true;
  depSelected: number;
  retSelected: number;
  departureDate: any;
  returnDate: any;
  searchFields = [{
    hiking: false,
    lan: [],
    tour: '',
    departure: '',
    return: '',
    date: [],
    adults: 0,
    juniorSenior: 0,
    children: 0,
    returnTrip: this.returnTrip,
    seats: '0',
    seasonStart: new Date(),
    seasonEnd: new Date()
  }];
  disableFrom: boolean;
  cancelText: string;
  activeSearch: number;
  tours: any;
  accountType = { hiking: false, trip: false };

  constructor(
    public cancelService: CancelService,
    public dialogRef: MatDialogRef<OrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public bookingService: BookingService,
    public dialog: MatDialog) {
    if (window.innerWidth > 1024) {
      this.dialogWidth = '50vw';
      this.dialogHeight = '';
    } else {
      this.dialogWidth = '100vw';
      this.dialogHeight = '100vw';
    }
    this.bookingService.getTours().then((info) => {
      if (this.language === 'FO' || !this.language) {
        this.searchFields[0].lan = searchFields.fo;
        this.homeText.from = homeText.from.fo;
        this.homeText.adults = homeText.adults.fo;
        this.homeText.orderHere = homeText.orderHere.fo;
        this.homeText.readmore = homeText.readmore.fo;
        this.homeText.headline = homeText.headline.fo;
        this.homeText.juniorSeniorText = homeText.juniorSeniorText.fo;
        this.homeText.childrenText = homeText.childrenText.fo;
        this.homeText.returnTripText = homeText.returnTripText.fo;
        this.cancelText = searchText.cancel.fo;
      } else {
        this.searchFields[0].lan = searchFields.en;
        this.homeText.from = homeText.from.en;
        this.homeText.adults = homeText.adults.en;
        this.homeText.orderHere = homeText.orderHere.en;
        this.homeText.readmore = homeText.readmore.en;
        this.homeText.headline = homeText.headline.en;
        this.homeText.juniorSeniorText = homeText.juniorSeniorText.en;
        this.homeText.childrenText = homeText.childrenText.en;
        this.homeText.returnTripText = homeText.returnTripText.en;
        this.cancelText = searchText.cancel.en;
      }

      const trips = JSON.parse(info[0].tours);
      trips.sort(this.compareSeasonDate);
      this.tours = trips;
      for (let i = 0; i < trips.length; i++) {

        const dateCurrent = new Date();
        const UTCDate = dateCurrent.getUTCFullYear() + '-' +
          ((dateCurrent.getUTCMonth() + 1) < 10 ? '0' : '') +
          (dateCurrent.getUTCMonth() + 1) + '-' +
          (dateCurrent.getUTCDate() < 10 ? '0' : '') +
          dateCurrent.getUTCDate();

        if (trips[i].seasonEnd >= UTCDate) {
          this.price.push(trips[i].priceAdult);
          if (this.language === 'FO' || !this.language) {
            this.trips.push(trips[i].tour);
            this.tourInfo.push(trips[i].fragreiding);
          } else {
            this.tourInfo.push(trips[i].fragreidingEN);
            this.trips.push(trips[i].touren);
          }
          this.tripsAccountType.push(trips[i].accountType);
        } else if (i === 0) {
          // alert(trips[i].tour);
          // if (this.language === 'FO' || !this.language) {
          //   this.trips.push(trips[i].tour);
          //   this.tourInfo.push(trips[i].fragreiding);
          // } else {
          //   this.tourInfo.push(trips[i].fragreidingEN);
          //   this.trips.push(trips[i].touren);
          // }
          // if (this.width < 500) {
          //   this.backgroundSrc.push('assets/img/' + i + '_mobile.png');
          // } else if (this.width > 500 && this.width < 1024) {
          //   this.backgroundSrc.push('assets/img/' + i + '_tablet.png');
          // } else {
          //   this.backgroundSrc.push('assets/img/' + i + '.png');
          // }
        }
      }
      this.cancelService.getCancelTours().then((cancelInfo) => {
        this.cancelInfo = cancelInfo;
      })
      this.selectTour(data[2], data[1]);
    }).catch(() => {

    });
  }

  soldOutCalender(direction) {
    const primaryTour = this.tours[this.tours.findIndex(x => x.id === '1577719642953')];
    const getTour = this.tours[this.tours.findIndex(x => x.id === '1577720079820')];
    const extraTour = {tour: getTour.tour, touren: getTour.touren}
    if (extraTour.tour === this.searchFields[0].tour || extraTour.touren === this.searchFields[0].tour) {
      var seat = JSON.parse(primaryTour.seat);
      var departureLocation = this.searchFields[0].departure;
      var returnLocation = this.searchFields[0].return;
      var allDepartureDate = [];
      var allReturnDate = [];
      this.bookingService.getBooking({ future: 'true' }).then((bookedDate) => {
        bookedDate.forEach((value) => {
          if (direction === 'departure') {
            if (primaryTour.tour === value.tour &&
              departureLocation === value.departureLocation) {

              if (new Date(value.departureDate) > new Date()) {
                const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
                const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
                if (index === -1) {
                  allDepartureDate.push({ departureDate: value.departureDate, countPeople });
                } else {
                  allDepartureDate[index].countPeople = allDepartureDate[index].countPeople + countPeople;
                }
              }
            }
            if (primaryTour.tour === value.tour &&
              departureLocation === value.returnLocation) {

              if (new Date(value.departureDate) > new Date()) {
                const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
                const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
                if (index === -1) {
                  allDepartureDate.push({ departureDate: value.returnDate, countPeople });
                } else {
                  allDepartureDate[index].countPeople = allDepartureDate[index].countPeople + countPeople;
                }
              }


            }
          } else {
            if (primaryTour.tour === value.tour && returnLocation === value.returnLocation) {

              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
              if (index === -1) {
                allReturnDate.push({ returnDate: value.returnDate, countPeople });
              } else {
                allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
              }
            }
            if (primaryTour.tour === value.tour && returnLocation === value.departureLocation) {

              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
              if (index === -1) {
                allReturnDate.push({ returnDate: value.departureDate, countPeople });
              } else {
                allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
              }
            }
          }
        })

      }).then(() => {
        if (direction === 'departure') {
          allDepartureDate.forEach((x) => {
            if (x.countPeople >= seat && seat !== 0) {
              var stringCount = new Date(x.departureDate);
              stringCount.setHours(0, 0, 0, 0);
              this.fullBookedDateDeparture.push(stringCount.toString())
            }
          });
        } else {
          allReturnDate.forEach((x) => {
            if (x.countPeople >= seat && seat !== 0) {
              const stringCount = new Date(x.returnDate);
              stringCount.setHours(0, 0, 0, 0);
              this.fullBookedDateReturn.push(stringCount.toString())
            }
          });
        }

        if (direction === 'departure') {
          this.myFilterDeparture = (d: Date): boolean => {
            d.setHours(0, 0, 0, 0);

            if (this.fullBookedDateDeparture.includes(d.toString())) {

              return true;
            } else {
              return false;
            }

          }
        } else {
          this.myFilterReturn = (d: Date): boolean => {
            d.setHours(0, 0, 0, 0);
            if (this.fullBookedDateReturn.includes(d.toString())) {
              return true;
            } else {
              return false;
            }

          }
        }
      })
    } else {
      this.myFilterReturn = undefined;
      this.myFilterDeparture = undefined;
    }
  }


  mask() {
    if (
      this.tripDropdown ||
      this.locationDropdown ||
      this.dateDropdown ||
      this.timeDropdown ||
      this.guestsDropdown
    ) {
      this.activeSearch = -1;
      this.tripDropdown = false;
      this.locationDropdown = false;
      this.dateDropdown = false;
      this.timeDropdown = false;
      this.guestsDropdown = false;
    }

  }

  compareSeasonDate(a, b) {
    if (a.seasonStart > b.seasonStart) {
      return 1;
    }
    if (a.seasonStart < b.seasonStart) {
      return -1;
    }
    return 0;
  }

  selectTour(value, trip?) {
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.activeSearch = trip;
      this.disabled = true;
      this.tripDropdown = true;
      this.departureDate = undefined;
      this.returnDate = undefined;
    } else {
      this.searchFields[0].hiking = this.tripsAccountType[value].hiking;
      this.accountType.hiking = this.tripsAccountType[value].hiking;
      this.accountType.trip = this.tripsAccountType[value].trip;
      this.activeSearch = -1;
      this.selectFrom(-1, 1);
      this.amountTrips = value;
      this.disabled = false;
      this.tripDropdown = false;
      this.searchFields[0].tour = trip;
      this.bookingService.getTours().then((info) => {
        const trips = [];
        const data = JSON.parse(info[0].tours);
        data.sort(this.compareSeasonDate);
        const today = new Date();
        const UTCtoday = today.getUTCFullYear() + '-' +
          ((today.getUTCMonth() + 1) < 10 ? '0' : '') +
          (today.getUTCMonth() + 1) + '-' +
          (today.getUTCDate() < 10 ? '0' : '') +
          today.getUTCDate();

        data.forEach((dataInfo) => {
          const seasonEnd = dataInfo.seasonEnd;
          if (seasonEnd >= UTCtoday) {
            trips.push(dataInfo);
          }
        });
        data.forEach((value) => {
          if (trip === value.tour || trip === value.touren) {
            if (this.searchFields[0].hiking === false) {
              this.searchFields[0].seats = value.seat;
            } else {
              this.searchFields[0].seats = '1';
            }
          }
        })
        for (let i = 0; i < Object.keys(trips[value].returntime).length; i++) {
          let returntime = trips[value].returntime[i];
          returntime = new Date(returntime);

          this.returnTime.push(
            (returntime.getUTCHours() < 10 ? '0' : '') +
            returntime.getUTCHours() + ':' +
            (returntime.getUTCMinutes() < 10 ? '0' : '') +
            returntime.getUTCMinutes());
        }
        for (let i = 0; i < Object.keys(trips[value].departuretime).length; i++) {
          let departuretime = trips[value].departuretime[i];
          departuretime = new Date(departuretime);

          this.departureTime.push(
            (departuretime.getUTCHours() < 10 ? '0' : '') +
            departuretime.getUTCHours() + ':' +
            (departuretime.getUTCMinutes() < 10 ? '0' : '') +
            departuretime.getUTCMinutes());
        }
        this.disableFrom = true;
        this.froms = [];
        this.froms.push(trips[value].departure);
        if (trips[value].return) {
          this.froms.push(trips[value].return);
        }

        if (this.froms.length < 2) {
          this.selectFrom(0, trips[value].departure);
          this.returnTrip = false;
          this.searchFields[0].returnTrip = false;
        } else {
          this.searchFields[0].returnTrip = true;
          this.returnTrip = true;
        }
        let startAt = new Date(trips[value].seasonStart);
        startAt = new Date(Date.UTC(startAt.getUTCFullYear(), startAt.getUTCMonth(), startAt.getUTCDate()));

        if (startAt < today) {
          this.startAt = today;
        } else {
          this.startAt = startAt;
        }
        const min = new Date(trips[value].seasonStart);
        this.searchFields[0].seasonStart = min;
        this.min = new Date(min.getUTCFullYear() + '/' + (min.getUTCMonth() + 1) + '/' + min.getUTCDate());


        if (today >= this.min) {
          this.min = today;
          this.min.setHours(0, 0, 0, 0);
        }
        const max = new Date(trips[value].seasonEnd);
        this.searchFields[0].seasonEnd = max;
        this.max = new Date(max.getUTCFullYear() + '/' + (max.getUTCMonth() + 1) + '/' + max.getUTCDate());
        this.cancelInfo.forEach((cancelInfo) => {
          const fromDate = new Date(cancelInfo.fromDate);
          fromDate.setHours(0, 0, 0, 0);
          const toDate = new Date(cancelInfo.toDate);
          toDate.setHours(0, 0, 0, 0);

          if (fromDate >= this.min && toDate <= this.max) {
            this.cancelDates.push({ from: fromDate, to: toDate, id: cancelInfo.tour });
          }
        });

        let man = 1;
        let tys = 2;
        let mik = 3;
        let hos = 4;
        let fri = 5;
        let ley = 6;
        let sun = 0;
        for (let dag = 0; dag < 7; dag++) {
          if (trips[value].chooseDay[dag] === true  || this.tripsAccountType[value].hiking) {
            if (dag === 0) {
              man = -1;
            }
            if (dag === 1) {
              tys = -1;
            }
            if (dag === 2) {
              mik = -1;
            }
            if (dag === 3) {
              hos = -1;
            }
            if (dag === 4) {
              fri = -1;
            }
            if (dag === 5) {
              ley = -1;
            }
            if (dag === 6) {
              sun = -1;
            }
          }
          this.myFilter = (d: Date): boolean => {
            
            if (JSON.parse(this.searchFields[0].seats) === 0) {
              return false;
            } else {
              d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
              const day = d.getUTCDay();
              d.setHours(0, 0, 0, 0);
              this.cancelDates.forEach((cancelDate) => {
                if (d >= cancelDate.from && d <= cancelDate.from && (trips[value].id === cancelDate.id)) {
                  if (day === 0) {
                    man = day;
                  }
                  if (day === 1) {
                    tys = day;
                  }
                  if (day === 2) {
                    mik = day;
                  }
                  if (day === 3) {
                    hos = day;
                  }
                  if (day === 4) {
                    fri = day;
                  }
                  if (day === 5) {
                    ley = day;
                  }
                  if (day === 6) {
                    sun = day;
                  }
                }
              });
              return day !== sun && day !== man && day !== tys && day !== mik && day !== hos && day !== fri && day !== ley;
            };
          };

        }
      }).catch(() => {

      });
    }
  }

  openCalender(value) {
    value === 'departure' ? this.departureInput.nativeElement.click() : this.returnInput.nativeElement.click();
  }

  selectFrom(value, from?) {
    this.selectDeparture(-1);
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.locationDropdown = true;
      this.activeSearch = from;
    } else {
      this.activeSearch = from;
      this.selectDeparture(-1, 2);
      this.locationDropdown = false;
      if (value === 0) {
        this.searchFields[0].return = this.froms[value + 1];
      } else {
        this.searchFields[0].return = this.froms[value - 1];
      }
      this.searchFields[0].departure = this.froms[value];

      this.departureLocation = from;
    }
  }

  selectDeparture(event, sel?) {
    if (event <= -1) {
      this.mask();
      this.dateDropdown = true;
      this.activeSearch = sel;
    } else {
      if (this.returnTrip) {
        this.returnDate = undefined;
        if (sel === 'departure') {
          this.departureDate = event;
          this.returnMin = event;
          this.disableFrom = false;
        } else {
          this.returnDate = event;
          this.activeSearch = sel;
          this.selectGuests(-1, 3);
        }
        this.searchFields[0].date = [this.departureDate, this.returnDate];
      } else {
        this.activeSearch = sel;
        this.selectGuests(-1, 3);
        this.searchFields[0].date = [event];

      }
    }
  }

  selectGuests(value, guest?) {
    if (JSON.parse(value) <= -1) {
      this.mask();
      this.guestsDropdown = true;
      this.activeSearch = guest;
    } else {
      this.guestsDropdown = false;
      this.activeSearch = -1;
      this.searchFields[0].adults = this.adults;
      this.searchFields[0].juniorSenior = this.juniorSenior;
      this.searchFields[0].children = this.children;
      this.amountGuest = (this.adults + this.juniorSenior + this.children);
    }
  }

  addGuests(count, type) {
    if (type === 0) {
      this.adults = this.adults + count;
    }
    if (type === 1) {
      this.juniorSenior = this.juniorSenior + count;
    }
    if (type === 2) {
      this.children = this.children + count;
    }
  }

  searchBooking(): void {
    this.mask();
    let warning: string;

    if (this.adults !== 0 || this.juniorSenior !== 0) {
    } else {
      if (this.language === 'FO' || !this.language) {
        warning = 'Vinarliga vel gestir. \n\nGev gætur!\n Børn undir 6 ár kunna ikki ferðast einsamøll';
      } else {
        warning = 'Please select guests. OBS! Children below 6 year old can not travel alone';
      }
    }

    if (this.departureLocation === undefined) {
      if (this.language === 'FO' || !this.language) {
        warning = 'Vinarliga vel fráferð';
      } else {
        warning = 'Please select a departure';
      }
    }

    if (this.returnTrip) {
      if ((this.departureDate === undefined || this.departureDate === '') && this.returnDate) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
      if ((this.returnDate === undefined || this.returnDate === '') && this.departureDate) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel heimferð dato';
        } else {
          warning = 'Please select return date';
        }
      }
      if ((this.returnDate === undefined || this.returnDate === '')
        && (this.departureDate === undefined || this.departureDate === '')) {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
    } else {
      if (this.departureDate === undefined || this.departureDate === '') {
        if (this.language === 'FO' || !this.language) {
          warning = 'Vinarliga vel fráferð dato';
        } else {
          warning = 'Please select departure date';
        }
      }
    }

    if (warning) {
      alert(warning);
    } else {
      // const searchFields = [{
      //   lan: [],
      //   tour: 'Mykines / Sørvágur',
      //   departure: 'Sørvágur',
      //   return: 'Mykines',
      //   date: ['2018-05-16', '2018-05-17'],
      //   adults: 1,
      //   juniorSenior: 1,
      //   children: 1,
      //   returnTrip: this.returnTrip,
      // }];
      this.searchFields[0].adults = this.adults;
      this.searchFields[0].juniorSenior = this.juniorSenior;
      this.searchFields[0].children = this.children;
      this.searchFields[0].returnTrip = this.returnTrip;
      const dialogRef = this.dialog.open(SearchComponent, {
        width: this.dialogWidth,
        height: this.dialogHeight,
        data: this.searchFields,
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
