import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class NewsService {

  constructor(public http: HttpClient) {

  }

  addNews(newsInfo) {
    const link = 'https://mykines.vita.fo/php/addNews.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, newsInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getNews() {
    const link = 'https://mykines.vita.fo/php/getNews.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  deleteNews(id) {
    const link = 'https://mykines.vita.fo/php/deleteNews.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
