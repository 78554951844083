<div class="container">
  <div class="modal animate">
    <div class="headline">
        <h2>Vælkomin</h2>
    </div>
    <div class="input-fields">
      <div class="email">
          <input [ngClass]="{'linear-gradient': linearGradientEmail}" placeholder="Teldupostur" type="text" name="uname" (focus)="animateEmail(true)" (blur)="animateEmail(false)" [(ngModel)]="email"  (keyup.enter)="keytab('email')" required>
      </div>
      <div class="psw">
          <input [ngClass]="{'linear-gradient': linearGradientPsw}" placeholder="Loyniorð" type="password" name="psw" (focus)="animatePsw(true)" (blur)="animatePsw(false)" [(ngModel)]="psw"  (keyup.enter)="keytab('psw')" required>

      </div>
    </div>
    <div tappable class="login-button" (click)="logIn()">Login</div>
  </div>
</div>
