<div >
  <div class="close-btn" (click)="closeDialog()">X</div>
</div>
<div class="wrap" *ngFor="let message of data">
  <div class="latestDate" *ngIf="message.date">
    <div>{{message.date}}</div>
    {{message.month}}
    <div class="img">
      <img src="assets/img/latest.png">
    </div>
  </div>
  <div class="messageText">
    <div>{{message.headline}}</div>
    <div>{{message.text}}</div>
  </div>
</div>
