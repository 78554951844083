import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { BookingService } from '../../services/booking-service/booking-service';
import { CancelService } from '../../services/cancel-service/cancel-service';
import { EmailService } from '../../services/email-service/email-service';
import { InfoService } from '../../services/info-service/info-service';
import { PaymentService } from '../../services/payment-service/payment-service';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { searchText, homeText } from '../../app/app.constant';

@Component({
  selector: 'app-search-booking',
  templateUrl: './search-booking.html',
  styleUrls: ['./search-booking.scss']
})

export class SearchComponent {
  departureHide = true;
  faCalendarAlt = faCalendarAlt;
  tour: any;
  fullBookedDateDeparture = [];
  fullBookedDateReturn = [];
  departureLocation: any;
  departureDate: any;
  returnDate: any;
  newReturnDate: string;
  newDepartureDate: string;
  newReturnTime = 'default';
  developer = false;
  newDepartureTime = 'default';
  departureTime = [];
  returnTime = [];
  guests: any;
  guest = 0;
  returnMin = new Date();
  minDeparture = new Date();
  maxDeparture = new Date();
  minReturn = new Date();
  maxReturn = new Date();
  homeText = {
    returnTripText: ''
  }
  maxGuest: any;
  myFilterDeparture: any;
  myFilterReturn: any;
  tours = [];
  price: number;
  totalPrice: number;
  priceAdults: number;
  priceSeniorJunior: number;
  priceChildren: number;
  payment: boolean;
  available: number;
  departurePriceSelected: number;
  returnPriceSelected: number;
  return = true;
  departure = true;
  confirm: boolean;
  userProfile: boolean;
  conditions: any;
  name: string;
  email: string;
  tlf: number;
  comments: string;
  placeholderTlf: string;
  placeholderName: string;
  placeholderEmail: string;
  placeholderComments: string;
  countNext: number;
  departureText: string;
  pricesText: string;
  cancelTripText: string;
  notEnoughLeft: string;
  leftText: string;
  returnText: string;
  checkAvailable: string;
  select: string;
  selected: string;
  termText: string;
  termTextLink: string;
  confirmText: string;
  backText: string;
  nextText: string;
  customerInfoText: string;
  orderText: string;
  cancelText: string;
  sameRouteText: string;
  currentOlderDate: boolean;
  currentTime: Date;
  departureTimeSelected: any;
  loading: boolean;
  returnLoading: boolean;
  orderClicked: boolean;
  addOneHoure: any;
  cancelTourDate: string;
  countryCode = 'false';
  land: string;
  hiking: boolean;
  hikingInfo: string;
  invoiceEmail: boolean;
  addReturnDate: boolean;
  extraTrip: boolean;

  constructor(
    public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public bookingService: BookingService,
    public emailService: EmailService,
    public paymentService: PaymentService,
    public cancelService: CancelService,
    public infoService: InfoService,
    public dialog: MatDialog) {
    this.extraTrip = data[0].extraTrip;
    this.hiking = data[0].hiking;
    this.minReturn = data[0].seasonStart;
    this.maxReturn = data[0].seasonEnd;
    this.minDeparture = data[0].seasonStart;
    this.maxDeparture = data[0].seasonEnd;
    this.orderClicked = false;
    this.loading = false;
    this.returnLoading = false;
    if (sessionStorage.getItem('language') === 'FO' || sessionStorage.getItem('language') === null) {
      this.departureText = this.hiking ? searchText.hiking.fo : searchText.from.fo;
      this.homeText.returnTripText = homeText.returnTripText.fo;
      this.hikingInfo = searchText.hikingInfo.fo;
      this.pricesText = searchText.prices.fo;
      this.leftText = searchText.left.fo;
      this.returnText = searchText.to.fo;
      this.checkAvailable = searchText.checkAvailable.fo;
      this.select = searchText.select.fo;
      this.selected = searchText.selected.fo;
      this.termText = searchText.terms.fo;
      this.termTextLink = searchText.termsLink.fo;
      this.confirmText = searchText.confirm.fo;
      this.backText = searchText.back.fo;
      this.nextText = searchText.next.fo;
      this.customerInfoText = searchText.customerInfo.fo;
      this.orderText = searchText.order.fo;
      this.cancelText = searchText.cancel.fo;
      this.placeholderName = searchText.name.fo;
      this.placeholderEmail = searchText.email.fo;
      this.placeholderTlf = searchText.tlf.fo;
      this.placeholderComments = searchText.comments.fo;
      this.sameRouteText = searchText.sameRoute.fo;
      this.cancelTripText = searchText.cancelTrip.fo;
      this.land = searchText.land.fo;
      this.notEnoughLeft = searchText.notEnoughLeft.fo;
    } else {
      this.homeText.returnTripText = homeText.returnTripText.en;
      this.departureText = this.hiking ? searchText.hiking.en : searchText.from.en;
      this.hikingInfo = searchText.hikingInfo.en;
      this.pricesText = searchText.prices.en;
      this.leftText = searchText.left.en;
      this.notEnoughLeft = searchText.notEnoughLeft.en;
      this.returnText = searchText.to.en;
      this.checkAvailable = searchText.checkAvailable.en;
      this.select = searchText.select.en;
      this.selected = searchText.selected.en;
      this.termText = searchText.terms.en;
      this.termTextLink = searchText.termsLink.en;
      this.confirmText = searchText.confirm.en;
      this.backText = searchText.back.en;
      this.nextText = searchText.next.en;
      this.customerInfoText = searchText.customerInfo.en;
      this.orderText = searchText.order.en;
      this.cancelText = searchText.cancel.en;
      this.placeholderName = searchText.name.en;
      this.placeholderEmail = searchText.email.en;
      this.placeholderTlf = searchText.tlf.en;
      this.placeholderComments = searchText.comments.en;
      this.sameRouteText = searchText.sameRoute.en;
      this.cancelTripText = searchText.cancelTrip.en;
      this.land = searchText.land.en;
    }

    this.getTours();
  }
  getTours() {
    for (let i = 0; i < this.data.length; i++) {
      const depDate = new Date(this.data[i].date[0]);
      if (this.data[i].returnTrip) {
        if (this.data[i].returnTrip) {
          this.addReturnDate = true;
          const retDate = new Date(this.data[i].date[1]);

          this.returnDate = retDate.getFullYear() + '-' +
            ((retDate.getMonth() + 1) < 10 ? '0' : '') +
            (retDate.getMonth() + 1) + '-' +
            (retDate.getDate() < 10 ? '0' : '') +
            retDate.getDate();
          ;
          this.newReturnDate = this.returnDate;
        }
      }
      // this.tour = data[i].tour;
      // this.departureLocation = data[i].departure;
      this.departureDate = depDate.getFullYear() + '-' +
        ((depDate.getMonth() + 1) < 10 ? '0' : '') +
        (depDate.getMonth() + 1) + '-' +
        (depDate.getDate() < 10 ? '0' : '') +
        depDate.getDate();
      this.newDepartureDate = this.departureDate;
      this.guests = this.data[i].adults + this.data[i].juniorSenior + this.data[i].children;
      // this.departureTime = data[i].time;
      const d = new Date();
      d.setUTCHours(0, 0, 0, 0);
      depDate.setUTCHours(0, 0, 0, 0);

      if (depDate <= d) {
        this.currentOlderDate = true;
      }
    }
    this.bookingService.getTours().then((infoTour) => {
      const infoTourObj = JSON.parse(infoTour[0].tours);
      infoTourObj.sort(this.compareSeasonDate);
      this.cancelService.getCancelTours().then((cancelInfo) => {
        cancelInfo = cancelInfo;
        cancelInfo.forEach(element => {

          if (infoTourObj[element.tour].tour === this.data[0].tour || infoTourObj[element.tour].touren === this.data[0].tour) {
            if (this.departureDate >= element.fromDate &&
              this.departureDate <= element.toDate &&
              (JSON.stringify(infoTourObj[element.tour].departuretime).includes(element.time)
                || element.time === 'Allir túrar')) {
              this.cancelTourDate = element.time;
            }

          }
        });


      }).catch(() => {

      });
      infoTourObj.forEach(element => {

        if (element.tour === this.data[0].tour || element.touren === this.data[0].tour) {
          this.tours.push(element);
          this.maxGuest = this.developer ? 10 : this.data[0].seats;
          this.payment = JSON.parse(element.payment);
          this.priceAdults = JSON.parse(element.priceAdult);
          this.priceSeniorJunior = JSON.parse(element.priceSeniorJunior);
          this.priceChildren = JSON.parse(element.priceChildren);
          this.price = (this.priceAdults * this.data[0].adults) +
            (this.priceSeniorJunior * this.data[0].juniorSenior) +
            (this.priceChildren * this.data[0].children);
        }
      });

      let countLoop = 0;
      const loop = [];
      const date = new Date(this.departureDate);
      if (this.hiking) {
        this.next();
      } else {

        this.departureTime = [];
        for (let s = 0; s < Object.keys(this.tours[0].departuretime).length; s++) {
          countLoop++;
          if (this.tours[0].departuretime[s] !== null) {

            if (this.tours[0].departure === this.data[0].return) {
              this.departureTime.push({
                time: this.tours[0].returntime[s], guests: this.maxGuest
              });
              this.bookingService.searchBooking({ year: date.getFullYear() }).then((bookingInfo) => {

                const bookingInfoObj = bookingInfo;
                const promise = new Promise<any>((resolve, reject) => {
                  for (let i = 0; i < bookingInfoObj.length; i++) {

                    if ((this.departureDate === bookingInfoObj[i].returnDate) &&
                      (this.departureTime[s].time === bookingInfoObj[i].returnTime) && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                      const count = JSON.parse(bookingInfoObj[i].adults) +
                        JSON.parse(bookingInfoObj[i].juniorSenior) +
                        JSON.parse(bookingInfoObj[i].children);
                      this.departureTime[s].guests -= count;
                    }

                    if ((this.departureDate === bookingInfoObj[i].departureDate) &&
                      (this.departureTime[s].time === bookingInfoObj[i].departureTime) && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                      const count = JSON.parse(bookingInfoObj[i].adults) +
                        JSON.parse(bookingInfoObj[i].juniorSenior) +
                        JSON.parse(bookingInfoObj[i].children);
                      this.departureTime[s].guests -= count;
                    }
                    if (i === bookingInfoObj.length - 1) {
                      resolve(s);
                    }
                  }

                });
                Promise.all([promise]).then((values) => {
                  loop.push(values);
                  if (loop.length >= countLoop - 1) {
                    this.loading = true;
                    this.getReturn(this.data);
                  }
                });
              }).catch(() => {

              });
            } else {
              this.departureTime.push({
                time: this.tours[0].departuretime[s], guests: this.maxGuest
              });
              this.bookingService.searchBooking({ year: date.getFullYear() }).then((bookingInfo) => {
                const bookingInfoObj = bookingInfo;
                const promise = new Promise<any>((resolve, reject) => {
                  if (bookingInfo) {
                    for (let i = 0; i < bookingInfoObj.length; i++) {

                      if (this.departureDate === bookingInfoObj[i].returnDate &&
                        this.departureTime[s].time === bookingInfoObj[i].returnTime && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                        const count = JSON.parse(bookingInfoObj[i].adults) +
                          JSON.parse(bookingInfoObj[i].juniorSenior) +
                          JSON.parse(bookingInfoObj[i].children);
                        this.departureTime[s].guests -= count;
                      }

                      if ((this.departureDate === bookingInfoObj[i].departureDate) &&
                        (this.departureTime[s].time === bookingInfoObj[i].departureTime) && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                        const count = JSON.parse(bookingInfoObj[i].adults) +
                          JSON.parse(bookingInfoObj[i].juniorSenior) +
                          JSON.parse(bookingInfoObj[i].children);
                        this.departureTime[s].guests -= count;

                      }
                      if (i === bookingInfoObj.length - 1) {
                        resolve(s);
                      }
                    }
                  } else {
                    resolve(s);
                  }
                });

                Promise.all([promise]).then((values) => {
                  loop.push(values);
                  if (loop.length > countLoop - 1) {
                    this.loading = true;
                    this.getReturn(this.data);
                  }
                });

                if (this.data[0].returnTrip === false && this.departureTime.length === 1 || this.hiking) {
                  this.selectedPrice(0, 'departure');
                }
              }).catch(() => {

              });
            }
          }
        }
      }

    }).catch(() => {

    });
  }
  setReturnDate() {
    this.returnPriceSelected = undefined;
    this.departurePriceSelected = undefined;
    this.addOneHoure = 0;
    if (this.newReturnDate) {
    } else {
      this.newReturnDate = this.newDepartureDate
    }
  }
  setMaxMinDates(direction?) {
    if (direction && new Date(this.newDepartureDate) > new Date(this.returnDate)) {
      this.newReturnDate = this.newDepartureDate;
    }
    this.minReturn = this.newDepartureDate ? new Date(this.newDepartureDate) : this.departureDate;
  }

  checkAvailability(direction?) {
    this.data[0].date[0] = new Date(this.newDepartureDate);
    this.data[0].date[1] = new Date(this.newReturnDate);
    this.getTours()
  }


  soldOutCalender(direction) {
    var seat = this.data[0].seats;
    var departureLocation = this.data[0].departure;
    var returnLocation = this.data[0].return;
    var allDepartureDate = [];
    var allReturnDate = [];
    this.bookingService.getBooking({ future: 'true', notUserData: 'true' }).then((bookedDate) => {
      bookedDate.forEach((value) => {
        if (direction === 'departure') {

          if (this.data[0].tour === value.tour &&
            departureLocation === value.departureLocation &&
            this.newDepartureTime === value.departureTime) {

            if (new Date(value.departureDate) > new Date()) {
              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
              if (index === -1) {
                allDepartureDate.push({ departureDate: value.departureDate, countPeople });
              } else {
                allDepartureDate[index].countPeople = allDepartureDate[index].countPeople + countPeople;
              }
            }
          }
          if (this.data[0].tour === value.tour &&
            departureLocation === value.returnLocation &&
            this.newDepartureTime === value.returnTime) {

            if (new Date(value.departureDate) > new Date()) {
              const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
              const index = allDepartureDate.findIndex(x => x.departureDate === value.departureDate);
              if (index === -1) {
                allDepartureDate.push({ departureDate: value.returnDate, countPeople });
              } else {
                allDepartureDate[index].countPeople = allDepartureDate[index].countPeople + countPeople;
              }
            }


          }
        } else {
          if (this.data[0].tour === value.tour && returnLocation === value.returnLocation && this.newReturnTime === value.returnTime) {

            const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
            const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
            if (index === -1) {
              allReturnDate.push({ returnDate: value.returnDate, countPeople });
            } else {
              allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
            }
          }
          if (this.data[0].tour === value.tour && returnLocation === value.departureLocation && this.newReturnTime === value.departureTime) {

            const countPeople = (JSON.parse(value.adults) + JSON.parse(value.juniorSenior) + JSON.parse(value.children))
            const index = allReturnDate.findIndex(x => x.returnDate === value.returnDate);
            if (index === -1) {
              allReturnDate.push({ returnDate: value.departureDate, countPeople });
            } else {
              allReturnDate[index].countPeople = allReturnDate[index].countPeople + countPeople;
            }
          }
        }
      })

    }).then(() => {

      if (direction === 'departure') {
        allDepartureDate.forEach((x) => {
          if (x.countPeople >= seat) {
            var stringCount = new Date(x.departureDate);
            stringCount.setHours(0, 0, 0, 0);
            this.fullBookedDateDeparture.push(stringCount.toString())
          }
        });
      } else {
        allReturnDate.forEach((x) => {
          if (x.countPeople >= seat) {
            const stringCount = new Date(x.returnDate);
            stringCount.setHours(0, 0, 0, 0);
            this.fullBookedDateReturn.push(stringCount.toString())
          }
        });
      }

      if (direction === 'departure') {
        var deprtureDateSet = false;
        this.myFilterDeparture = (d: Date): boolean => {
          d.setHours(0, 0, 0, 0);
          if (this.fullBookedDateDeparture.includes(d.toString())) {
            return false;
          } else {
            const setDate = d.getFullYear() + '-' +
              ((d.getMonth() + 1) < 10 ? '0' : '') +
              (d.getMonth() + 1) + '-' +
              (d.getDate() < 10 ? '0' : '') +
              d.getDate();
            if (new Date(setDate) >= new Date(this.newDepartureDate)) {
              setTimeout(() => {
                deprtureDateSet ? '' : this.newDepartureDate = setDate;
                deprtureDateSet = true;
              }, 100)
            }
            return true;
          }

        }
      } else {
        var returnDateSet = false;
        this.myFilterReturn = (d: Date): boolean => {
          d.setHours(0, 0, 0, 0);
          if (this.fullBookedDateReturn.includes(d.toString())) {
            return false;
          } else {
            const setDate = d.getFullYear() + '-' +
              ((d.getMonth() + 1) < 10 ? '0' : '') +
              (d.getMonth() + 1) + '-' +
              (d.getDate() < 10 ? '0' : '') +
              d.getDate();
            if (new Date(setDate) >= new Date(this.newReturnDate)) {
              setTimeout(() => {
                returnDateSet ? '' : this.newReturnDate = setDate;
                returnDateSet = true;
              }, 100)
            }
            return true;
          }

        }
      }
    })
  }
  country(value) {
    this.countryCode = value;
  }

  getReturn(data) {
    const loop = [];
    let countLoop = 0;
    const date = new Date(this.departureDate);
    this.returnTime = [];
    for (let s = 0; s < Object.keys(this.tours[0].returntime).length; s++) {
      countLoop++;
      if (this.tours[0].returntime[s] !== null) {
        if (this.tours[0].departure === data[0].return) {

          this.returnTime.push({
            time: this.tours[0].departuretime[s], guests: this.maxGuest
          });

          this.bookingService.searchBooking({ year: date.getFullYear() }).then((bookingInfo) => {
            const bookingInfoObj = bookingInfo;
            const promise = new Promise<any>((resolve, reject) => {
              for (let i = 0; i < bookingInfoObj.length; i++) {
                if (this.returnDate === bookingInfoObj[i].departureDate &&
                  this.returnTime[s].time === bookingInfoObj[i].departureTime && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                  const count = JSON.parse(bookingInfoObj[i].adults) +
                    JSON.parse(bookingInfoObj[i].juniorSenior) +
                    JSON.parse(bookingInfoObj[i].children);
                  this.returnTime[s].guests -= count;
                }

                if (this.returnDate === bookingInfoObj[i].returnDate &&
                  this.returnTime[s].time === bookingInfoObj[i].returnTime && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                  const count = JSON.parse(bookingInfoObj[i].adults) +
                    JSON.parse(bookingInfoObj[i].juniorSenior) +
                    JSON.parse(bookingInfoObj[i].children);
                  this.returnTime[s].guests -= count;
                }
                if (i === bookingInfoObj.length - 1) {
                  resolve(s);
                }
              }
            });

            Promise.all([promise]).then((values) => {
              loop.push(values);
              if (loop.length >= countLoop - 1) {
                this.returnLoading = true;
              }
            });
          }).catch(() => {

          });
        } else {
          this.returnTime.push({
            time: this.tours[0].returntime[s], guests: this.maxGuest
          });

          this.bookingService.searchBooking({ year: date.getFullYear() }).then((bookingInfo) => {
            const bookingInfoObj = bookingInfo;
            const promise = new Promise<any>((resolve, reject) => {
              if (bookingInfo) {
                for (let i = 0; i < bookingInfoObj.length; i++) {
                  if (this.returnDate === bookingInfoObj[i].departureDate &&
                    this.returnTime[s].time === bookingInfoObj[i].departureTime && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                    const count = JSON.parse(bookingInfoObj[i].adults) +
                      JSON.parse(bookingInfoObj[i].juniorSenior) +
                      JSON.parse(bookingInfoObj[i].children);
                    this.returnTime[s].guests -= count;
                  }

                  if (this.returnDate === bookingInfoObj[i].returnDate &&
                    this.returnTime[s].time === bookingInfoObj[i].returnTime && (bookingInfoObj[i].payment !== 'Endurgoldið')) {
                    const count = JSON.parse(bookingInfoObj[i].adults) +
                      JSON.parse(bookingInfoObj[i].juniorSenior) +
                      JSON.parse(bookingInfoObj[i].children);
                    this.returnTime[s].guests -= count;
                  }
                  if (i === bookingInfoObj.length - 1) {
                    resolve(s);
                  }
                }
              } else {
                resolve(s);
              }
            });

            Promise.all([promise]).then((values) => {
              loop.push(values);
              if (loop.length >= countLoop - 1) {
                this.returnLoading = true;
              }
            });
          }).catch(() => {

          });
        }

      }
    }
  }

  compareSeasonDate(a, b) {
    if (a.seasonStart > b.seasonStart) {
      return 1;
    }
    if (a.seasonStart < b.seasonStart) {
      return -1;
    }
    return 0;
  }
  closeDialog(): void {
    this.dialogRef.close();
    this.departurePriceSelected = undefined;
    this.returnPriceSelected = undefined;
    this.userProfile = undefined;
  }

  selectedPrice(i, sel, time?) {
    if (sel === 'departure') {
      this.departurePriceSelected = i;
      if (time) {
        this.returnPriceSelected = undefined;
        this.departureTimeSelected = time;
        this.addOneHoure = JSON.parse(this.departureTimeSelected.substring(0, 2)) + 1;
        this.addOneHoure = this.departureTimeSelected.replace(/^.{2}/g, this.addOneHoure);
      }
      if (this.data[0].returnTrip === false && this.departureTime.length === 1) {
        this.next();
      }
    } else {
      this.returnPriceSelected = i;
    }

  }

  next() {

    let warning: string;
    if (this.data[0].returnTrip && !this.hiking) {
      if (this.returnPriceSelected === undefined) {

        if (sessionStorage.getItem('language') === 'FO' || !sessionStorage.getItem('language')) {
          warning = 'Vinarliga vel heimferðar tíð';
        } else {
          warning = 'Please select return time';
        }
      }
      if (this.departurePriceSelected === undefined && !this.hiking) {
        if (sessionStorage.getItem('language') === 'FO' || !sessionStorage.getItem('language')) {
          warning = 'Vinarliga vel fráferðar tíð';
        } else {
          warning = 'Please select departure time';
        }
      }
    } else {
      if (this.departurePriceSelected === undefined && !this.hiking) {
        if (sessionStorage.getItem('language') === 'FO' || !sessionStorage.getItem('language')) {
          warning = 'Vinarliga vel fráferðar tíð';
        } else {
          warning = 'Please select departure time';
        }
      }
    }
    if (warning) {
      alert(warning);
    } else {

      if (this.data[0].returnTrip) {
        if (!isNaN(this.departurePriceSelected)) {
          this.departure = true;
          this.departureHide = false;
          this.return = true;
        }
      } else {
        this.totalPrice = this.price;
        this.confirm = true;
      }

      if (!isNaN(this.departurePriceSelected) && !isNaN(this.returnPriceSelected)) {
        this.totalPrice = this.price + this.price;
        this.confirm = true;
      }
    }
  }

  back(value?) {
    this.countNext = undefined;
    this.confirm = false;
    this.departurePriceSelected = undefined;
    this.returnPriceSelected = undefined;
    this.departure = true;
    this.departureHide = true;
    this.return = true;
  }

  setCondition() {
    this.conditions = this.conditions ? false : true;
  }

  condition() {
    this.infoService.getCondition().then((value) => {
      const valueObj = value;
      let condition: string;
      if (sessionStorage.getItem('language') === 'FO' || sessionStorage.getItem('language') === null) {
        condition = valueObj[0].conditionFo;
      } else {
        condition = valueObj[0].conditionEn;
      }
      this.dialog.open(ConditionComponent, {
        width: '50vw',
        height: '50vh',
        data: { text: condition }
      });
    }).catch(() => {

    });
  }

  confirmBtn(condition) {
    if (this.hiking) {
      if (condition) {
        this.departureHide = false;
        this.departure = false;
        this.return = false;
        this.confirm = false;
        this.userProfile = true;
      } else {
        if (sessionStorage.getItem('language') === 'FO' || !sessionStorage.getItem('language')) {
          alert('Vinarliga góðtak treytirnar');
        } else {
          alert('You must agree terms');
        }
      }
    } else {


      if (this.departureTime[this.departurePriceSelected].guests - this.guests >= 0) {
        if (condition) {
          this.departureHide = false;
          this.departure = false;
          this.return = false;
          this.confirm = false;
          this.userProfile = true;
        } else {
          if (sessionStorage.getItem('language') === 'FO' || !sessionStorage.getItem('language')) {
            alert('Vinarliga góðtak treytirnar');
          } else {
            alert('You must agree terms');
          }
        }
      } else {
        alert(this.notEnoughLeft);
      }
    }
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  order() {
    this.orderClicked = true;
    let error: string;
    this.comments = this.comments ? this.comments.replace(/'/g, '') : this.comments;
    if (!this.validateEmail(this.email)) {
      this.orderClicked = true;
      error = 'Please provide a valid email';
    }
    if (isNaN(this.tlf) || JSON.stringify(this.tlf).length < 8) {
      error = 'Please provide a valid number of mininum 6 character';
    }
    if (this.name.indexOf('\'')) {
      this.name.replace(/'/g, '');
    }
    if (this.name.length < 4) {
      error = 'Please provide a name more than 3 character';
    }
    if (JSON.stringify(this.departureDate).length !== 12) {
      error = 'Error in booking!! \n You have not provided a date, please retry your booking';
    }
    if (this.countryCode === 'false') {
      error = 'Please choose country code';
    }
    if (this.comments) {
      if (this.comments.includes('&')) {
        error = 'It is not possible to use the "&" character';
      }
    }
    if (this.name.includes('&')) {
      error = 'It is not possible to use the "&" character';
    }
    if (error) {

      this.orderClicked = false;
      alert(error);
    } else {
      this.emailService.getEmail().then((value) => {

        if (value.filter((e) => e.email.toUpperCase() === this.email.toUpperCase()).length > 0) {




          if (this.hiking) {
            const hikingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              date: this.departureDate,
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice,
              payment: 'Faktura',
              paymentid: Math.floor(Math.random() * 90000) + 10000,
            };
            console.log(hikingInfo);
            this.paymentService.logPayment(hikingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            this.bookingService.registerHiking(hikingInfo).then((data) => {
              this.emailService.sendHikingEmail(hikingInfo).then((info) => {
                alert('Atgongumerki er sent til tín teldupost. ' +
                  'Ein rokning verður send seinni. \n\n Gev gætur!\n ' +
                  'Kanna Spam/Junk postin. Atgongumerki kann vera endaður har.');
                this.closeDialog();
              }).catch(() => {

              });
            }).catch((err) => {
              console.log(err)
              alert(JSON.stringify(err));
            });
          } else {
            const bookingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              phone: '+' + this.countryCode + this.tlf,
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              departureDate: this.departureDate,
              departureTime: this.departureTime[this.departurePriceSelected].time,
              departureLocation: this.data[0].departure,
              returnDate: this.data[0].returnTrip ? this.returnDate : '',
              returnTime: this.data[0].returnTrip ? this.returnTime[this.returnPriceSelected].time : '',
              returnLocation: this.data[0].returnTrip ? this.data[0].return : '',
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice,
              payment: 'Faktura',
              paymentid: Math.floor(Math.random() * 90000) + 10000,
              hiking: this.hiking
            };
            this.paymentService.logPayment(bookingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            this.bookingService.registerBooking(bookingInfo).then((data) => {
              this.emailService.sendEmailEn(bookingInfo).then((info) => {
                alert('Ferðaseðilin er sendur til tín teldupost. ' +
                  'Ein rokning verður send seinni. \n\n Gev gætur!\n ' +
                  'Kanna Spam/Junk postin. Ferðaseðilin kann vera endaður har.');
                this.closeDialog();
              }).catch(() => {

              });
            }).catch((err) => {
              alert(err);
            });
          }
        } else if (this.payment === false) {
          if (this.hiking) {
            const hikingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              date: this.departureDate,
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice
            };
            this.paymentService.logPayment(hikingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            if (hikingInfo.amount > 0) {
              if (this.hiking) {
                this.paymentService.getHikingPayment(hikingInfo).then((data) => {
                  window.location.href = data;
                }).catch((err) => {
                  alert(err);
                });
              }
            } else {
              alert('Something went wrong and your order could not be completed. Please try again.');
            }

          } else {
            const bookingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              phone: '+' + this.countryCode + this.tlf,
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              departureDate: this.departureDate,
              departureTime: this.departureTime[this.departurePriceSelected].time,
              departureLocation: this.data[0].departure,
              returnDate: this.data[0].returnTrip ? this.returnDate : '',
              returnTime: this.data[0].returnTrip ? this.returnTime[this.returnPriceSelected].time : '',
              returnLocation: this.data[0].returnTrip ? this.data[0].return : '',
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice,
            };
            this.paymentService.logPayment(bookingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            if (bookingInfo.amount > 0) {
              this.paymentService.getPayment(bookingInfo).then((data) => {
                window.location.href = data;
              }).catch((err) => {
                alert(err);
              });

            } else {
              alert('Something went wrong and your order could not be completed. Please try again.');
            }
          }
        } else {
          if (this.hiking) {
            const hikingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              date: this.departureDate,
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice,
              payment: 'Kontant',
              paymentInfo: 'Cash payment on departure',
              paymentid: Math.floor(Math.random() * 90000) + 10000,
            };
            this.paymentService.logPayment(hikingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            if (hikingInfo.amount > 0) {
              this.bookingService.registerHiking(hikingInfo).then((data) => {
                this.emailService.sendHikingEmail(hikingInfo).then((info) => {
                  alert('Atgongumerki er sent til tín teldupost. Gjaldast skal vera kontant við fráferð' +
                    '\n\n Gev gætur!\n ' +
                    'Kanna Spam/Junk postin. Atgongumerki kann vera enda har.\n\n' +
                    'The ticket has been sent to your email. Cash payment on departure' +
                    ' \n\n Please be aware!\n ' +
                    'Your ticket may have been placed in your spam/junk mail');
                  this.closeDialog();
                }).catch(() => {

                });
              }).catch((err) => {
                alert(err);
              });
            } else {
              alert('Something went wrong and your order could not be completed. Please try again.');
            }
          } else {
            const bookingInfo = {
              tour: this.data[0].tour,
              name: this.name.replace(/[^a-zA-Z ]/g, ""),
              phone: '+' + this.countryCode + this.tlf,
              email: this.email,
              comments: this.comments ? this.comments.replace(/[^a-zA-Z ]/g, ""): '',
              departureDate: this.departureDate,
              departureTime: this.departureTime[this.departurePriceSelected].time,
              departureLocation: this.data[0].departure,
              returnDate: this.data[0].returnTrip ? this.returnDate : '',
              returnTime: this.data[0].returnTrip ? this.returnTime[this.returnPriceSelected].time : '',
              returnLocation: this.data[0].returnTrip ? this.data[0].return : '',
              adults: this.data[0].adults,
              priceAdult: this.priceAdults,
              juniorSenior: this.data[0].juniorSenior,
              priceJuniorSenior: this.priceSeniorJunior,
              children: this.data[0].children,
              priceChildren: this.priceChildren,
              amount: this.totalPrice,
              payment: 'Kontant',
              paymentInfo: 'Cash payment on departure',
              paymentid: Math.floor(Math.random() * 90000) + 10000,
            };
            this.paymentService.logPayment(bookingInfo).then((response) => {
              console.log(response)
            }).catch((error) => {
              console.log(error)
            });
            if (bookingInfo.amount > 0) {

              this.bookingService.registerBooking(bookingInfo).then((data) => {
                this.emailService.sendEmailEn(bookingInfo).then((info) => {
                  alert('Ferðaseðilin er sendur til tín teldupost. Gjaldast skal kontant við fráferð' +
                    '\n\n Gev gætur!\n ' +
                    'Kanna Spam/Junk postin. Ferðaseðilin kann vera endaður har.\n\n' +
                    'The ticket has been sent to your email. Cash payment on departure' +
                    ' \n\n Please be aware!\n ' +
                    'Your ticket may have been placed in your spam/junk mail');
                  this.closeDialog();
                }).catch(() => {

                });
              }).catch((err) => {
                alert(err);
              });

            }

          }
        }
      });
    }
  }

}

@Component({
  selector: 'app-condition',
  templateUrl: '../condition/condition.html',
})
export class ConditionComponent {

  constructor(
    public dialogRef: MatDialogRef<ConditionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
