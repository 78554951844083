<div class="mask" tappable (click)="mask()">
</div>
<div class="order" tappable>
  <div class="search">
      <span class="tour">
        <div *ngIf="!accountType.hiking && !accountType.trip">
          <fa-icon [icon]="faHandPointer"></fa-icon>
        </div>
        <div *ngIf="accountType.hiking  || accountType.trip">
          <fa-icon [icon]="accountType.trip?faShip:faHiking"></fa-icon>
        </div>
        <div class="searchFields">
          <input
            tappable
            [ngClass]="{'activeSearch': activeSearch === 0}"
            (click)="selectTour('-1', 0)"
            [value]="searchFields[0].tour? searchFields[0].tour : searchFields[0].lan[0]"
            class="default"
            type="button"
          />
        </div>
        <div [ngClass]="{'dropDown': !tripDropdown}" class="option">
          <div
            tappable
            (click)="selectTour(i, trip)"
            *ngFor="let trip of trips; let i = index;"
          >
            <fa-icon
              [icon]="tripsAccountType[i].trip?faShip:faHiking"
            ></fa-icon>
            <p>{{trip}}</p>
          </div>
        </div>
      </span>
      <span class="location">
        <fa-icon [icon]="faMapMarker"></fa-icon>
        <div class="searchFields">
          <input
            tappable
            [disabled]="(disabled) || (disableFrom)"
            [placeholder]="searchFields[0].lan[1]"
            [ngClass]="{'disabled': disabled, 'activeSearch': activeSearch === 1}"
            (click)="selectFrom('-1', 1)"
            [value]="searchFields[0].departure? searchFields[0].departure : searchFields[0].lan[1]"
            class="default default1"
            type="button"
          />
        </div>
        <div
          [ngClass]="{'dropDown': !locationDropdown}"
          class="option option1"
        >
          <div
            tappable
            (click)="selectFrom(i, from)"
            *ngFor="let from of froms; let i = index;"
          >
            {{from}}
          </div>
        </div>
      </span>
      <span class="owl-date-time">
        <fa-icon [icon]="faCalendarAlt"></fa-icon>
        <div class="searchFields">
          <input
            tappable
            [ngClass]="{'activeSearch': activeSearch === 2}"
            [disabled]="(disabled) || (disableFrom)"
            [ngClass]="{'disabled': disabled}"
            (click)="selectDeparture('-1', 2)"
            [value]="searchFields[0].lan[2]"
            class="default"
            type="button"
          />
          <div [ngClass]="{'dropDown': !dateDropdown}" class="optionDate">
            <span *ngIf="froms[1]"
              >{{homeText.returnTripText}}
              <input
                class="checkbox"
                type="checkbox"
                [(ngModel)]="returnTrip"
              />
            </span>
            <div class="wrapDate">
              {{accountType.hiking ? searchFields[0].lan[2] :
              searchFields[0].lan[6]}}
              <input
              id="departure-input"
                class="dateInput"
                readonly
                (ngModelChange)="selectDeparture($event, 'departure')"
                (click)="soldOutCalender('departure')"
                [disabled]="disabled"
                [min]="min"
                [max]="max"
                [(ngModel)]="departureDate"
                [owlDateTimeTrigger]="dt2"
                [owlDateTime]="dt2"
                [owlDateTimeFilter]="myFilterDeparture != undefined ? myFilterDeparture : myFilter"
                [ngClass]="{'disabled': disabled}"
                type="button"
                #departureInput
              />
              <fa-icon
                class="calenderImg"
                tappable
                (click)="openCalender('departure')"
                [icon]="faCalendarAlt"
              ></fa-icon>

              <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            </div>
            <div class="wrapDate" *ngIf="returnTrip && !accountType.hiking">
              {{searchFields[0].lan[7]}}
              <input
              id="return-input"
                class="dateInput"
                readonly
                (ngModelChange)="selectDeparture($event, 'return')"
                (click)="soldOutCalender('return')"
                [disabled]="(disabled) || (disableFrom)"
                [min]="returnMin"
                [max]="max"
                [(ngModel)]="returnDate"
                [owlDateTimeTrigger]="dt1"
                [owlDateTime]="dt1"
                [owlDateTimeFilter]="myFilterReturn != undefined ? myFilterReturn : myFilter"
                [ngClass]="{'disabled': disabled}"
                type="button"
                #returnInput
              />
              <fa-icon
                class="calenderImg"
                tappable
                (click)="openCalender('return')"
                [icon]="faCalendarAlt"
              ></fa-icon>
              <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            </div>
          </div>
        </div>
      </span>

      <span class="guests">
        <fa-icon [icon]="faUsers"></fa-icon>
        <div class="searchFields">
          <input
            tappable
            [ngClass]="{'activeSearch': activeSearch === 3}"
            [disabled]="disabled"
            [ngClass]="{'disabled': disabled}"
            (click)="selectGuests('-1', 3)"
            [value]="searchFields[0].lan[4]"
            class="default default1"
            type="button"
          />
        </div>
        <div [ngClass]="{'dropDown': !guestsDropdown}" class="option3">
          <div class="chooseGuest">
            <div class="wrap-add-guests">
              <div class="guestText">{{homeText.adults}}</div>
              <div class="age">16-66</div>
              <div class="add-guests">
                <fa-icon
                  tappable
                  (click)="adults != 0 ? addGuests(-1, 0): 0"
                  [icon]="faMinusCircle"
                ></fa-icon>
                <input
                  class="guestInput"
                  type="number"
                  [(ngModel)]="adults"
                />
                <fa-icon
                  tappable
                  (click)="addGuests(1, 0)"
                  [icon]="faPlusCircle"
                ></fa-icon>
              </div>
            </div>
            <div class="wrap-add-guests">
              <div class="guestText">{{homeText.juniorSeniorText}}</div>
              <div class="age">7-15 / 67+</div>
              <div class="add-guests">
                <fa-icon
                  tappable
                  (click)="juniorSenior != 0 ? addGuests(-1, 1): 0"
                  [icon]="faMinusCircle"
                ></fa-icon>

                <input
                  class="guestInput"
                  type="number"
                  [(ngModel)]="juniorSenior"
                />
                <fa-icon
                  tappable
                  (click)="addGuests(1, 1)"
                  [icon]="faPlusCircle"
                ></fa-icon>
              </div>
            </div>
            <div class="wrap-add-guests">
              <div class="guestText">{{homeText.childrenText}}</div>
              <div class="age">0-6</div>
              <div class="add-guests">
                <fa-icon
                  tappable
                  (click)="children != 0 ? addGuests(-1, 2): 0"
                  [icon]="faMinusCircle"
                ></fa-icon>
                <input
                  class="guestInput"
                  type="number"
                  [(ngModel)]="children"
                />
                <fa-icon
                  tappable
                  (click)="addGuests(1, 2)"
                  [icon]="faPlusCircle"
                ></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </span>

      <span>
        <img src="assets/icons/search.png" />
        <div class="searchFields">
          <input
            readonly
            tappable
            (click)="searchBooking()"
            [disabled]="disabled"
            class="default go"
            [value]="searchFields[0].lan[5]"
            type="button"
          />
        </div>
      </span>
    </div>

  <div>
    <div class="headline">
      <span [innerHTML]="data[1]"></span>
    </div>
    <div class="content">
      <span [innerHTML]="data[0]"></span>
    </div>
  </div>

  <div tappable class="cancel-btn" (click)="closeDialog()">{{cancelText}}</div>
</div>
