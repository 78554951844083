export const language = {
  language: navigator.language,
};

export const monthNames = {
  en: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  fo: ['JAN', 'FEB', 'MAR', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DES']
};

export const searchFields = {
  en: ['Choose', 'From', 'Date', 'Time', 'Guests', 'Order', 'Departure', 'Return'],
  fo: ['Vel', 'Frá', 'Dato', 'Tíð', 'Gestir', 'Bílegg', 'Fráferð', 'Heimferð']
};

export const message = {

  cancelMessage: [{
    en: 'is canceled due to weather',
    fo: 'er avlýstur vegna veður',
    enFleiri: 'canceled due to weather',
    foFleiri: 'eru avlýstir vegna veður'
  },
  {
    en: 'is canceled due to technical issues',
    fo: 'er avlýstur vegna tekniskum truppuleikum',
    enFleiri: 'are canceled due to technical issues',
    foFleiri: 'eru avlýstir vegna tekniskum truppuleikum'
  },
  {
    en: 'is canceled',
    fo: 'er avlýstur',
    enFleiri: 'is canceled',
    foFleiri: 'eru avlýstir'
  },
  {
    en: 'is canceled due to not enough participants',
    fo: 'er avlýstur vegna ov lítil undirtøka',
    enFleiri: 'canceled due to not enough participants',
    foFleiri: 'eru avlýstir vegna ov lítil undirtøka'
  }],
  normalMessage: { en: 'All departure today are as normal', fo: 'Vit sigla í dag sum vantligt' }
};

export const searchText = {
  notEnoughLeft: {en: 'Warning! Not enough seats available', fo: 'Ávaring! Mongding av plássum er ikki tøk'},
  checkAvailable: {en: 'Check Availability', fo: 'Leita'},
  select: {en: 'Select', fo: 'Vel'},
  selected: {en: 'Selected', fo: 'Valgt'},
  hikingInfo: { en: 'Hiking fee', fo: 'Gongugjald' },
  from: { en: 'Departure', fo: 'Fráferð' },
  hiking: { en: 'Ticket', fo: 'Atgongumerki' },
  to: { en: 'Return', fo: 'Heimferð' },
  prices: { en: 'Prices', fo: 'Prísir' },
  left: { en: 'Left', fo: 'Eftir' },
  terms: { en: 'I agree to the terms and conditions.', fo: 'Eg góðtaki treytirnar fyri at keypa.' },
  termsLink: { en: 'Read the terms and conditions here', fo: 'Les treytirnar fyri keypi her' },
  customerInfo: { en: 'Customer Information', fo: 'Kunda upplýsingar' },
  name: { en: 'Name', fo: 'Navn' },
  email: { en: 'Email', fo: 'Teldupost' },
  tlf: { en: 'Telephone no.', fo: 'Telefon nr.' },
  comments: { en: 'Comment', fo: 'Viðmerking' },
  confirm: { en: 'Confirm', fo: 'Vátta' },
  order: { en: 'Order', fo: 'Bílegg' },
  cancel: { en: 'Cancel', fo: 'Ógilda' },
  back: { en: 'Back', fo: 'Aftur' },
  next: { en: 'Next', fo: 'Næsta' },
  cancelTrip: { en: 'This trip is canceled', fo: 'Hesin túrurin er avlýstur' },
  sameRoute: { fo: 'ÁVARING - vitjanin er styttri enn ein tíma', en: 'WARNING - the stay is less than an hour' },
  land: { en: 'Country', fo: 'Land' }
};

export const orderText = {
  from: { en: 'Departure', fo: 'Fráferð' },
  to: { en: 'Return', fo: 'Heimferð' },
  prices: { en: 'Prices', fo: 'Prísir' },
  left: { en: 'Left', fo: 'Eftir' },
  terms: { en: 'I agree to the terms and conditions.', fo: 'Eg góðtaki treytirnar fyri at keypa.' },
  termsLink: { en: 'Read the terms and conditions here', fo: 'Les treytirnar fyri keypi her' },
  customerInfo: { en: 'Customer Information', fo: 'Kunda upplýsingar' },
  confirm: { en: 'Confirm', fo: 'Vátta' },
  order: { en: 'Order', fo: 'Bílegg' },
  cancel: { en: 'Cancel', fo: 'Ógilda' },
  back: { en: 'Back', fo: 'Aftur' },
  next: { en: 'Next', fo: 'Næsta' }
};

export const homeText = {
  returnTripText: { en: 'Return Trip', fo: 'Báðar vegir' },
  childrenText: { en: 'Children', fo: 'Små børn' },
  juniorSeniorText: { en: 'Junior / Senior', fo: 'Børn / pensjo..' },
  from: { en: 'From', fo: 'Frá' },
  adults: { en: 'Adults', fo: 'Vaksin' },
  orderHere: { en: 'Order', fo: 'Bílegg' },
  to: { en: 'To', fo: 'Til' },
  readmore: { en: 'Read more', fo: 'Les meira' },
  headline: { en: ['Latest News', 'Contact', 'Info'], fo: ['Seinastu Tíðindi', 'Samband', 'Upplýsingar'] },
  contact: {
    name: 'Sp/f Mykines',
    adr: 'Bryggjan 17',
    city: 'FO-380 Sørvágur',
    tel: '+298 200 388',
    vtal: '554375',
    email: 'info@mykines.fo'
  }
};
