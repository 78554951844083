import { AdsService } from '../../services/ads-service/ads-service'
import { BookingService } from '../../services/booking-service/booking-service'
import { CancelService } from '../../services/cancel-service/cancel-service'
import { Component } from '@angular/core'
import { EmailService } from '../../services/email-service/email-service'
import { HikingService } from 'src/services/hiking-service/hiking-service'
import { InfoService } from '../../services/info-service/info-service'
import { LoginModalComponent } from '../login/login'
import { MatDialog } from '@angular/material'
import { NewsService } from '../../services/news-service/new-service'
import { PageService } from '../../services/page-service/page-service'
import { PaymentService } from '../../services/payment-service/payment-service'
import { Router } from '@angular/router'
import { UploadImageService } from '../../services/uploadImage-service/uploadImage.service'
import { UserService } from '../../services/user-service/user-service'
import {
  faSignOutAlt,
  faShip,
  faRoute,
  faCalendar,
  faFile,
  faBan,
  faInfo,
  faFileInvoice,
  faFileSignature,
  faUserAlt,
  faBullhorn,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons'

// tslint:disable:no-unused-expression
@Component({
  selector: 'app-admin',
  templateUrl: './admin.html',
  styleUrls: ['./admin.scss'],
})
export class AdminComponent {
  ar = []
  hiking: boolean
  faSignOutAlt = faSignOutAlt
  paymentMethods = [
    { name: 'Card', value: 0 },
    { name: 'Cash', value: 1 },
    { name: 'Faktura', value: 2 },
  ]
  manadar = [
    { name: 'Allir', value: 100 },
    { name: 'Jan', value: 1 },
    { name: 'Feb', value: 2 },
    { name: 'Mar', value: 3 },
    { name: 'Apr', value: 4 },
    { name: 'Mai', value: 5 },
    { name: 'Jun', value: 6 },
    { name: 'Jul', value: 7 },
    { name: 'Aug', value: 8 },
    { name: 'Sep', value: 9 },
    { name: 'Okt', value: 10 },
    { name: 'Nov', value: 11 },
    { name: 'Des', value: 12 },
  ]
  tolTour = 'Mykines'
  choosenDay = new Date()
  choosenMonth = 'Allir'
  choosenYear = new Date().getFullYear()
  tolRadio = 'ar'
  login = true
  name = 'Maja'
  email = 'maja@mykines.fo'

  menus = [
    { name: 'Túrar / Gongugjald', icon: faShip },
    { name: 'Farleið', icon: faRoute },
    { name: 'Bókingar - Túrar', icon: faCalendar },
    { name: 'Bókingar - Gongugjald', icon: faCalendar },
    { name: 'Síðir', icon: faFile },
    { name: 'Avlýsing', icon: faBan },
    // { name: "Tíðindi", icon: faShip },
    { name: 'Upplýsingar', icon: faInfo },
    { name: 'Roknings móttakarar', icon: faFileInvoice },
		{ name: 'Avsláttur', icon: faFileInvoice },
    { name: 'Treytir', icon: faFileSignature },
    { name: 'Skráset brúkara', icon: faUserAlt },
    { name: 'Lýsingar', icon: faBullhorn },
    { name: 'Samla ferðafólka tøl', icon: faChartLine },
  ]
  activeMenu = sessionStorage.getItem('menu')
    ? sessionStorage.getItem('menu')
    : 'Túrar / Gongugjald'
  signout = 'Rita út'
  ferdafolk = []
  hikingPeople = []
  samlaSola: string
  tours = []
  routes = []
  ads = []
  trips = []
  dato: string
  leita: string
  uniqNr: number
  newTours = []
  seats = []
  blabla = 'test'
  days = ['Mán', 'Týs', 'Mik', 'Hós', 'Frí', 'Ley', 'Sun']
  selectedDay = [
    { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false },
  ]
  bookings = []
  hikings = []
  selected = true
  filterBookings = this.bookings

  filterHikings = this.hikings
  filterDateBookings = []
  menues = []
  content: any
  languageSelect = 'FO'
  selectedPage: any
  departureTime = []
  cancelDate = []
  cancelTime = ''
  canceledTrip = []
  reasonCancel = [
    { 0: 'Weather' },
    { 1: 'Technical issuess' },
    { 2: 'Other' },
    { 3: 'Not enough participants' },
  ]
  selectedReason = 0
  extraMessage: string
  startAt = new Date()
  min = new Date()
  max = new Date()
  myFilter: any
  newsDate = []
  newsHeadlineFo: string
  newsHeadlineEn: string
  newsTekstEn: string
  newsTekstFo: string
  allNews = []
  infoTekstEn: string
  infoHeadlineFo: string
  infoHeadlineEn: string
  infoTekstFo: string
  sliceEnd = 100
  allInfo = []
  pageInfo = []
  cancelInfo: any
  countEmail = { count: 0, id: -1 }
  countRefund = { count: 0, id: -1 }
  emails = []
	// Discount emails are emails from server (current state)
	discountEmails = []
	// Discount fields are updated states of the discount emails (not yet saved)
	discountFields = []
	// Whether discounts have changed
	discountsChanged = false
  conditionLan = 'FO'
  condition = {
    conditionFo: '',
    conditionEn: '',
  }
  loadBooking: boolean
  loadHiking: boolean

  randomNumber: string

  userProfile = {
    name: '',
    email: '',
    psw: '',
    pswrepeat: '',
  }
  bookingValue: string
  hikingValue: string
  users = []

  loading: number
  countTimeout = 0
  countLoop = 0
  selctedDate: string
  guestsLeftFirstTrip = []
  guestsLeftFirstTripTime: string
  guestsLeftSecondTrip = []
  guestsLeftSecondTripTime: string
  guestsLeftThirdTrip = []
  guestsLeftThirdTripTime: string
  guestsLeftFourthTrip = []
  guestsLeftFourthTripTime: string
  guestsLeftFirstReturnTrip = []
  guestsLeftFirstReturnTripTime: string
  guestsLeftSecondReturnTrip = []
  guestsLeftSecondReturnTripTime: string
  guestsLeftThirdReturnTrip = []
  guestsLeftThirdReturnTripTime: string
  guestsLeftFourthReturnTrip = []
  guestsLeftFourthReturnTripTime: string

  seasonStartArray = []

  selectedTravelDate: Date
  tripTime = []
  returnTripTime = []
  departureTripTime = []
  tourPriceForBooking = []
  paymentAvoid: boolean
  location = []
  currentRegisterTours = []
  selectedFileV = []
  selectedFileH = []
  linkInfoFO = []
  linkInfoEN = []

  constructor(
    private router: Router,
    public bookingService: BookingService,
    public hikingService: HikingService,
    public pageService: PageService,
    public newService: NewsService,
    public cancelService: CancelService,
    public emailService: EmailService,
    public paymentService: PaymentService,
    public userService: UserService,
    public infoService: InfoService,
    public dialog: MatDialog,
    public uploadImageService: UploadImageService,
    public adsService: AdsService,
  ) {
    this.randomNumber = new Date().getTime().toString(36)
    for (let i = 1; i < 100; i++) {
      this.seats.push(i)
    }
    if (this.activeMenu === 'Bókingar - Túrar') {
      this.getBookingInfo({ admin: true })
    }
    if (this.activeMenu === 'Bókingar - Gongugjald') {
      this.getHikingInfo()
    }

    this.getTourInfo()
    this.getAdInfo()
    this.getRouteInfo()
    this.getCondition()
    this.getUsers()
    this.isLogin()
    this.getCancelTrip()
		this.getDiscountEmails()

    if (this.activeMenu === 'Samla ferðafólka tøl') {
      this.bookingService
        .getBooking({ admin: true })
        .then((data) => {
          data = data == null ? [] : data
          data.forEach((element) => {
            const year = new Date(element.departureDate).getFullYear()
            this.ar.includes(year) ? '' : this.ar.push(year)
          })
        })
        .then(() => {
          this.getBookingTol()
        })
    }
  }
  getCancelTrip() {
    this.cancelService.getCancelTours().then((response) => {
      this.canceledTrip = response
    })
  }
  fileChange(event) {
    let fileList: FileList = event.target.files
    if (fileList.length > 0) {
      let file: File = fileList[0]
      var img: any = document.querySelector('#preview img')
      img.file = file
      var reader = new FileReader()
      reader.onload = (function (aImg: any) {
        return function (e) {
          aImg.src = e.target.result
        }
      })(img)
      reader.readAsDataURL(file)
    }
  }
  getBookingTol() {
    const monthWithZero =
      this.choosenDay.getMonth() + 1 < 10
        ? '0' + (this.choosenDay.getMonth() + 1)
        : this.choosenDay.getMonth() + 1
    const dateWithZero =
      this.choosenDay.getDate() < 10
        ? '0' + this.choosenDay.getDate()
        : this.choosenDay.getDate()
    const choosenDate =
      this.choosenDay.getFullYear() + '-' + monthWithZero + '-' + dateWithZero
    var tourIndex = this.tours.findIndex((x) => x.tour === this.tolTour)
    const monthIndex = this.manadar.findIndex(
      (x) => x.name === this.choosenMonth,
    )
    const month = this.manadar[monthIndex].value

    this.hiking = this.tours[tourIndex].accountType === 'hiking'
    this.ferdafolk = []
    this.hikingPeople = []
    const bookinginfo = {
      year: this.choosenYear,
      tour: this.tolTour,
      touren: this.tours[tourIndex].touren,
      hiking: this.hiking,
      month: month < 10 ? '0' + month : month,
      date: choosenDate,
      dateSelected: this.tolRadio === 'dagar',
    }

    this.bookingService.getStats(bookinginfo).then((data) => {
      data.forEach((element) => {
        if (this.hiking) {
          if (element[0]['SUM(adult+juniorSenior+children)']) {
            this.hikingPeople.push(
              element[0]['SUM(adult+juniorSenior+children)'],
            )
          } else {
            this.hikingPeople.push(element[0]['SUM(goldid)'])
          }
        } else {
          if (element[0]['SUM(adults+juniorSenior+children)']) {
            this.ferdafolk.push(element[0]['SUM(adults+juniorSenior+children)'])
          } else {
            this.ferdafolk.push(element[0]['SUM(goldid)'])
          }
        }
      })
    })
  }

  onFileChanged(idx, event, type) {
    if (type === 'vertical') {
      this.selectedFileV[idx] = event.target.files[0]
    } else {
      this.selectedFileH[idx] = event.target.files[0]
    }
  }

  accountTypeChange(type, id) {
    this.tours = this.tours.map((element) => {
      if (element.id === id) {
        element.accountType = type
      }
      return element
    })
  }

  onUpload(idx, fileName, type) {
    // sessionStorage.getItem('customer');

    const uploadData = new FormData()
    uploadData.append(
      'fileToUpload',
      type === 'vertical' ? this.selectedFileV[idx] : this.selectedFileH[idx],
      fileName + '.png',
    )

    this.uploadImageService
      .uploadImage(uploadData)
      .then((data) => {
        location.reload()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  getBookingInfo(value?) {
    if (value) {
      this.bookingValue = value
    } else {
      this.bookingValue = ''
    }
    this.bookings = []
    this.bookingService
      .getBooking({ admin: true })
      .then((data) => {
        data = data == null ? [] : data
        const obj = data
        this.bookings = obj.filter((x) => x.payment !== 'Not Paid')
        
        obj.forEach((info) => {
          const year = new Date(info.departureDate).getFullYear()
          if (!this.ar.includes(year)) {
            this.ar.push()
          }
          if (this.trips.indexOf(info.tour) === -1) {
            this.trips.push(info.tour)
          }
        })
        this.loadBooking = true
        sessionStorage.setItem('hikings', null)
        sessionStorage.setItem('bookings', JSON.stringify(this.bookings))
        this.filterBookings = []
        this.filterBookings = this.bookings
        this.filterBookings.sort(this.compare)
      })
      .catch((error) => {
        alert(error)
      })
  }

  getHikingInfo(value?) {
    if (value) {
      this.hikingValue = value
    } else {
      this.hikingValue = ''
    }
    this.hikings = []
    this.hikingService
      .getHiking({ false: false })
      .then((data) => {
        const obj = data
        this.hikings = obj

        if (obj !== null) {
          obj.forEach((info) => {
            if (this.trips.indexOf(info.tour) === -1) {
              this.trips.push(info.tour)
            }
          })

          this.loadHiking = true
          sessionStorage.setItem('bookings', null)
          sessionStorage.setItem('hikings', JSON.stringify(this.hikings))
          this.filterHikings = []
          this.filterHikings = this.hikings
          this.filterHikings.sort(this.compare)
        }
      })
      .catch((error) => {
        alert(error)
      })
  }

  isLogin() {
    if (
      sessionStorage.getItem('login') === 'signOut' ||
      !sessionStorage.getItem('login')
    ) {
      const dialogRef = this.dialog.open(LoginModalComponent, {})
    }
  }

  deleteUser(id) {
    this.userService
      .deleteUser({ id })
      .then((value) => {
        if (id === sessionStorage.getItem('login')) {
          sessionStorage.setItem('login', 'signOut')
          this.isLogin()
        }
        alert('Brúkari strikaður')
        this.getUsers()
      })
      .catch(() => {})
  }

  getUsers() {
    this.userService
      .getUsers()
      .then((value) => {
        this.users = []
        const userObj = value
        userObj.forEach((user) => {
          this.users.push(user)
        })
      })
      .catch(() => {})
  }

  signOut() {
    sessionStorage.setItem('login', 'signOut')
    if (sessionStorage.getItem('login') === 'signOut') {
      const dialogRef = this.dialog.open(LoginModalComponent, {})
    }
  }

  register() {
    this.userService
      .registerUser(this.userProfile)
      .then((value) => {
        if (value === 1) {
          alert('Brúkari skrásettur')
          this.getUsers()
        } else {
          alert(value)
        }
      })
      .catch(() => {})
  }

  saveCondition() {
    this.condition.conditionEn = this.condition.conditionEn.replace(/\s+/g, ' ')
    this.condition.conditionFo = this.condition.conditionFo.replace(/\s+/g, ' ')
    this.infoService
      .updateCondition(this.condition)
      .then((value) => {
        if (value === 1) {
          alert('Treytir eru goymdar')
        } else {
          alert('Okkurt gekk gali, royn aftur seinni')
        }
      })
      .catch(() => {})
  }

  getCondition() {
    this.infoService
      .getCondition()
      .then((info) => {
        const infoObj = info
        this.condition.conditionFo = infoObj[0].conditionFo
        this.condition.conditionEn = infoObj[0].conditionEn
      })
      .catch(() => {})
  }
  selectConditionLan(lan) {
    this.conditionLan = lan
  }

	/**
	 * Add a single discount field to the list of discount fields
	 * 
	 * This will just add an empty field for filling out
	 * 
	 * @author 					Pætur Mortensen
	 */
	addDiscountField(){
		this.discountFields.push({ email: '', discount: '0'})
		this.checkDiscountsChanged()
	}

	/**
	 * Delete a single discount field
	 * 
	 * @author 					Pætur Mortensen 
	 */
	deleteDiscountField(fieldIndex){
		this.discountFields.splice(fieldIndex, 1)
		this.checkDiscountsChanged()
	}

	/**
	 * Update the discounts on the server
	 * 
	 * @author 					Pætur Mortensen
	 */
	updateDiscounts(){
		// Quick validation to ensure we're not sending empty fields
		this.discountFields = this.discountFields.filter(field => field.email && field.discountAmount);
		
		this.emailService
			.updateDiscountEmails(this.discountFields)
			.then( response => {
				const discountEmails = [];
				response.discounts.forEach( discount => {
					discountEmails.push({...discount, discountAmount:parseInt(discount.discountAmount)})
				})
				this.updateDiscountData(discountEmails);
			})
			.catch(() => {})
	}

	
	/**
	 * Update the discount emails and their fields with cloned data
	 *  
	 * @author 					Pætur Mortensen 
	 */
	updateDiscountData(discountEmails){
		this.discountEmails = discountEmails;
		this.discountFields = JSON.parse(JSON.stringify(discountEmails));

		// Emails and fields are the same, so we call checkDiscountsChanged to ensure it's set
		this.checkDiscountsChanged();
	}

	/**
	 * Check whether discounts have changed since save
	 * 
	 * @author 					Pætur Mortensen
	 */
	checkDiscountsChanged(){
		this.discountsChanged = 
			JSON.stringify(this.discountEmails) !== JSON.stringify(this.discountFields);
	}

	/**
	 * Fetch and set up discount emails
	 * 
	 * @author 					Pætur Mortensen
	 */
	getDiscountEmails() {
		this.emailService
			.getDiscountEmails()
			.then( response => {
				const discountEmails = [];
				response.forEach( discount => {
					discountEmails.push({...discount, discountAmount:parseInt(discount.discountAmount)})
				})
				this.updateDiscountData(discountEmails);
			})
			.catch(() => {})
	}

  addEmail() {
    this.emails.push({ email: '', id: '' })
  }

  updateEmail(value, id?) {
    const emailInfo = {
      email: value,
      id,
    }

    this.emailService
      .updateEmail(emailInfo)
      .then((info) => {
        this.getEmail()
      })
      .catch(() => {})
  }

  deleteEmail(value, id?) {
    const emailInfo = {
      email: value,
      id,
    }

    this.emailService
      .deleteEmail(emailInfo)
      .then((info) => {
        this.getEmail()
      })
      .catch(() => {})
  }

  getEmail() {
    this.emails = []
    this.emailService
      .getEmail()
      .then((emailInfo) => {
        const infoObj = emailInfo
        infoObj.forEach((info) => {
          this.emails.push(info)
        })
      })
      .catch(() => {})
  }
  getFilterBookings(filterBookings, time, id) {
		console.log('get filter bookings.');
    if (
      filterBookings.filter(
        (x) =>
          (x.departureTime === time || x.returnTime === time) && x.tourId == id,
      ).length > 0
    ) {
      return true
    }
    return false
  }
  getTour(id) {
		return this.tours.find((x) => x.id === id)
  }
  getTourInfo() {
    this.bookingService
      .getTours()
      .then((info) => {
        this.tours = info
        for (let i = 0; i < this.tours.length; i++) {
          this.currentRegisterTours.push({
            tourfo: this.tours[i].tour,
            touren: this.tours[i].touren,
          })
        }
      })
      .catch((err) => {})
    this.updateNewsList()
    this.updateInfoList()
    this.updatePage()
    this.getEmail()
  }
  addAd() {
    const newAd = {
      id: 0,
      link: '',
      headlineFo: '',
      headlineEn: '',
      textFo: '',
      textEn: '',
      imageUrl: '',
      new: true,
    }

    this.ads.unshift(newAd)
  }

  getAdInfo() {
    this.adsService
      .getAdInfo()
      .then((info) => {
        this.ads = info ? info : []
      })
      .catch((err) => {})
  }
  deleteAd(i) {
    let currentAd = this.ads[i]
    currentAd.delete = true
    this.adsService
      .updateAdInfo({
        ad: JSON.stringify(currentAd),
      })
      .then((data) => {
        if (data.return) {
          this.ads.splice(i, 1)
          alert('Lýsing er sletta')
        } else {
          alert(data)
        }
      })
  }
  saveAds(i) {
    let currentAd = this.ads[i]
    this.adsService
      .updateAdInfo({
        ad: JSON.stringify(currentAd),
      })
      .then((data) => {
        if (data.return) {
          this.ads[i].id = data.id;
          this.ads[i].new = false;
          alert('Lýsing eru goymd')
        } else {
          alert(data)
        }
      })
  }
  getRouteInfo() {
    this.bookingService
      .getRoutes()
      .then((info) => {
        info.forEach((element) => {
          if (element.departureCity !== '') {
            if (element.extraTrip == '1') {
              if (
                !this.location.some(
                  (x) => x === element.departureCity + ' (Extra Trip)',
                )
              ) {
                this.location.push(element.departureCity + ' (Extra Trip)')
              }
            } else {
              if (!this.location.some((x) => x === element.departureCity)) {
                this.location.push(element.departureCity)
              }
            }
          }
          if (element.returnCity !== '') {
            if (element.extraTrip == '1') {
              if (
                !this.location.some(
                  (x) => x === element.returnCity + ' (Extra Trip)',
                )
              ) {
                this.location.push(element.returnCity + ' (Extra Trip)')
              }
            } else {
              if (!this.location.some((x) => x === element.returnCity)) {
                this.location.push(element.returnCity)
              }
            }
          }
          const depTime = new Date(element.departureTime).toLocaleTimeString(
            'en-GB',
            {
              timeZone: 'Europe/London',
              hour: '2-digit',
              minute: '2-digit',
            },
          )
          const retTime = new Date(element.returnTime).toLocaleTimeString(
            'en-GB',
            {
              timeZone: 'Europe/London',
              hour: '2-digit',
              minute: '2-digit',
            },
          )
          if (
            !this.tripTime.includes(depTime) &&
            element.departureTime !== ''
          ) {
            this.tripTime.push(depTime)
          }
          if (!this.location.includes(retTime) && element.returnTime !== '') {
            this.tripTime.push(retTime)
          }

          this.returnTripTime.push({
            departure: element.departureCity,
            return: element.returnCity,
          })
        })
        this.routes = info ? info : []
        this.routes.map((x) => {
          if (x.departureTime) {
            x.departureTimeFormat = new Date(x.departureTime)
            x.returnTimeFormat = new Date(x.returnTime)
            x.departureTime = new Date(x.departureTime).toLocaleString(
              'en-GB',
              {
                timeZone: 'Europe/London',
                hour: 'numeric',
                minute: 'numeric',
              },
            )

            x.returnTime = new Date(x.returnTime).toLocaleString('en-GB', {
              timeZone: 'Europe/London',
              hour: 'numeric',
              minute: 'numeric',
            })
          }
          return x
        })

        this.routes.sort(this.compareSeason)
      })
      .catch((err) => {})
  }

  compareSeason(a, b) {
    if (a.seasonStart < b.seasonStart) {
      return 1
    }
    if (a.seasonStart > b.seasonStart) {
      return -1
    }
    return 0
  }
  compare(a, b) {
    if (a.reg_date < b.reg_date) {
      return 1
    }
    if (a.reg_date > b.reg_date) {
      return -1
    }
    return 0
  }
  compareSeasonDate(a, b) {
    if (a.seasonStart > b.seasonStart) {
      return 1
    }
    if (a.seasonStart < b.seasonStart) {
      return -1
    }
    return 0
  }
  compareNameDesc(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return 1
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return -1
    }
    return 0
  }

  compareNameAsc(a, b) {
    if (
      a.name.toLowerCase().replace(/\s/g, '') <
      b.name.toLowerCase().replace(/\s/g, '')
    ) {
      return -1
    }
    if (
      a.name.toLowerCase().replace(/\s/g, '') >
      b.name.toLowerCase().replace(/\s/g, '')
    ) {
      return 1
    }
    return 0
  }

  compareKlDesc(a, b) {
    if (a.departureTime.toLowerCase() < b.departureTime.toLowerCase()) {
      return 1
    }
    if (a.departureTime.toLowerCase() > b.departureTime.toLowerCase()) {
      return -1
    }
    return 0
  }

  compareKlAsc(a, b) {
    if (a.departureTime.toLowerCase() < b.departureTime.toLowerCase()) {
      return -1
    }
    if (a.departureTime.toLowerCase() > b.departureTime.toLowerCase()) {
      return 1
    }
    return 0
  }

  cancelRangeDate(event, i) {
    const first = new Date(
      Date.UTC(
        event.value[0].getFullYear(),
        event.value[0].getMonth(),
        event.value[0].getDate(),
      ),
    )
    const second = new Date(
      Date.UTC(
        event.value[1].getFullYear(),
        event.value[1].getMonth(),
        event.value[1].getDate(),
      ),
    )

    const year = first.getUTCFullYear()
    const day = (first.getUTCDate() < 10 ? '0' : '') + first.getUTCDate()
    const month =
      (first.getUTCMonth() + 1 < 10 ? '0' : '') + (first.getUTCMonth() + 1)

    const year1 = second.getFullYear()
    const day1 = (second.getUTCDate() < 10 ? '0' : '') + second.getUTCDate()
    const month1 =
      (second.getUTCMonth() + 1 < 10 ? '0' : '') + (second.getUTCMonth() + 1)

    this.cancelDate = JSON.parse(
      '["' +
        year +
        '-' +
        month +
        '-' +
        day +
        '" , "' +
        year1 +
        '-' +
        month1 +
        '-' +
        day1 +
        '"]',
    )
  }

  newsRangeDate(event) {
    const first = new Date(event.value[0])
    first.setDate(first.getDate() + 1)
    const second = new Date(event.value[1])
    second.setDate(second.getDate() + 1)

    const year = first.getUTCFullYear()
    const day = (first.getUTCDate() < 10 ? '0' : '') + first.getUTCDate()
    const month =
      (first.getUTCMonth() + 1 < 10 ? '0' : '') + (first.getUTCMonth() + 1)

    const year1 = second.getFullYear()
    const day1 = (second.getUTCDate() < 10 ? '0' : '') + second.getUTCDate()
    const month1 =
      (second.getUTCMonth() + 1 < 10 ? '0' : '') + (second.getUTCMonth() + 1)

    this.newsDate = JSON.parse(
      '["' +
        year +
        '' +
        month +
        '-' +
        day +
        '" , "' +
        year1 +
        '' +
        month1 +
        '-' +
        day1 +
        '"]',
    )
  }

  deleteNews(value) {
    const r = confirm('Ynskir tú at strika Tíðindi')
    if (r === true) {
      this.newService
        .deleteNews({ id: value })
        .then((info) => {
          if (info) {
            this.updateNewsList()
          } else {
          }
        })
        .catch(() => {})
    }
  }

  deleteInfo(value) {
    const r = confirm('Ynskir tú at strika upplýsingar')
    if (r === true) {
      this.infoService
        .deleteInfo({ id: value })
        .then((info) => {
          if (info) {
            this.updateInfoList()
          } else {
          }
        })
        .catch(() => {})
    }
  }

  deleteCancel(value) {
    const r = confirm('Ynskir tú at strika avlýsing')
    if (r === true) {
      this.cancelService
        .deleteCancel({ id: value })
        .then((info) => {
          if (info) {
            this.updateCancelList()
          } else {
          }
        })
        .catch(() => {})
    }
  }
  findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i
      }
    }
    return -1
  }
  sendCancelEmail(canceled, id, parentIndex, index, extraMessage?) {
    this.bookingService
      .getTours()
      .then((tourInfo) => {
        const tours = tourInfo
        const canceledTour = this.findWithAttr(tours, 'id', canceled.tour)

        this.countEmail.count = 0
        this.countEmail.id = id
        this.loading = index
        this.countLoop = 0
        let count = 0
        let reason: string
        if (this.reasonCancel[canceled.reason][canceled.reason] === 'Other') {
          reason = ''
        } else {
          reason =
            'due to ' + this.reasonCancel[canceled.reason][canceled.reason]
        }

        // if (tours[canceledTour].accountType.hiking) {
        //   this.hikingService
        //     .getHiking({ future: false })
        //     .then((data) => {
        //       const obj = data;
        //       this.countTimeout = 0;
        //       obj.forEach((obj, i) => {
        //         if (
        //           (obj.tour === tours[canceledTour].tour ||
        //             obj.tour === tours[canceledTour].touren) &&
        //           obj.date >= canceled.fromDate &&
        //           obj.date <= canceled.toDate
        //         ) {
        //           if (canceled.time === "Allir túrar") {
        //             this.cancelInfo = {
        //               fromDate: canceled.fromDate,
        //               tour: tours[canceledTour].touren,
        //               name: obj.name,
        //               email: obj.email,
        //               comments: obj.comments,
        //               paymentid: obj.paymentid,
        //               reason,
        //               extraMessage,
        //             };
        //             this.countLoop++;
        //             ((x) => {
        //               this.countTimeout++;
        //               setTimeout(() => {
        //                 count++;
        //                 this.emailService
        //                   .cancelEmailEn(this.cancelInfo)
        //                   .then((info) => {
        //                     this.countEmail.count++;
        //                     this.cancelService
        //                       .updateRefundEmail({
        //                         sentEmail: this.countEmail.count,
        //                         id,
        //                       })
        //                       .then(() => {
        //                         this.canceledTrip[parentIndex][
        //                           index
        //                         ].sentEmail = this.countEmail.count;
        //                       })
        //                       .catch(() => {});
        //                   })
        //                   .catch((err) => {
        //                     alert(err);
        //                   });
        //                 if (this.countLoop === count) {
        //                   this.loading = -1;
        //                   alert("Liðugt");
        //                 }
        //               }, this.countTimeout * 1000);
        //             })(i);
        //           }

        //           if (
        //             canceled.time === obj.departureTime ||
        //             canceled.time === obj.returnTime
        //           ) {
        //             const cancelInfo = {
        //               fromDate: canceled.fromDate,
        //               tour: tours[canceledTour].touren,
        //               name: obj.name,
        //               phone: obj.phone,
        //               email: obj.email,
        //               comments: obj.comments,
        //               paymentid: obj.paymentid,
        //               reason,
        //               extraMessage,
        //             };
        //             this.countLoop++;
        //             ((x) => {
        //               this.countTimeout++;
        //               setTimeout(() => {
        //                 count++;
        //                 this.emailService
        //                   .cancelEmailEn(cancelInfo)
        //                   .then((info) => {
        //                     this.countEmail.count++;
        //                     this.cancelService
        //                       .updateRefundEmail({
        //                         sentEmail: this.countEmail.count,
        //                         id,
        //                       })
        //                       .then(() => {
        //                         this.canceledTrip[parentIndex][
        //                           index
        //                         ].sentEmail = this.countEmail.count;
        //                       })
        //                       .catch(() => {});
        //                   })
        //                   .catch((err) => {
        //                     alert(err);
        //                   });
        //                 if (this.countLoop === count) {
        //                   this.loading = -1;
        //                   alert("Liðugt");
        //                 }
        //               }, this.countTimeout * 1000);
        //             })(i);
        //           }
        //         }
        //       });
        //       // for (let i = 0; i < obj.length; i++) {
        //       //   if (
        //       //     (obj[i].tour === tours[canceledTour].tour || obj[i].tour === tours[canceledTour].touren) &&
        //       //     ((obj[i].date >= canceled.fromDate &&
        //       //       obj[i].date <= canceled.toDate))
        //       //   ) {

        //       //     if (canceled.time === 'Allir túrar') {
        //       //       this.cancelInfo = {
        //       //         fromDate: canceled.fromDate,
        //       //         tour: tours[canceledTour].touren,
        //       //         name: obj[i].name,
        //       //         email: obj[i].email,
        //       //         comments: obj[i].comments,
        //       //         paymentid: obj[i].paymentid,
        //       //         reason,
        //       //         extraMessage
        //       //       };
        //       //       this.countLoop++;
        //       //       ((x) => {
        //       //         this.countTimeout++;
        //       //         setTimeout(() => {
        //       //           count++;
        //       //           this.emailService.cancelEmailEn(this.cancelInfo).then((info) => {
        //       //             this.countEmail.count++;
        //       //             this.cancelService.updateRefundEmail({ sentEmail: this.countEmail.count, id }).then(() => {
        //       //               this.canceledTrip[parentIndex][index].sentEmail = this.countEmail.count;
        //       //             }).catch(() => {

        //       //             });

        //       //           }).catch((err) => {
        //       //             alert(err);
        //       //           });
        //       //           if (this.countLoop === count) {
        //       //             this.loading = -1;
        //       //             alert('Liðugt');
        //       //           }
        //       //         }, this.countTimeout * 1000);
        //       //       })(i);

        //       //     }

        //       //     if (canceled.time === obj[i].departureTime || canceled.time === obj[i].returnTime) {

        //       //       const cancelInfo = {
        //       //         fromDate: canceled.fromDate,
        //       //         tour: tours[canceledTour].touren,
        //       //         name: obj[i].name,
        //       //         phone: obj[i].phone,
        //       //         email: obj[i].email,
        //       //         comments: obj[i].comments,
        //       //         paymentid: obj[i].paymentid,
        //       //         reason,
        //       //         extraMessage
        //       //       };
        //       //       this.countLoop++;
        //       //       ((x) => {

        //       //         this.countTimeout++;
        //       //         setTimeout(() => {
        //       //           count++;
        //       //           this.emailService.cancelEmailEn(cancelInfo).then((info) => {
        //       //             this.countEmail.count++;
        //       //             this.cancelService.updateRefundEmail({ sentEmail: this.countEmail.count, id }).then(() => {
        //       //               this.canceledTrip[parentIndex][index].sentEmail = this.countEmail.count;
        //       //             }).catch(() => {

        //       //             });
        //       //           }).catch((err) => {
        //       //             alert(err);
        //       //           });
        //       //           if (this.countLoop === count) {
        //       //             this.loading = -1;
        //       //             alert('Liðugt');
        //       //           }
        //       //         }, this.countTimeout * 1000);
        //       //       })(i);
        //       //     }
        //       //   }
        //       // }
        //     })
        //     .catch(() => {});
        // } else {
        this.bookingService
          .getBooking({ admin: true })
          .then((data) => {
            data = data == null ? [] : data
            let obj = data
            this.countTimeout = 0

            obj = obj.filter(
              (x) =>
                (x.tour === tours[canceledTour].tour ||
                  x.tour === tours[canceledTour].touren) &&
                ((x.departureDate >= canceled.fromDate &&
                  x.departureDate <= canceled.toDate) ||
                  (x.returnDate >= canceled.fromDate &&
                    x.returnDate <= canceled.toDate)) &&
                (x.departureTime == canceled.time ||
                  x.returnTime == canceled.time),
            )
            obj = obj.map((element) => {
              ;(element.fromDate = canceled.fromDate), (element.reason = reason)
              element.extraMessage = extraMessage
              return element
            })

            this.emailService
              .cancelMultipleEmail({ obj })
              .then((info) => {
                this.cancelService
                  .updateRefundEmail({
                    sentEmail: obj.length,
                    id,
                  })
                  .then(() => {
                    this.canceledTrip[parentIndex][index].sentEmail = obj.length
                  })
                  .catch(() => {})
              })
              .catch((err) => {
                alert(err)
              })

            // obj.forEach((obj, i) => {
            //   if (
            //     (obj.tour === tours[canceledTour].tour ||
            //       obj.tour === tours[canceledTour].touren) &&
            //     ((obj.departureDate >= canceled.fromDate &&
            //       obj.departureDate <= canceled.toDate) ||
            //       (obj.returnDate >= canceled.fromDate &&
            //         obj.returnDate <= canceled.toDate))
            //   ) {
            //     if (canceled.time === "Allir túrar") {
            //       this.cancelInfo = {
            //         fromDate: canceled.fromDate,
            //         tour: tours[canceledTour].touren,
            //         name: obj.name,
            //         phone: obj.phone,
            //         email: obj.email,
            //         comments: obj.comments,
            //         paymentid: obj.paymentid,
            //         reason,
            //         extraMessage,
            //       };
            //       this.countLoop++;
            //       ((x) => {
            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;

            //           this.emailService
            //             .cancelEmailEn(this.cancelInfo)
            //             .then((info) => {
            //               this.countEmail.count++;
            //               this.cancelService
            //                 .updateRefundEmail({
            //                   sentEmail: this.countEmail.count,
            //                   id,
            //                 })
            //                 .then(() => {
            //                   this.canceledTrip[parentIndex][
            //                     index
            //                   ].sentEmail = this.countEmail.count;
            //                 })
            //                 .catch(() => {});
            //             })
            //             .catch((err) => {
            //               alert(err);
            //             });
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert("Liðugt");
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);
            //     }

            //     if (
            //       canceled.time === obj.departureTime ||
            //       canceled.time === obj.returnTime
            //     ) {
            //       const cancelInfo = {
            //         fromDate: canceled.fromDate,
            //         tour: tours[canceledTour].touren,
            //         name: obj.name,
            //         phone: obj.phone,
            //         email: obj.email,
            //         comments: obj.comments,
            //         paymentid: obj.paymentid,
            //         reason,
            //         extraMessage,
            //       };
            //       this.countLoop++;
            //       ((x) => {
            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;
            //           this.emailService
            //             .cancelEmailEn(cancelInfo)
            //             .then((info) => {
            //               this.countEmail.count++;
            //               this.cancelService
            //                 .updateRefundEmail({
            //                   sentEmail: this.countEmail.count,
            //                   id,
            //                 })
            //                 .then(() => {
            //                   this.canceledTrip[parentIndex][
            //                     index
            //                   ].sentEmail = this.countEmail.count;
            //                 })
            //                 .catch(() => {});
            //             })
            //             .catch((err) => {
            //               alert(err);
            //             });
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert("Liðugt");
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);
            //     }
            //   }
            // });
            // for (let i = 0; i < obj.length; i++) {
            //   if (
            //     (obj[i].tour === tours[canceledTour].tour || obj[i].tour === tours[canceledTour].touren) &&
            //     ((obj[i].seasonStart >= canceled.fromDate &&
            //       obj[i].seasonStart <= canceled.toDate) ||
            //       (obj[i].seasonEnd >= canceled.fromDate &&
            //         obj[i].seasonEnd <= canceled.toDate))
            //   ) {

            //     if (canceled.time === 'Allir túrar') {
            //       this.cancelInfo = {
            //         fromDate: canceled.fromDate,
            //         tour: tours[canceledTour].touren,
            //         name: obj[i].name,
            //         phone: obj[i].phone,
            //         email: obj[i].email,
            //         comments: obj[i].comments,
            //         paymentid: obj[i].paymentid,
            //         reason,
            //         extraMessage
            //       };
            //       this.countLoop++;
            //       ((x) => {
            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;
            //           this.emailService.cancelEmailEn(this.cancelInfo).then((info) => {
            //             this.countEmail.count++;
            //             this.cancelService.updateRefundEmail({ sentEmail: this.countEmail.count, id }).then(() => {
            //               this.canceledTrip[parentIndex][index].sentEmail = this.countEmail.count;
            //             }).catch(() => {

            //             });

            //           }).catch((err) => {
            //             alert(err);
            //           });
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert('Liðugt');
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);

            //     }

            //     if (canceled.time === obj[i].departureTime || canceled.time === obj[i].returnTime) {

            //       const cancelInfo = {
            //         fromDate: canceled.fromDate,
            //         tour: tours[canceledTour].touren,
            //         name: obj[i].name,
            //         phone: obj[i].phone,
            //         email: obj[i].email,
            //         comments: obj[i].comments,
            //         paymentid: obj[i].paymentid,
            //         reason,
            //         extraMessage
            //       };
            //       this.countLoop++;
            //       ((x) => {

            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;
            //           this.emailService.cancelEmailEn(cancelInfo).then((info) => {
            //             this.countEmail.count++;
            //             this.cancelService.updateRefundEmail({ sentEmail: this.countEmail.count, id }).then(() => {
            //               this.canceledTrip[parentIndex][index].sentEmail = this.countEmail.count;
            //             }).catch(() => {

            //             });
            //           }).catch((err) => {
            //             alert(err);
            //           });
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert('Liðugt');
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);
            //     }
            //   }
            // }
          })
          .catch(() => {})
        // }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  sendCancelRefund(canceled, id, parentIndex, index) {
    this.bookingService
      .getTours()
      .then((tourInfo) => {
        const tours = tourInfo
        const canceledTour = this.findWithAttr(tours, 'id', canceled.tour)
        this.countRefund.count = 0
        this.countTimeout = 0
        this.countLoop = 0
        this.countRefund.id = id
        this.loading = index
        // if (tours[canceledTour].accountType.hiking) {
        //   this.hikingService
        //     .getHiking({ future: false })
        //     .then((data) => {
        //       const obj = data;

        //       this.countTimeout = 0;
        //       let count = 0;
        //       obj.forEach((obj, i) => {
        //         if (
        //           (obj.tour === tours[canceledTour].tour ||
        //             obj.tour === tours[canceledTour].touren) &&
        //           obj.date >= canceled.fromDate &&
        //           obj.date <= canceled.toDate
        //         ) {
        //           if (
        //             canceled.time === "Allir túrar" &&
        //             obj.payment !== "Faktura" &&
        //             obj.payment !== "Endurgoldið" &&
        //             !obj.avoidPayment
        //           ) {
        //             this.countLoop++;
        //             ((x) => {
        //               this.countTimeout++;
        //               setTimeout(() => {
        //                 count++;
        //                 this.paymentService
        //                   .cancelHikingRefundPayment({
        //                     paymentid: obj.paymentid,
        //                   })
        //                   .then((info) => {
        //                     if (info.split("-")[1]) {
        //                       this.countRefund.count++;
        //                       this.cancelService
        //                         .updateRefundEmail({
        //                           cancelRefund: this.countRefund.count,
        //                           id,
        //                         })
        //                         .then(() => {
        //                           this.hikingService
        //                             .updatePaymentStatus({
        //                               paymentid: obj.paymentid,
        //                             })
        //                             .then(() => {
        //                               this.emailService
        //                                 .sendRefundCancelEn({
        //                                   name: obj.name,
        //                                   tour: obj.tour,
        //                                   email: obj.email,
        //                                 })
        //                                 .then(() => {
        //                                   this.canceledTrip[parentIndex][
        //                                     index
        //                                   ].cancelRefund =
        //                                     this.countRefund.count;
        //                                 })
        //                                 .catch(() => {});
        //                             })
        //                             .catch(() => {});
        //                         })
        //                         .catch(() => {});
        //                     }
        //                   })
        //                   .catch((err) => {});
        //                 if (this.countLoop === count) {
        //                   this.loading = -1;
        //                   alert("Liðugt");
        //                 }
        //               }, this.countTimeout * 1000);
        //             })(i);
        //           }
        //           if (
        //             (canceled.time === obj.departureTime ||
        //               canceled.time === obj.returnTime) &&
        //             obj.payment !== "Faktura" &&
        //             obj.payment !== "Endurgoldið" &&
        //             !obj.avoidPayment
        //           ) {
        //             this.countLoop++;
        //             ((x) => {
        //               this.countTimeout++;
        //               setTimeout(() => {
        //                 count++;
        //                 this.paymentService
        //                   .cancelRefundPayment({ paymentid: obj.paymentid })
        //                   .then((info) => {
        //                     if (info.split("-")[1]) {
        //                       this.countRefund.count++;
        //                       this.cancelService
        //                         .updateRefundEmail({
        //                           cancelRefund: this.countRefund.count,
        //                           id,
        //                         })
        //                         .then(() => {
        //                           this.bookingService
        //                             .updatePaymentStatus({
        //                               paymentid: obj.paymentid,
        //                             })
        //                             .then(() => {
        //                               this.emailService
        //                                 .sendRefundCancelEn({
        //                                   name: obj.name,
        //                                   tour: obj.tour,
        //                                   email: obj.email,
        //                                   paymentid: obj.paymentid,
        //                                 })
        //                                 .then(() => {
        //                                   this.canceledTrip[parentIndex][
        //                                     index
        //                                   ].cancelRefund =
        //                                     this.countRefund.count;
        //                                 })
        //                                 .catch(() => {});
        //                             })
        //                             .catch(() => {});
        //                         })
        //                         .catch(() => {});
        //                     }
        //                   })
        //                   .catch((err) => {});
        //                 if (this.countLoop === count) {
        //                   this.loading = -1;
        //                   alert("Liðugt");
        //                 }
        //               }, this.countTimeout * 1000);
        //             })(i);
        //           }
        //         } else {
        //           if (i === obj.length - 1 && this.countLoop === 0) {
        //             this.loading = -1;
        //             alert("Liðugt");
        //           }
        //         }
        //       });
        //       for (let i = 0; i < obj.length; i++) {}
        //     })
        //     .catch(() => {});
        // } else {
        this.bookingService
          .getBooking({ admin: true })
          .then((data) => {
            data = data == null ? [] : data
            let obj = data

            this.countTimeout = 0
            let count = 0
            obj = obj.filter(
              (x) =>
                (x.tour === tours[canceledTour].tour ||
                  x.tour === tours[canceledTour].touren) &&
                ((x.departureDate >= canceled.fromDate &&
                  x.departureDate <= canceled.toDate) ||
                  (x.returnDate >= canceled.fromDate &&
                    x.returnDate <= canceled.toDate)) &&
                (x.departureTime == canceled.time ||
                  x.returnTime == canceled.time),
            )

            if (canceled.time === 'Allir túrar') {
              obj = obj.filter((x) => {
                return (
                  obj.payment !== 'Faktura' && obj.payment !== 'Endurgoldið'
                )
              })
            } else {
              obj = obj.filter((x) => {
                return (
                  (canceled.time === x.departureTime ||
                    canceled.time === x.returnTime) &&
                  x.payment !== 'Faktura' &&
                  x.payment !== 'Endurgoldið' &&
                  !x.avoidPayment
                )
              })
            }
            this.paymentService
              .cancelMultipleRefundPayment({ obj })
              .then((info) => {
                info.substring(0, info.length - 1).split(',')
                this.cancelService
                  .updateRefundEmail({
                    cancelRefund: obj.length,
                    id,
                  })
                  .then((value) => {
                    this.bookingService
                      .updateMultiplePaymentStatus({
                        obj,
                      })
                      .then((value) => {
                        this.emailService
                          .sendMultipleRefundCancelEn({
                            obj,
                          })
                          .then((value) => {
                            let findex = this.canceledTrip.findIndex(
                              (x) => x.id === id,
                            )

                            this.canceledTrip[findex].cancelRefund = obj.length
                            this.loading = -1
                          })
                          .catch((err) => {
                            this.loading = -1
                            console.error(err)
                          })
                      })
                      .catch((err) => {
                        console.error(err)
                      })
                  })
                  .catch((err) => {
                    console.error(err)
                  })
              })
              .catch((err) => {
                console.error(err)
              })

            // obj.forEach((obj, i) => {
            //   if (
            //     (obj.tour === tours[canceledTour].tour ||
            //       obj.tour === tours[canceledTour].touren) &&
            //     ((obj.departureDate >= canceled.fromDate &&
            //       obj.departureDate <= canceled.toDate) ||
            //       (obj.returnDate >= canceled.fromDate &&
            //         obj.returnDate <= canceled.toDate))
            //   ) {
            //     if (
            //       canceled.time === "Allir túrar" &&
            //       obj.payment !== "Faktura" &&
            //       obj.payment !== "Endurgoldið" &&
            //       !obj.avoidPayment
            //     ) {
            //       this.countLoop++;
            //       ((x) => {
            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;
            //           this.paymentService
            //             .cancelRefundPayment({ paymentid: obj.paymentid })
            //             .then((info) => {
            //               if (info.split("-")[1]) {
            //                 this.countRefund.count++;
            //                 this.cancelService
            //                   .updateRefundEmail({
            //                     cancelRefund: this.countRefund.count,
            //                     id,
            //                   })
            //                   .then(() => {
            //                     this.bookingService
            //                       .updatePaymentStatus({
            //                         paymentid: obj.paymentid,
            //                       })
            //                       .then(() => {
            //                         this.emailService
            //                           .sendRefundCancelEn({
            //                             name: obj.name,
            //                             tour: obj.tour,
            //                             email: obj.email,
            //                             paymentid: obj.paymentid,
            //                           })
            //                           .then(() => {
            //                             this.canceledTrip[parentIndex][
            //                               index
            //                             ].cancelRefund = this.countRefund.count;
            //                           })
            //                           .catch(() => {});
            //                       })
            //                       .catch(() => {});
            //                   })
            //                   .catch(() => {});
            //               }
            //             })
            //             .catch((err) => {});
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert("Liðugt");
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);
            //     }
            //     if (
            //       (canceled.time === obj.departureTime ||
            //         canceled.time === obj.returnTime) &&
            //       obj.payment !== "Faktura" &&
            //       obj.payment !== "Endurgoldið" &&
            //       !obj.avoidPayment
            //     ) {
            //       this.countLoop++;
            //       ((x) => {
            //         this.countTimeout++;
            //         setTimeout(() => {
            //           count++;
            //           this.paymentService
            //             .cancelRefundPayment({ paymentid: obj.paymentid })
            //             .then((info) => {
            //               if (info.split("-")[1]) {
            //                 this.countRefund.count++;
            //                 this.cancelService
            //                   .updateRefundEmail({
            //                     cancelRefund: this.countRefund.count,
            //                     id,
            //                   })
            //                   .then(() => {
            //                     this.bookingService
            //                       .updatePaymentStatus({
            //                         paymentid: obj.paymentid,
            //                       })
            //                       .then(() => {
            //                         this.emailService
            //                           .sendRefundCancelEn({
            //                             name: obj.name,
            //                             tour: obj.tour,
            //                             email: obj.email,
            //                             paymentid: obj.paymentid,
            //                           })
            //                           .then(() => {
            //                             this.canceledTrip[parentIndex][
            //                               index
            //                             ].cancelRefund = this.countRefund.count;
            //                           })
            //                           .catch(() => {});
            //                       })
            //                       .catch(() => {});
            //                   })
            //                   .catch(() => {});
            //               }
            //             })
            //             .catch((err) => {});
            //           if (this.countLoop === count) {
            //             this.loading = -1;
            //             alert("Liðugt");
            //           }
            //         }, this.countTimeout * 1000);
            //       })(i);
            //     }
            //   } else {
            //     if (i === obj.length - 1 && this.countLoop === 0) {
            //       this.loading = -1;
            //       alert("Liðugt");
            //     }
            //   }
            // });
            // for (let i = 0; i < obj.length; i++) {

            // }
          })
          .catch(() => {})
        // }
      })
      .catch(() => {})
  }

  setDelay(i) {
    setTimeout(() => {
      console.log('test',i)
    }, 1000)
  }

  saveNews() {
    let newsDateStart: any
    let newsDateEnd: any
    if (this.newsDate.length > 0) {
      newsDateStart = new Date(this.newsDate[0])
      newsDateStart =
        newsDateStart.getFullYear() +
        '-' +
        (newsDateStart.getUTCMonth() + 1 < 10 ? '0' : '') +
        (newsDateStart.getUTCMonth() + 1) +
        '-' +
        (newsDateStart.getUTCDate() < 10 ? '0' : '') +
        newsDateStart.getUTCDate()
      newsDateEnd = new Date(this.newsDate[1])
      newsDateEnd =
        newsDateEnd.getFullYear() +
        '-' +
        (newsDateEnd.getUTCMonth() + 1 < 10 ? '0' : '') +
        (newsDateEnd.getUTCMonth() + 1) +
        '-' +
        (newsDateEnd.getUTCDate() < 10 ? '0' : '') +
        newsDateEnd.getUTCDate()
    } else {
      newsDateStart = ''
      newsDateEnd = ''
    }

    const newsInfo = {
      commentEn: this.newsTekstEn,
      headlineFo: this.newsHeadlineFo,
      headlineEn: this.newsHeadlineEn,
      commentFo: this.newsTekstFo,
    }

    this.newService
      .addNews(newsInfo)
      .then((info) => {
        this.updateNewsList()
      })
      .catch(() => {})
  }

  saveInfo() {
    const infoInfo = {
      commentEn: this.infoTekstEn,
      headlineFo: this.infoHeadlineFo,
      headlineEn: this.infoHeadlineEn,
      commentFo: this.infoTekstFo,
    }
    this.infoService
      .addInfo(infoInfo)
      .then((info) => {
        this.updateInfoList()
      })
      .catch(() => {})
  }

  reasonCancelTrip(value) {
    this.selectedReason = value
  }

  cancelTrip(value) {
    this.cancelTime = value
    if (value === 'all') {
      this.cancelTime = 'Allir túrar'
    }
  }

  saveCanceledTrip(tour) {
    if (this.cancelTime === '') {
      alert('Vinarlig vel tíðspunkt')
      return
    }
    if (this.cancelDate[0] !== undefined && this.cancelDate[1] !== undefined) {
      if (tour === '1577720466537') {
        this.cancelTime = 'Allir túrar'
        this.selectedReason = 0
      }

      const cancelDateStart = new Date(this.cancelDate[0])
      const cancelDateEnd = new Date(this.cancelDate[1])

      this.cancelService
        .cancelTours({
          tour,
          fromDate:
            cancelDateStart.getFullYear() +
            '-' +
            (cancelDateStart.getUTCMonth() + 1 < 10 ? '0' : '') +
            (cancelDateStart.getUTCMonth() + 1) +
            '-' +
            (cancelDateStart.getUTCDate() < 10 ? '0' : '') +
            cancelDateStart.getUTCDate(),
          toDate:
            cancelDateEnd.getFullYear() +
            '-' +
            (cancelDateEnd.getUTCMonth() + 1 < 10 ? '0' : '') +
            (cancelDateEnd.getUTCMonth() + 1) +
            '-' +
            (cancelDateEnd.getUTCDate() < 10 ? '0' : '') +
            cancelDateEnd.getUTCDate(),
          time: this.cancelTime,
          reason: this.selectedReason,
          extraMessage: this.extraMessage,
        })
        .then((info) => {
          if (info) {
            this.updateCancelList()
            alert('Avlýsing er framd')
          } else {
            alert('Villa')
          }
        })
        .catch(() => {})
    } else {
      alert('Vinarlig vel dato')
    }
  }

  updateCancelList() {
    for (let t = 0; t < this.canceledTrip.length; t++) {
      this.canceledTrip[t] = []
    }
    this.cancelService
      .getCancelTours()
      .then((cancelInfo) => {
        const infoObj = cancelInfo
        this.canceledTrip = cancelInfo
        // infoObj.forEach((info) => {
        //   this.canceledTrip.forEach((element) => {
        //     element.push(info);
        //     element.sort(this.compare);
        //   });
        // });
      })
      .catch(() => {})
  }

  updateNewsList() {
    this.allNews = []
    this.newService
      .getNews()
      .then((newsInfo) => {
        const infoObj = newsInfo
        infoObj.forEach((info) => {
          this.allNews.push(info)
          this.allNews.sort(this.compare)
        })
      })
      .catch(() => {})
  }

  updateInfoList() {
    this.allInfo = []
    this.infoService
      .getInfo()
      .then((infoInfo) => {
        const infoObj = JSON.parse(infoInfo)
        infoObj.forEach((info) => {
          this.allNews.push(info)
          this.allNews.sort(this.compare)
        })
      })
      .catch(() => {})
  }

  openMenu(menu) {
    if (menu === 'Farleið') {
      this.bookingService.getRoutes().then((data) => {
        
      })
    }
    if (menu === 'Samla ferðafólka tøl') {
      this.bookingService
        .getBooking({ admin: true })
        .then((data) => {
          data = data == null ? [] : data
          data.forEach((element) => {
            const year = new Date(element.departureDate).getFullYear()
            this.ar.includes(year) ? '' : this.ar.push(year)
          })
        })
        .then(() => {
          this.getBookingTol()
        })
    }
    if (menu === 'Bókingar - Túrar') {
      this.sliceEnd = 100
      this.loadBooking = false
      this.bookings = []
      this.bookingService
        .getBooking({ admin: true })
        .then((data) => {
          data = data == null ? [] : data
          const obj = data
          this.bookings = obj
          obj.forEach((info) => {
            if (this.trips.indexOf(info.tour) === -1) {
              this.trips.push(info.tour)
            }
          })
          this.loadBooking = true
          sessionStorage.setItem('hikings', null)
          sessionStorage.setItem('bookings', JSON.stringify(this.bookings))
          this.filterBookings = []
          this.filterBookings = this.bookings
          this.filterBookings.sort(this.compare)
        })
        .catch((error) => {
          alert(error)
        })
    }
    if (menu === 'Bókingar - Gongugjald') {
      this.getHikingInfo()
    }
    if (menu === 'Túrar') {
      this.getTourInfo()
    }
    if (menu === 'Farleið') {
      this.getRouteInfo()
    }
    sessionStorage.setItem('menu', menu)
    this.activeMenu = sessionStorage.getItem('menu')
    this.selectedPage = false
  }

  addPage() {
    this.pageInfo.push({
      menuFo: 'Nýggj síða',
      menuEn: 'New page',
      contentFo: '',
      contentEn: '',
      id: -1,
    })
  }

  savepage(i, id?) {
    let pageInfo: any
    pageInfo = {
      menuFo: this.pageInfo[i].menuFo,
      menuEn: this.pageInfo[i].menuEn,
      contentFo: this.pageInfo[i].contentFo.replace(/\s+/g, ' '),
      contentEn: this.pageInfo[i].contentEn.replace(/\s+/g, ' '),
      id,
    }

    this.pageService
      .updatePages(pageInfo)
      .then((info) => {
        this.updatePage()
      })
      .catch(() => {})
  }

  deletePage(i, id?) {
    this.pageService
      .deletePage({ id })
      .then(() => {
        this.updatePage()
      })
      .catch(() => {})
  }

  updatePage() {
    this.pageInfo = []
    this.pageService
      .getPages()
      .then((pageInfo) => {
        const infoObj = pageInfo
        infoObj.forEach((element) => {
          this.pageInfo.push(element)
        })
      })
      .catch(() => {})
  }

  selectPageLanguage(lan) {
    this.languageSelect = lan
  }

  selectPageMenu(id) {
    if (id === this.selectedPage) {
      this.selectedPage = -1
    } else {
      this.selectedPage = id
    }
  }
  setRoute(event, type, id, i) {
    switch (type) {
      case 'seasonStart':
        this.routes[i].seasonStart = event.value
        break
      case 'departureTime':
        this.routes[i].departureTime = event.value
        break
      case 'departureCity':
        this.routes[i].departureCity = event.target.value
        break
      case 'seasonEnd':
        this.routes[i].seasonEnd = event.value
        break
      case 'returnTime':
        this.routes[i].returnTime = event.value
        break
      case 'returnCity':
        this.routes[i].returnCity = event.target.value
        break
      case 'payment':
        this.routes[i].payemntMethod = event.value
        break
      case 'extraTrip':
        this.routes[i].extraTrip = event.target.checked ? 1 : 0
        break
      default:
        break
    }
  }

  addRoute(tourId) {
    const newTour = {
      id: 0,
      tour: JSON.parse(tourId),
      seasonStart: '',
      departureTime: '',
      departureCity: '',
      seasonEnd: '',
      returnTime: '',
      returnCity: '',
      payemntMethod: '0',
      extraTrip: 0,
      new: true,
    }

    this.routes.unshift(newTour)
  }
  deleteRoute(i) {
    let currentRoute = this.routes[i]
    let id = 0
    if (currentRoute.new) {
      this.routes.splice(i, 1)
      return
    } else {
      id = currentRoute.id
    }
    const res = confirm('Ynskir tú at strika rutuna?')
    if (res === true) {
      this.bookingService
        .deleteRoute({
          id: id,
        })
        .then((data) => {
          let hasOnes = true
          for (let i = 0; i < data.length; i++) {
            hasOnes = data[i] == 1 && hasOnes ? true : false
          }
          if (hasOnes) {
            alert('Rutan er strika')
            window.location.reload()
          } else {
            alert(data)
          }
        })
    }
  }

  saveRute(i) {
    let currentRoute = this.routes[i]
    if (currentRoute.accountType === 'hiking') {
      currentRoute.oneway = 1
    }
    this.bookingService
      .updateRoute({
        route: JSON.stringify(currentRoute),
      })
      .then((data) => {
        if (data.return) {
          this.routes[i].id = data.id
          this.routes[i].new = false
          alert('Farleiðin er goymd')
        } else {
          alert(data)
        }
      })
  }
  addTour() {
    const newTour = {
      id: 0,
      tour: 'New tour / hike',
      accountType: 'trip',
      priceAdult: 0,
      priceSeniorJunior: 0,
      priceChildren: 0,
      priceAdultExtra: 0,
      priceSeniorJuniorExtra: 0,
      priceChildrenExtra: 0,
      payment: '',
      weekdays: [1, 1, 1, 1, 1, 1, 1],
      fragreiding: '',
      fragreidingEN: '',
      new: true,
    }
    this.tours.unshift(newTour)
  }

  seat(info, id) {
    this.tours = this.tours.map((element) => {
      if (element.id === id) {
        element.seat = info
      }
      return element
    })
  }

  saveSettings() {
    this.bookingService
      .updateTours({
        tours: JSON.stringify(this.tours),
      })
      .then((data) => {
        let hasOnes = true
        for (let i = 0; i < data.length; i++) {
          hasOnes = data[i] == 1 && hasOnes ? true : false
        }
        if (hasOnes) {
          alert('Túranir eru goymdir')
        } else {
          alert(data)
        }
      })
  }

  selectDay(idx, i) {
    this.tours[i].chooseDay[idx] = this.tours[i].chooseDay[idx] ? false : true
  }

  searchIndex(nameKey, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return i
      }
    }
  }

  deleteTour(id) {
    const res = confirm(
      'Ynskir tú at strika rutuna og allar avlýsingar fyri rutuna?',
    )
    if (res === true) {
      this.bookingService
        .deleteTour({
          id: id,
        })
        .then((data) => {
          let hasOnes = true
          for (let i = 0; i < data.length; i++) {
            hasOnes = data[i] == 1 && hasOnes ? true : false
          }
          if (hasOnes) {
            alert('Túrurin eru strikaður')
            window.location.reload()
          } else {
            alert(data)
          }
        })
    }
  }

  print() {
    window.print()
  }

  filterTrip(value) {
    this.selected = false
    this.filterBookings = []
    this.filterDateBookings = []
    const obj = this.bookings
    obj.forEach((element) => {
      if (value !== 1) {
        if (element.tour.indexOf(value) === -1) {
        } else {
          this.filterBookings.push(element)
          this.filterDateBookings.push(element)
        }
      } else {
        this.filterBookings.push(element)
        this.filterDateBookings.push(element)
      }
    })
  }

  filterName(value) {
    if (value === 'desc') {
      this.filterBookings.sort(this.compareNameDesc)
    } else if (value === 'asc') {
      this.filterBookings.sort(this.compareNameAsc)
    } else {
      this.filterBookings.sort(this.compare)
    }
  }

  search(value) {
    this.filterBookings = []
    this.filterHikings = []
    let obj = []
    if (value === 'hiking') {
      obj = this.hikings
    } else {
      obj = this.bookings
    }
    obj.forEach((element) => {
      if (
        JSON.stringify(element).toUpperCase().includes(this.leita.toUpperCase())
      ) {
        if (value === 'hiking') {
          this.filterHikings.push(element)
        } else {
          this.filterBookings.push(element)
        }
      }
    })
  }

  filterAscDesc(ascDesc, value) {
    if (value === 'kl') {
      if (ascDesc === 'asc') {
        this.filterBookings.sort(this.compareKlAsc)
      } else {
        this.filterBookings.sort(this.compareKlDesc)
      }
    } else {
      if (ascDesc === 'asc') {
        this.filterBookings.sort(this.compareNameAsc)
      } else {
        this.filterBookings.sort(this.compareNameDesc)
      }
    }
  }

  changeseasonStart(i) {
  }

  selectedDate(value) {
    // this.filterBookings = [];
    // this.filterBookings = this.bookings;
    // this.filterBookings.sort(this.compare);

    this.guestsLeftFirstTrip = []
    this.guestsLeftSecondTrip = []

    this.filterBookings = []
    this.filterHikings = []
    let obj = []
    if (value === 'hiking') {
      obj = JSON.parse(sessionStorage.getItem('hikings'))
    } else {
      obj = JSON.parse(sessionStorage.getItem('bookings'))
    }

    const year = this.selectedTravelDate.getFullYear()
    const day =
      (this.selectedTravelDate.getDate() < 10 ? '0' : '') +
      this.selectedTravelDate.getDate()
    const month =
      (this.selectedTravelDate.getMonth() + 1 < 10 ? '0' : '') +
      (this.selectedTravelDate.getMonth() + 1)
    this.selctedDate = year + '-' + month + '-' + day
    obj.forEach((element) => {
      if (value === 'hiking') {
        if (
          element.date === this.selctedDate &&
          element.payment !== 'Endurgoldið'
        ) {
          this.filterHikings.push(element)
        }
      } else {
        if (
          element.departureDate === year + '-' + month + '-' + day &&
          element.payment !== 'Endurgoldið'
        ) {
          if (element.returnDate !== year + '-' + month + '-' + day) {
            element.returnDate = ''
            element.returnTime = ''
            element.returnLocation = ''
          }
          this.filterBookings.push(element)
        }

        if (
          element.returnDate === year + '-' + month + '-' + day &&
          !JSON.stringify(this.filterBookings).includes(
            JSON.stringify(element),
          ) &&
          element.payment !== 'Endurgoldið'
        ) {
          if (element.departureDate !== year + '-' + month + '-' + day) {
            element.departureDate = ''
            element.departureTime = ''
            element.departureLocation = ''
          }
          this.filterBookings.push(element)
        }
      }
    })
    let filterObj = []
    if (value === 'hiking') {
      filterObj = this.filterHikings
      this.sliceEnd = this.filterHikings.length
    } else {
      this.sliceEnd = this.filterBookings.length
      filterObj = this.filterBookings
    }

    this.routes.forEach((element) => {
      if (!this.getTour(element.tour)) {
        return
      }
      if (this.getTour(element.tour).accountType === 'trip') {
        this.guestsLeftFirstTrip.push(
          {
            id: element.id,
            time: element.departureTime,
            left: 0,
            tourId: element.tour,
          },
          {
            id: element.id,
            time: element.returnTime,
            left: 0,
            tourId: element.tour,
          },
        )
      }
    })

    for (let t = 0; t < this.routes.length; t++) {
      if (value === 'hiking') {
      } else {
        filterObj.forEach((element) => {
          let departureLeft = this.guestsLeftFirstTrip.find(
            (x) =>
              x.id === this.routes[t].id &&
              element.tourId == this.routes[t].tour &&
              x.time === element.departureTime,
          )
          let returnLeft = this.guestsLeftFirstTrip.find((x) => {
            return (
              x.id === this.routes[t].id &&
              element.tourId == this.routes[t].tour &&
              x.time === element.returnTime
            )
          })

          if (departureLeft) {
            departureLeft.left +=
              parseInt(element.adults, 10) +
              parseInt(element.juniorSenior, 10) +
              parseInt(element.children, 10)
          }
          if (returnLeft) {
            returnLeft.left +=
              parseInt(element.adults, 10) +
              parseInt(element.juniorSenior, 10) +
              parseInt(element.children, 10)
          }
        })
      }
    }
  }
  rangeDate(event) {
    this.guestsLeftFirstTrip = []
    this.guestsLeftSecondTrip = []
    this.guestsLeftThirdTrip = []
    this.guestsLeftFourthTrip = []
    this.guestsLeftFirstReturnTrip = []
    this.guestsLeftSecondReturnTrip = []
    this.guestsLeftThirdReturnTrip = []
    this.guestsLeftFourthReturnTrip = []
    setTimeout(() => {
      this.dato = ''
    }, 10)

    this.filterBookings = []
    const obj = this.filterDateBookings
    obj.forEach((element) => {
      const first = new Date(event.value[0])
      first.setDate(first.getDate() + 1)
      const second = new Date(event.value[1])
      second.setDate(second.getDate() + 1)

      const year = first.getUTCFullYear()
      const day = (first.getUTCDate() < 10 ? '0' : '') + first.getUTCDate()
      const month =
        (first.getUTCMonth() + 1 < 10 ? '0' : '') + (first.getUTCMonth() + 1)
      this.selctedDate = day + '-' + month + '-' + year
      const year1 = second.getFullYear()
      const day1 = (second.getUTCDate() < 10 ? '0' : '') + second.getUTCDate()
      const month1 =
        (second.getUTCMonth() + 1 < 10 ? '0' : '') + (second.getUTCMonth() + 1)

      if (
        element.departureDate >= year + '-' + month + '-' + day &&
        element.departureDate <= year1 + '-' + month1 + '-' + day1
      ) {
        this.filterBookings.push(element)
      }
    })
  }

  cancelRefundSingle(value, i, hiking?) {
    const r = confirm('Ynskir tú at endurgjalda?')
    if (r === true) {
      if (hiking) {
        if (this.filterHikings[i].payment === 'Goldið') {
          this.paymentService
            .cancelHikingRefundPayment({
              paymentid: value,
            })
            .then((info) => {
              if (info.split('-')[1]) {
                this.hikingService
                  .updatePaymentStatus({
                    paymentid: this.filterHikings[i].paymentid,
                  })
                  .then(() => {
                    this.emailService
                      .sendRefundCancelEn({
                        name: this.filterHikings[i].name,
                        tour: this.filterHikings[i].tour,
                        email: this.filterHikings[i].email,
                        paymentid: this.filterHikings[i].paymentid,
                      })
                      .then(() => {
                        this.filterHikings[i].payment = 'Endurgoldið'
                        alert('Bóking er endurgoldin')
                      })
                      .catch((error) => {
                        alert(
                          'Villa - Email er ikki sendur, men upphæddin er endurgoldin',
                        )
                      })
                  })
                  .catch((error) => {
                    alert(
                      'Villa skipan ikki dagførd, men upphæddin er endurgoldin',
                    )
                  })
              } else {
                alert('Villa: ' + info)
              }
            })
            .catch(() => {})
        } else {
          alert('Villa')
        }
      } else {
        if (this.filterBookings[i].payment === 'Goldið') {
          this.paymentService
            .cancelRefundPayment({
              paymentid: this.filterBookings[i].paymentid,
              departureLocation: this.filterBookings[i].departureLocation,
              returnLocation: this.filterBookings[i].returnLocation,
              tourId: this.filterBookings[i].tourId,
            })
            .then((info) => {
              if (info.split('-')[1]) {
                this.bookingService
                  .updatePaymentStatus({
                    paymentid: this.filterBookings[i].paymentid,
                  })
                  .then(() => {
                    this.emailService
                      .sendRefundCancelEn({
                        name: this.filterBookings[i].name,
                        tour: this.filterBookings[i].tour,
                        email: this.filterBookings[i].email,
                        paymentid: this.filterBookings[i].paymentid,
                      })
                      .then(() => {
                        this.filterBookings[i].payment = 'Endurgoldið'
                        alert('Bóking er endurgoldin')
                      })
                      .catch((error) => {
                        alert('Villa' + error)
                      })
                  })
                  .catch((error) => {
                    alert('Villa' + error)
                  })
              } else {
                alert('Villa: ' + info)
              }
            })
            .catch(() => {})
        } else {
          alert('Villa')
        }
      }
    }
  }

  avoidPayment(id, value, hiking?) {
    let checkBox: boolean
    if (value) {
      checkBox = false
    } else {
      checkBox = true
    }
    if (hiking) {
      this.hikingService
        .updateAvoidPayment({ id, avoidPayment: checkBox })
        .then((data) => {})
        .catch(() => {})
    } else {
      this.bookingService
        .updateAvoidPayment({ id, avoidPayment: checkBox })
        .then((data) => {})
        .catch(() => {})
    }
  }

  saveBooking(value, i) {

    const res = confirm('Ynskir tú at dagføra bókingina')
    if (res === true) {
      const paymentid = this.filterBookings[i].paymentid
      const tour = this.filterBookings[i].tour
      const name = this.filterBookings[i].name
      const phone = this.filterBookings[i].phone
      const email = this.filterBookings[i].email
      const comments = this.filterBookings[i].comments

      const d = new Date(this.filterBookings[i].departureDate)

      const departureDate = this.filterBookings[i].departureDate
        ? d.getFullYear() +
          '-' +
          (d.getMonth() + 1 < 10 ? '0' : '') +
          (d.getMonth() + 1) +
          '-' +
          (d.getDate() < 10 ? '0' : '') +
          d.getDate()
        : ''
      const departureTime = this.filterBookings[i].departureTime
      const departureLocation = this.filterBookings[i].departureLocation
      const r = new Date(this.filterBookings[i].returnDate)
      const returnDate =
        this.filterBookings[i].returnDate !== ''
          ? r.getFullYear() +
            '-' +
            (r.getMonth() + 1 < 10 ? '0' : '') +
            (r.getMonth() + 1) +
            '-' +
            (r.getDate() < 10 ? '0' : '') +
            r.getDate()
          : ''

      const returnTime = this.filterBookings[i].returnTime
      const returnLocation = this.filterBookings[i].returnLocation
      const adults = this.filterBookings[i].adults
      const juniorSenior = this.filterBookings[i].juniorSenior
      const children = this.filterBookings[i].children
      const payment = this.filterBookings[i].payment
      const total =
        parseInt(adults, 10) +
        parseInt(juniorSenior, 10) +
        parseInt(children, 10)
      let tourNumber
      let departureLeft = 0
      let returnLeft = 0
      let error: string
      const bookings = this.filterBookings
      this.filterBookings.forEach((element) => {
        if (
          (element.departureTime === departureTime &&
            (tour === element.tour || tour === element.touren) &&
            departureDate === element.departureDate) ||
          (element.departureTime === returnTime &&
            (tour === element.tour || tour === element.touren) &&
            departureDate === element.returnDate)
        ) {
          departureLeft +=
            parseInt(element.adults, 10) +
            parseInt(element.juniorSenior, 10) +
            parseInt(element.children, 10)
        }
        if (
          (element.returnTime === returnTime &&
            (tour === element.tour || tour === element.touren) &&
            returnDate === element.returnDate) ||
          (element.departureTime === returnTime &&
            (tour === element.tour || tour === element.touren) &&
            returnDate === element.departureDate)
        ) {
          returnLeft +=
            parseInt(element.adults, 10) +
            parseInt(element.juniorSenior, 10) +
            parseInt(element.children, 10)
        }
      })
      if (
        departureLeft > 80 &&
        departureDate === returnDate &&
        departureDate.length > 0
      ) {
        error =
          'Tað er ikki nok pláss fráferð tann ' +
          departureDate +
          ' kl. ' +
          departureTime
      }
      if (
        returnLeft > 80 &&
        departureDate === returnDate &&
        returnDate.length > 0
      ) {
        error =
          'Tað er ikki nok pláss heimferð tann ' +
          returnDate +
          ' kl. ' +
          returnTime
      }
      if (
        departureLeft > 80 &&
        departureDate !== returnDate &&
        returnDate !== '' &&
        departureDate.length > 0
      ) {
        error =
          'Tað er ikki nok pláss fráferð tann ' +
          departureDate +
          ' kl. ' +
          departureTime
      }
      if (
        returnLeft > 80 &&
        departureDate !== returnDate &&
        returnDate !== '' &&
        returnDate.length > 0
      ) {
        error =
          'Tað er ikki nok pláss heimferð tann ' +
          returnDate +
          ' kl. ' +
          returnTime
      }
      if (!error) {
        for (let t = 0; t < this.tours.length; t++) {
          if (this.tours[t].tour === tour || this.tours[t].touren === tour) {
            tourNumber = t
          }
        }

        const bookingInfo = {
          paymentid: paymentid ? paymentid : '',
          id: value,
          tour,
          name,
          phone,
          email,
          comments,
          departureDate,
          departureTime,
          departureLocation,
          returnDate,
          returnTime,
          returnLocation,
          adults,
          juniorSenior,
          children,
          payment,
          priceAdult: this.tours[tourNumber].priceAdult,
          priceJuniorSenior: this.tours[tourNumber].priceSeniorJunior,
          priceChildren: this.tours[tourNumber].priceChildren,
          priceAdultExtra: this.tours[tourNumber].priceAdultExtra,
          priceJuniorSeniorExtra: this.tours[tourNumber].priceSeniorJuniorExtra,
          priceChildrenExtra: this.tours[tourNumber].priceChildrenExtra,
        }

        this.bookingService.updateBooking(bookingInfo).then((data) => {
          if (data === 1) {
            this.bookingService.updatePdf(bookingInfo).then((data) => {
              const res1 = confirm(
                'Ynskir tú at senda ein teldupost til gestin',
              )
              if (res1 === true) {
                this.emailService
                  .sendEmailEn(bookingInfo)
                  .then(() => {
                    this.loadBooking = false
                    this.getBookingInfo('Bóking er goymd')
                  })
                  .catch((err) => {
                    alert('Villa' + err)
                  })
              }
            })
          } else {
            alert(data)
          }
        })
      } else {
        alert(error)
      }
    }
  }

  saveHiking(value, i) {
    const res = confirm('Ynskir tú at dagføra gongugjald')
    if (res === true) {
      const paymentid = this.filterHikings[i].paymentid
      const tour = this.filterHikings[i].tour
      const name = this.filterHikings[i].name
      const email = this.filterHikings[i].email
      const comments = this.filterHikings[i].comments
      const d = new Date(this.filterHikings[i].date)

      const date = this.filterHikings[i].date
        ? d.getFullYear() +
          '-' +
          (d.getMonth() + 1 < 10 ? '0' : '') +
          (d.getMonth() + 1) +
          '-' +
          (d.getDate() < 10 ? '0' : '') +
          d.getDate()
        : ''

      const adults = this.filterHikings[i].adult
      const juniorSenior = this.filterHikings[i].juniorSenior
      const children = this.filterHikings[i].children
      const payment = this.filterHikings[i].payment
      const total =
        parseInt(adults, 10) +
        parseInt(juniorSenior, 10) +
        parseInt(children, 10)
      let tourNumber
      let departureLeft = 0
      let error: string
      const hikings = this.filterHikings
      this.filterHikings.forEach((element) => {
        if (
          (tour === element.tour || tour === element.touren) &&
          date === element.date
        ) {
          departureLeft +=
            parseInt(element.adults, 10) +
            parseInt(element.juniorSenior, 10) +
            parseInt(element.children, 10)
        }
      })

      if (departureLeft > 80 && date.length > 0) {
        error = 'Tað er ikki nok pláss fráferð tann ' + date
      }
      if (!error) {
        for (let t = 0; t < this.tours.length; t++) {
          if (this.tours[t].tour === tour || this.tours[t].touren === tour) {
            tourNumber = t
          }
        }

        const hikingInfo = {
          paymentid: paymentid ? paymentid : '',
          id: value,
          tour,
          name,
          email,
          comments,
          date,
          adults,
          juniorSenior,
          children,
          payment,
          priceAdult: this.tours[tourNumber].priceAdult,
          priceJuniorSenior: this.tours[tourNumber].priceSeniorJunior,
          priceChildren: this.tours[tourNumber].priceChildren,
        }
        this.hikingService.updateHiking(hikingInfo).then((data) => {
          if (data === '1') {
            const res1 = confirm('Ynskir tú at teldupost til gestin')
            if (res1 === true) {
              this.emailService
                .sendHikingEmail(hikingInfo)
                .then(() => {
                  this.loadHiking = false
                  this.getHikingInfo('Bóking er goymd')
                })
                .catch((err) => {
                  alert('Villa' + err)
                })
            }
          } else {
            alert(data)
          }
        })
      } else {
        alert(error)
      }
    }
  }

  deleteBooking(value, i) {
    const res = confirm('Ynskir tú at strika bókingina')
    if (res === true) {
      this.bookingService
        .deleteBooking({ id: value })
        .then((info) => {
          if (info) {
            const paymentid = this.filterBookings[i].paymentid
            const tour = this.filterBookings[i].tour
            const name = this.filterBookings[i].name
            const phone = this.filterBookings[i].phone
            const email = this.filterBookings[i].email
            const comments = this.filterBookings[i].comments
            const d = new Date(this.filterBookings[i].departureDate)
            const departureDate = this.filterBookings[i].departureDate
              ? d.getFullYear() +
                '-' +
                (d.getUTCMonth() + 1 < 10 ? '0' : '') +
                (d.getUTCMonth() + 1) +
                '-' +
                (d.getUTCDate() < 10 ? '0' : '') +
                d.getUTCDate()
              : ''
            const departureTime = this.filterBookings[i].departureTime
            const departureLocation = this.filterBookings[i].departureLocation
            const r = new Date(this.filterBookings[i].returnDate)
            const returnDate =
              this.filterBookings[i].returnDate !== ''
                ? r.getFullYear() +
                  '-' +
                  (r.getUTCMonth() + 1 < 10 ? '0' : '') +
                  (r.getUTCMonth() + 1) +
                  '-' +
                  (r.getUTCDate() < 10 ? '0' : '') +
                  r.getUTCDate()
                : ''
            const returnTime = this.filterBookings[i].returnTime
            const returnLocation = this.filterBookings[i].returnLocation
            const adults = this.filterBookings[i].adults
            const juniorSenior = this.filterBookings[i].juniorSenior
            const children = this.filterBookings[i].children
            const payment = this.filterBookings[i].payment
            let tourNumber
            for (let t = 0; t < this.tours.length; t++) {
              if (
                this.tours[t].tour === tour ||
                this.tours[t].touren === tour
              ) {
                tourNumber = t
              }
            }
            const bookingInfo = {
              paymentid: paymentid ? paymentid : '',
              id: value,
              tour,
              name,
              phone,
              email,
              comments,
              departureDate,
              departureTime,
              departureLocation,
              returnDate,
              returnTime,
              returnLocation,
              adults,
              juniorSenior,
              children,
              payment,
              priceAdult: this.tours[tourNumber].priceAdult,
              priceJuniorSenior: this.tours[tourNumber].priceSeniorJunior,
              priceChildren: this.tours[tourNumber].priceChildren,
            }
            this.emailService
              .sendDeleteEn(bookingInfo)
              .then(() => {
                this.loadBooking = false
                this.getBookingInfo('Bóking strika')
              })
              .catch((error) => {
                alert('Villa' + error)
              })
          } else {
          }
        })
        .catch((error) => {
          alert('Villa' + error)
        })
    }
  }

  deleteHiking(value, i) {
    const res = confirm('Ynskir tú at strika Gongugjald')
    if (res === true) {
      this.hikingService
        .deleteHiking({ id: value })
        .then((info) => {
          if (info) {
            const paymentid = this.filterHikings[i].paymentid
            const tour = this.filterHikings[i].tour
            const name = this.filterHikings[i].name
            const phone = this.filterHikings[i].phone
            const email = this.filterHikings[i].email
            const comments = this.filterHikings[i].comments
            const d = new Date(this.filterHikings[i].date)
            const date = this.filterHikings[i].date
              ? d.getFullYear() +
                '-' +
                (d.getUTCMonth() + 1 < 10 ? '0' : '') +
                (d.getUTCMonth() + 1) +
                '-' +
                (d.getUTCDate() < 10 ? '0' : '') +
                d.getUTCDate()
              : ''
            const adults = this.filterHikings[i].adult
            const juniorSenior = this.filterHikings[i].juniorSenior
            const children = this.filterHikings[i].children
            const payment = this.filterHikings[i].payment
            let tourNumber
            for (let t = 0; t < this.tours.length; t++) {
              if (
                this.tours[t].tour === tour ||
                this.tours[t].touren === tour
              ) {
                tourNumber = t
              }
            }
            const hikingInfo = {
              paymentid: paymentid ? paymentid : '',
              id: value,
              tour,
              name,
              phone,
              email,
              comments,
              date,
              adults,
              juniorSenior,
              children,
              payment,
              priceAdult: this.tours[tourNumber].priceAdult,
              priceJuniorSenior: this.tours[tourNumber].priceSeniorJunior,
              priceChildren: this.tours[tourNumber].priceChildren,
            }
            this.emailService
              .sendDeleteEn(hikingInfo)
              .then(() => {
                this.loadHiking = false
                this.getHikingInfo('Gongugjald strika')
              })
              .catch((error) => {
                alert('Villa' + error)
              })
          } else {
          }
        })
        .catch((error) => {
          alert('Villa' + error)
        })
    }
  }

  clearFilter(value) {
    if (value === 'hiking') {
      this.selected = true
      this.loadHiking = false
      this.getHikingInfo()
      this.selctedDate = ''
      this.selectedTravelDate = null
    } else {
      this.selected = true
      this.loadBooking = false
      this.getBookingInfo()
      this.selctedDate = ''
      this.selectedTravelDate = null
    }
  }
}
