import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class EmailService {

  constructor(public http: HttpClient) {

  }

  getEmail() {
    const link = 'https://mykines.vita.fo/php/getEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {

          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateEmail(email) {
    const link = 'https://mykines.vita.fo/php/updateEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, email)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  deleteEmail(id) {
    const link = 'https://mykines.vita.fo/php/deleteEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  sendEmailEn(emailInfo) {
    const link = 'https://mykines.vita.fo/php/sendEmailEn.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, emailInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          console.log(error)
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  sendHikingEmail(emailInfo) {
    const link = 'https://mykines.vita.fo/php/sendHikingEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, emailInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  sendRefundCancelEn(refundCancel) {
    const link = 'https://mykines.vita.fo/php/sendRefundCancelEn.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, refundCancel)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
  sendMultipleRefundCancelEn(refundCancel) {
    const link = 'https://mykines.vita.fo/php/sendMultipleRefundCancelEn.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, refundCancel)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  sendDeleteEn(deleteInfo) {
    const link = 'https://mykines.vita.fo/php/sendDeleteEn.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, deleteInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  cancelEmailEn(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/cancelEmailEn.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log(error)
          console.log('Oooops!');
        });
    });
  }
  cancelMultipleEmail(bookingInfo) {
    const link = 'https://mykines.vita.fo/php/cancelMultipleEmail.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, bookingInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log(error)
          console.log('Oooops!');
        });
    });
  }

	/**
	 * Get discount emails and their discount rates
	 * 
	 * @author 					Pætur Mortensen 
	 */
	getDiscountEmails() {
    const link = 'https://mykines.vita.fo/php/getDiscountEmails.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {

          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

	/**
	 * Update all discount emails and discount rates
	 * 
	 * @author 					Pætur Mortensen 
	 */
  updateDiscountEmails(discounts) {
    const link = 'https://mykines.vita.fo/php/updateDiscountEmails.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, discounts)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
