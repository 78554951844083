import { Component, Inject, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { Router } from '@angular/router';
import { UserService } from '../../services/user-service/user-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})

export class LoginModalComponent {
  emailAnimate: boolean;
  pswAnimate: boolean;
  linearGradientEmail: boolean;
  linearGradientPsw: boolean;
  psw: string;
  email: string;
  constructor(
    public dialogRef: MatDialogRef<LoginModalComponent>,
    public dialog: MatDialog,
    public userService: UserService,
    private router: Router
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  animatePsw(value): void {
    if (!this.psw) {
      this.pswAnimate = value;
      this.linearGradientPsw = value;
    }
  }
  animateEmail(value): void {
    if (!this.email) {
      this.emailAnimate = value;
      this.linearGradientEmail = value;
    }
  }

  logIn() {
    this.userService.logIn({
      email: this.email,
      psw: this.psw
    }).then((data) => {
      if (data) {
        sessionStorage.setItem('login', data);
        this.closeDialog();
      } else {
        alert('Email or password is incorrect');
      }
    }).catch(() => {
      if (isDevMode()) {
        window.location.href = './';
      }
    });
  }

  keytab(value) {
    if (!this.psw && value === 'psw') {
      alert('Missing password');
    } else if (!this.email && value === 'email') {
      alert('Missing email');
    } else {
      this.logIn();
    }
  }
}
