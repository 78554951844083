import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-message',
  templateUrl: './message.html',
  styleUrls: ['./message.scss']
})

export class MessageComponent {


  constructor(
    public dialogRef: MatDialogRef<MessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
     }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
