import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UploadImageService {

  constructor(public http: HttpClient) {

  }

  uploadImage(info) {
    const link = 'https://mykines.vita.fo/php/uploadImage.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
