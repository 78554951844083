<div class="homeComponent" >
  <div class="adv">
    <div (click)="clickAds(tourNumber)" class="leftAd">
      <img *ngIf="adFirstImage != ''" [ngClass]="{'fadeInAd': fadeAd == true, 'fadeOutAd': fadeAd == false}"
        [src]="adFirstImage">
      <img *ngIf="adSecondImage != ''" [ngClass]="{'fadeInAd': fadeAd == false, 'fadeOutAd': fadeAd == true}"
        [src]="adSecondImage">
    </div>
    <div (click)="clickAds(tourNumber)" class="rightAd">
      <img *ngIf="adFirstImage != ''" [ngClass]="{'fadeInAd': fadeAd == true, 'fadeOutAd': fadeAd == false}"
        [src]="adFirstImage">
      <img *ngIf="adSecondImage != ''" [ngClass]="{'fadeInAd': fadeAd == false, 'fadeOutAd': fadeAd == true}"
        [src]="adSecondImage">
    </div>
  </div>
  <div class="tech-slideshow">
    <span
      tappable
      (click)="messageInfo(i, 'message')"
      *ngFor="let message of showMessage; let i = index;"
    >
      {{message.text}}
    </span>
  </div>
  <div
    *ngFor="let info of latestInfo; let i = index"
    [ngClass]="{'visibleMessageInfo': messageInfoCtrl === i}"
    class="messageInfo"
  >
    <div tappable (click)="messageInfo(-1)" class="close-btn">X</div>
    <div>
      <div *ngIf="!topInfo" class="latestDate">
        <div>{{info.date}}</div>
        {{info.month}}
        <img src="assets/img/latest.png" />
      </div>
      <div class="messageText">
        <div>{{info.headline}}</div>
        <div>{{info.text}}</div>
      </div>
    </div>
  </div>

  <div
    *ngFor="let info of infoes; let i = index"
    [ngClass]="{'visibleMessageInfo': infoInfoCtrl === i}"
    class="messageInfo"
  >
    <div tappable (click)="messageInfo(-1)" class="close-btn">X</div>
    <div class="messageText">
      <div>{{info.headline}}</div>
      <div>{{info.text}}</div>
    </div>
  </div>
  <div class="language" [ngClass]="{'hideContent': activeMenu !== menues[0]}">
    <div tappable (click)="setLanguage('FO')">
      <img src="assets/img/fo.png" />
    </div>
    <div tappable (click)="setLanguage('EN')">
      <img src="assets/img/en.png" />
    </div>
  </div>
  <div
    *ngIf="leftMenu === false || !leftMenu"
    class="slide-menu-icon burger"
    tappable
    (click)="burgerMenu()"
  >
    <img src="assets/icons/burger.png" />
  </div>
  <div
    *ngIf="leftMenu === true"
    class="slide-menu-icon close"
    tappable
    (click)="burgerMenu()"
  >
    <img src="assets/icons/close.png" />
  </div>
  <div
    class="menu"
    [ngClass]="{'openMenu': leftMenu === true, 'closeMenu': leftMenu === false}"
  >
    <div
      tappable
      [ngClass]="{'activeMenu': activeMenu === menu}"
      (click)="selectedMenu(menu)"
      *ngFor="let menu of menues"
    >
      {{menu}}
    </div>
  </div>
  <div class="pages" *ngFor="let page of pagesText; let i = index">
    <div *ngIf="(activeMenu === page.menuEn) && (language === 'EN')">
      <div class="pageContent" [innerHTML]="page.contentEn  | safeHtml"></div>
    </div>
    <div
      *ngIf="(activeMenu === page.menuFo) && (language === 'FO' || !language)"
    >
      <div class="pageContent" [innerHTML]="page.contentFo  | safeHtml"></div>
    </div>
  </div>

  <div class="home" *ngIf="activeMenu === menues[0]">
    <div class="static-home-content">
      <div class="logo">
        <img src="assets/img/logo.png" />
      </div>
      <div class="closed-season" *ngIf="trips[0] === 'Season Closed'">
        <h1>CLOSED FOR THE SEASON</h1>
        <p>We expect to reopen 1st of January</p>
      </div>
      <div *ngIf="trips[0] != 'Season Closed' || localhost === 'localhost'">
        <div class="search">
          <span class="tour">
            <div *ngIf="!accountType.hiking && !accountType.trip">
              <fa-icon [icon]="faHandPointer"></fa-icon>
            </div>
            <div *ngIf="accountType.hiking  || accountType.trip">
              <fa-icon [icon]="accountType.trip?faShip:faHiking"></fa-icon>
            </div>
            <div class="searchFields">
              <input
                tappable
                [ngClass]="{'activeSearch': activeSearch === 0}"
                (click)="selectTour('-1', 0)"
                [value]="searchFields[0].tour? searchFields[0].tour : searchFields[0].lan[0]"
                class="default"
                type="button"
              />
            </div>
            <div [ngClass]="{'dropDown': !tripDropdown}" class="option">
              <div
                tappable
                (click)="selectTour(i, trip)"
                *ngFor="let trip of trips; let i = index;"
              >
                <fa-icon
                  [icon]="tripsAccountType[i].trip?faShip:faHiking"
                ></fa-icon>
                <p>{{trip}}</p>
              </div>
            </div>
          </span>
          <span class="location">
            <fa-icon [icon]="faMapMarker"></fa-icon>
            <div class="searchFields">
              <input
                tappable
                [disabled]="(disabled) || (disableFrom)"
                [placeholder]="searchFields[0].lan[1]"
                [ngClass]="{'disabled': disabled, 'activeSearch': activeSearch === 1}"
                (click)="selectFrom('-1', 1)"
                [value]="searchFields[0].departure? searchFields[0].departure : searchFields[0].lan[1]"
                class="default default1"
                type="button"
              />
            </div>
            <div
              [ngClass]="{'dropDown': !locationDropdown}"
              class="option option1"
            >
              <div
                tappable
                (click)="selectFrom(i, from)"
                *ngFor="let from of froms; let i = index;"
              >
                {{from}}
              </div>
            </div>
          </span>
          <span class="owl-date-time">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
            <div class="searchFields">
              <input
                tappable
                [ngClass]="{'activeSearch': activeSearch === 2}"
                [disabled]="(disabled) || (disableFrom)"
                [ngClass]="{'disabled': disabled}"
                (click)="selectDeparture('-1', 2)"
                [value]="searchFields[0].lan[2]"
                class="default"
                type="button"
              />
              <div [ngClass]="{'dropDown': !dateDropdown}" class="optionDate">
                <span *ngIf="froms[1]"
                  >{{homeText.returnTripText}}
                  <input
                    class="checkbox"
                    type="checkbox"
                    [(ngModel)]="returnTrip"
                  />
                </span>
                <div class="wrapDate">
                  {{accountType.hiking ? searchFields[0].lan[2] :
                  searchFields[0].lan[6]}}
                  <input
                  id="departure-input"
                    class="dateInput"
                    readonly
                    (ngModelChange)="selectDeparture($event, 'departure')"
                    (click)="soldOutCalender('departure')"
                    [disabled]="disabled"
                    [min]="min"
                    [max]="max"
                    [(ngModel)]="departureDate"
                    [owlDateTimeTrigger]="dt2"
                    [owlDateTime]="dt2"
                    [owlDateTimeFilter]="myFilterDeparture != undefined ? myFilterDeparture : myFilter"
                    [ngClass]="{'disabled': disabled}"
                    type="button"
                    #departureInput
                  />
                  <fa-icon
                    class="calenderImg"
                    tappable
                    (click)="openCalender('departure')"
                    [icon]="faCalendarAlt"
                  ></fa-icon>

                  <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                </div>
                <div class="wrapDate" *ngIf="returnTrip && !accountType.hiking">
                  {{searchFields[0].lan[7]}}
                  <input
                  id="return-input"
                    class="dateInput"
                    readonly
                    (ngModelChange)="selectDeparture($event, 'return')"
                    (click)="soldOutCalender('return')"
                    [disabled]="(disabled) || (disableFrom)"
                    [min]="returnMin"
                    [max]="max"
                    [(ngModel)]="returnDate"
                    [owlDateTimeTrigger]="dt1"
                    [owlDateTime]="dt1"
                    [owlDateTimeFilter]="myFilterReturn != undefined ? myFilterReturn : myFilter"
                    [ngClass]="{'disabled': disabled}"
                    type="button"
                    #returnInput
                  />
                  <fa-icon
                    class="calenderImg"
                    tappable
                    (click)="openCalender('return')"
                    [icon]="faCalendarAlt"
                  ></fa-icon>
                  <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                </div>
              </div>
            </div>
          </span>

          <span class="guests">
            <fa-icon [icon]="faUsers"></fa-icon>
            <div class="searchFields">
              <input
                tappable
                [ngClass]="{'activeSearch': activeSearch === 3}"
                [disabled]="disabled"
                [ngClass]="{'disabled': disabled}"
                (click)="selectGuests('-1', 3)"
                [value]="searchFields[0].lan[4]"
                class="default default1"
                type="button"
              />
            </div>
            <div [ngClass]="{'dropDown': !guestsDropdown}" class="option3">
              <div class="chooseGuest">
                <div class="wrap-add-guests">
                  <div class="guestText">{{homeText.adults}}</div>
                  <div class="age">16-66</div>
                  <div class="add-guests">
                    <fa-icon
                      tappable
                      (click)="adults != 0 ? addGuests(-1, 0): 0"
                      [icon]="faMinusCircle"
                    ></fa-icon>
                    <input
                      class="guestInput"
                      type="number"
                      [(ngModel)]="adults"
                    />
                    <fa-icon
                      tappable
                      (click)="addGuests(1, 0)"
                      [icon]="faPlusCircle"
                    ></fa-icon>
                  </div>
                </div>
                <div class="wrap-add-guests">
                  <div class="guestText">{{homeText.juniorSeniorText}}</div>
                  <div class="age">7-15 / 67+</div>
                  <div class="add-guests">
                    <fa-icon
                      tappable
                      (click)="juniorSenior != 0 ? addGuests(-1, 1): 0"
                      [icon]="faMinusCircle"
                    ></fa-icon>

                    <input
                      class="guestInput"
                      type="number"
                      [(ngModel)]="juniorSenior"
                    />
                    <fa-icon
                      tappable
                      (click)="addGuests(1, 1)"
                      [icon]="faPlusCircle"
                    ></fa-icon>
                  </div>
                </div>
                <div class="wrap-add-guests">
                  <div class="guestText">{{homeText.childrenText}}</div>
                  <div class="age">0-6</div>
                  <div class="add-guests">
                    <fa-icon
                      tappable
                      (click)="children != 0 ? addGuests(-1, 2): 0"
                      [icon]="faMinusCircle"
                    ></fa-icon>
                    <input
                      class="guestInput"
                      type="number"
                      [(ngModel)]="children"
                    />
                    <fa-icon
                      tappable
                      (click)="addGuests(1, 2)"
                      [icon]="faPlusCircle"
                    ></fa-icon>
                  </div>
                </div>
              </div>
              <!-- <div tappable (click)="selectGuests(i, guest)" *ngFor="let guest of guests; let i = index;">{{guest}}</div> -->
            </div>
          </span>

          <span>
            <img src="assets/icons/search.png" />
            <div class="searchFields">
              <input
                readonly
                tappable
                (click)="searchBooking()"
                [disabled]="disabled"
                class="default go"
                [value]="searchFields[0].lan[5]"
                type="button"
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <carousel
  (window:resize)="onResize($event)"
  [height]='cellHeight'
  [transitionDuration]='500'
  [cellWidth]="cellWidth"
  [margin]='0'
  [arrows]="arrows"
  [images]="images">

</carousel>
  <!-- <div
    class="tripBackground"
    (window:resize)="onResize($event)"
    tappable
    (click)="mask()"
    [ngClass]="{'hideContent': activeMenu !== menues[0]}"
    *ngFor="let tour of trips; let i = index"
  >
    <div class="tripBox">
      <div>
        <div *ngFor="let tour of trips; let i = index">
          <img
            [ngClass]="{'rotateBackgroundTrip': (i === rotateTrip) && (trips.length > 1)}"
            *ngIf="i === rotateTrip"
            [src]="backgroundSrc[i]"
          />
          <img
            class="backBackground"
            *ngIf="i === delay"
            src="{{(i < trips.length - 1 ) ? backgroundSrc[i+1] : backgroundSrc[0]}}"
          />
         
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="bullets" [ngClass]="{'hideContent': activeMenu !== menues[0]}">
    <div tappable (click)="bullets(i)" *ngFor="let tour of trips; let i = index">
      <div>
        <span [ngClass]="{'activeBullet': i === rotateTrip}"></span>
      </div>
    </div>
  </div> -->
  <div class="readMore" [ngClass]="{'hideContent': activeMenu !== menues[0]}">
    <div class="readMoreWrap">
      <div class="readMoreTour" *ngFor="let tour of trips; let i = index">
        <div *ngIf="tours[i].accountType.trip" class="readMoreImg">
          <span
            [ngStyle]="{'background-image':'url('+backgroundSrcMedium[i]+')'}"
          ></span>
        </div>
        <div *ngIf="tours[i].accountType.hiking" class="readMoreImg">
          <span
            [ngStyle]="{'background-image':'url(assets/img/hiking_medium.png)'}"
          ></span>
        </div>
        <div class="readMoreHeadline">
          <span [ngClass]="{'readMoreHeadlineLength': tour.length > 8}"
            >{{tour}}</span
          >
        </div>
        <div class="readMoreInfo">
          <span [innerHTML]="tourInfo[i]"></span>
        </div>
        <div class="readMorePrice">
          <div>{{homeText.adults}}</div>
          <div>DKK {{price[i]}}</div>
        </div>
        <div class="readMoreButton" tappable (click)="order(i)">
          <div>{{homeText.orderHere}}</div>
          <div>
            <img src="assets/img/arrow.png" />
          </div>
        </div>
      </div>

      <div class="bottomContent">
        <div class="headline">
          <div class="headline">{{homeText.headline[0]}}</div>
          <div class="headline">{{homeText.headline[1]}}</div>
          <div class="headline">{{homeText.headline[2]}}</div>
        </div>

        <div class="latest">
          <div
            tappable
            (click)="messageInfo(i)"
            class="latestWrap"
            *ngFor="let info of latestInfo; let i = index"
          >
            <div class="latestDate">
              <div>{{info.date}}</div>
              {{info.month}}
              <img src="assets/img/latest.png" />
            </div>
            <div class="latestText">
              <div>{{info.headline}}</div>
              <div>{{info.text}}</div>
            </div>
          </div>
        </div>
        <div class="contact">
          <div class="contactImg">
            <img src="assets/img/contactBanner.png" />
          </div>
          <div class="contactText">
            <div>{{homeText.contact.name}}</div>
            <div>
              {{homeText.contact.adr}}
              <br />{{homeText.contact.city}} <br />tel:
              {{homeText.contact.tel}} <br />V-tal: {{homeText.contact.vtal}}
              <br />{{homeText.contact.email}}
            </div>
          </div>
        </div>
        <div class="info">
          <div
            tappable
            (click)="messageInfo(i, 'info')"
            *ngFor="let info of infoes; let i = index"
          >
            <div class="headlineInfo">{{info.headline}}</div>
            <div class="textInfo">
              {{info.text}}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
