import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AdsService {

  constructor(public http: HttpClient) {

  }

  click(value) {
    const link = 'https://mykines.vita.fo/php/updateAds.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, value)
        .subscribe(data => {

          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateAdInfo(value) {
    const link = 'https://mykines.vita.fo/php/updateAds.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, value)
        .subscribe(data => {
          console.log(data)
          resolve(data);
        }, error => {
          console.log(error)
          reject(error);
          console.log('Oooops!');
        });
    });
  }
  getAdInfo() {
    const link = 'https://mykines.vita.fo/php/getAdInfo.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
     
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}

