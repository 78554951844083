import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PaymentService {

  constructor(public http: HttpClient) {

  }

  cancelRefundPayment(info) {
    const link = 'https://mykines.vita.fo/php/quickpay/cancelTicket.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log('Oooops!');
        });
    });
  }
  cancelMultipleRefundPayment(info) {
    const link = 'https://mykines.vita.fo/php/quickpay/cancelMultipleTicket.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  cancelHikingRefundPayment(info) {
    const link = 'https://mykines.vita.fo/php/quickpay/cancelHiking.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log('Oooops!');
        });
    });
  }
  logPayment(info) {
    const link = 'https://mykines.vita.fo/php/logPayment.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log(error);
        });
    });
  }

  getPayment(info) {
    const link = 'https://mykines.vita.fo/php/quickpay/ticketClient.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log(error);
        });
    });
  }

  getHikingPayment(info) {
    const link = 'https://mykines.vita.fo/php/quickpay/hikingClient.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, info, {responseType: 'text'})
        .subscribe(data => {
          resolve(data);
        }, error => {
          // alert(error);
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}

