import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InfoService {

  constructor(public http: HttpClient) {

  }

  getCondition() {
    const link = 'https://mykines.vita.fo/php/getCondition.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  updateCondition(conditionInfo) {
    const link = 'https://mykines.vita.fo/php/updateCondition.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, conditionInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  addInfo(newsInfo) {
    const link = 'https://mykines.vita.fo/php/addInfo.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, newsInfo)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }

  getInfo() {
    const link = 'https://mykines.vita.fo/php/getInfo.php';
    return new Promise<any>((resolve, reject) => {
      this.http.get(link)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!' + error);
        });
    });
  }

  deleteInfo(id) {
    const link = 'https://mykines.vita.fo/php/deleteInfo.php';
    return new Promise<any>((resolve, reject) => {
      this.http.post(link, id)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
          console.log('Oooops!');
        });
    });
  }
}
